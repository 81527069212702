import React from "react";
import "./TransactionLoadingPlaceholder.css"
import "../LoadingPlaceholder.css"


class TransactionLoadingPlaceholder extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="loading-transaction linear-background"/>
        )
    }
}

export default TransactionLoadingPlaceholder
