import React from "react";
import FaIcon from "../components/FaIcon";
import "./SocialButtons.css";
import {federatedSignIn} from "../libs/authLib";
import Analytics from "../services/analytics/Mixpanel";
import Logger from '../utils/logger';

export default function GoogleButton({text, isSignUp, isOauth}){

    const handleClick = async () => {
        try {
            Analytics.track("button_click_signup_screen_signup_with_google", {application: "webapp"})
        } catch(e) {
            Logger.log("Failed to track Google Signup")
        }
        await federatedSignIn('Google', isOauth, isSignUp);
    }

    return (
        <div className="btn-login-google">
            <button
                className="btn-auth-social"
                onClick={handleClick}
            >
                <div className="btn-auth-social-icon">
                    <FaIcon icon={['fab', 'google']}/>
                </div>
                <div className="btn-auth-text btn-auth-social-text">{text}</div>
            </button>
        </div>
    );
}
