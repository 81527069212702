import React, { useState } from "react";
import "./CardAmountInput.css";
import CurrencyIcon from "../../misc/currencyicon/CurrencyIcon";
import DenominationSelector from "../DenominationSelector/DenominationSelector";

function CardAmountInput({ props }) {
    const [cardCurrency, setCardCurrency] = useState("USD");
    const [cardAmount, setCardAmount] = useState(props.amount || "");

    return (
        <div test-id='card-amount-input' ref={props.cardAmountInputRef} className="checkout-section checkout-section-order-total no-highlight">
            <div className="checkout-section-label py-0 ">
                Card Amount
            </div>
            <div className="checkout-order-total-value">
                {
                    props.denominations?.length > 0 ? <DenominationSelector denominations={props.denominations} cardAmount={cardAmount} setCardAmount={setCardAmount} paymentMethodSelectorCardAmountChanged={props.paymentMethodSelectorCardAmountChanged}/>
                        : <input
                            className="checkout-order-total-value-input"
                            type="number"
                            step="any"
                            placeholder="Enter Amount"
                            onChange={props.paymentMethodSelectorCardAmountChanged}
                            value={props.amount > 0 && Number(props.amount)}
                            style={{ marginBottom: "2px", fontSize: props.cardAmountFontSize, color: props.fieldValidator.cardAmount ? "#000000" : "#FF0000" }}
                        />
                }
            </div>
            <div className="checkout-section-currency-flag disabled">
                <div>
                    {cardCurrency}
                    <CurrencyIcon currency={cardCurrency} />
                </div>
            </div>
        </div>
    );
}

export default CardAmountInput;
