import * as AuthUser from "./../libs/authLib";
import * as API from './../libs/apiLib'
import Logger from './logger';

export const handleFingerprintSetup = async (fpPromise, botdPromise, skipAuthUser) => {
    try{
        Logger.log('handleFingerprintSetup is called');
        if(skipAuthUser){
            const fingerprint = await generateFingerprintData(fpPromise);
            const botd = await botdPromise;
            const result = await botd.detect();
            await updateUserDetails(fingerprint, result);
            return;
        }

        const authUser = await AuthUser.getAuthUser(true, false);
        if (!authUser.hasFingerprint) {
            const fingerprint = await generateFingerprintData(fpPromise);
            const botd = await botdPromise;
            const result = await botd.detect();
            if (fingerprint && fingerprint.visitorId) {
                await updateUserDetails(fingerprint, result);
            }
        }
    }catch(err){
        Logger.error(err)
    }
}

export const handleFingerprintUpdate = async (fpPromise, botdPromise, skipAuthUser) => {
    try{
        Logger.log('handleFingerprintUpdate is called');

        if(skipAuthUser){
            const fingerprint = await generateFingerprintData(fpPromise);
            const botd = await botdPromise;
            const result = await botd.detect();
            await updateUserDetails(fingerprint, result, true);
            return;
        }

        const authUser = await AuthUser.getAuthUser(true, false);
        if (authUser.hasFingerprint) {
            const fingerprint = await generateFingerprintData(fpPromise);
            const botd = await botdPromise;
            const result = await botd.detect();
            await updateUserDetails(fingerprint, result, true);
        }
    }catch(err){
        Logger.error(err)
    }
}

export const updateUserDetails = async (fingerprint, botdData, updateFp) => {
    try {
        await API.updateUser(null, fingerprint, botdData, updateFp)
        await AuthUser.getAuthUser(true);
    } catch(err){
        Logger.error(err);
    }
}

export const generateFingerprintData = async (fpPromise) => {
    try {
        const fp = await fpPromise;
        const result = await fp.get({ extendedResult: true });
        if (!result.visitorId) {
            return {};
        }
        return {
            visitorId: result?.visitorId,
            confidenceScore: result?.confidence?.score,
            ipLocation: {
                city: result?.ipLocation?.city?.name,
                continent: result?.ipLocation?.continent?.name,
                country: result?.ipLocation?.country?.name,
                latitude: result?.ipLocation?.latitude?.toString(),
                longitude: result?.ipLocation?.longitude?.toString(),
                postalCode: result?.ipLocation?.postalCode,
                timezone: result?.ipLocation?.timezone
            },
            incognito: result?.incognito,
            language: window?.navigator?.language
        }
    } catch (err) {
        Logger.error(err);
        return null;
    }
}
