import React, { useEffect, useState } from 'react'
import Analytics from '../services/analytics/Mixpanel'
import FaIcon from './misc/fontawesome/FaIcon'
import './RequestRefund.css'
import * as bitcoinAddressValidator from 'bitcoin-address-validation'
import * as API from '../libs/apiLib'
import * as AuthUser from '../libs/authLib'
import throbber from '../images/throbber_200_white.svg'
import Logger from '../utils/logger'

const MAX_REFUND_AMOUNT = 3000
const MIN_REFUND_AMOUNT = 10
const MIN_ADDRESS_LENGTH = 20

const RequestRefund = (
  {
    modalPageNum,
    setModalPageNum,
    isMobile,
    setShouldClickOff,
    setRefundHistory,
    closeRequestRefundModal,
    setAmount,
    amount,
    moonCreditAmount,
    address,
    setAddress
  }) => {
  const [amountError, setAmountError] = useState('')
  const [addressError, setAddressError] = useState('')
  const [verificationCodeError, setVerificationCodeError] = useState('')
  const [confirmationError, setConfirmationError] = useState(false)
  const [verificationCodeState, setVerificationCodeState] = useState('resend')
  const [verificationCode, setVerificationCode] = useState()
  const [offPlatformRefundId, setOffPlatformRefundId] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (isMobile) {
      setShouldClickOff(false)
    }
    setModalPageNum(0)
  }, [])

  const addressValidation = (address) => {
    const trimmedAddress = address.replace(/\s/g, '')
    if(trimmedAddress.length >= MIN_ADDRESS_LENGTH){
      const isAddressValid = bitcoinAddressValidator.validate(trimmedAddress)
      if (!isAddressValid) {
        setAddressError('Please enter a valid Bitcoin address')
      }else{
        setAddressError('')
      }
    }
  }

  const checkInputs = () => {
    addressValidation(address)

    if (amount && address && !amountError && !addressError) {
      setModalPageNum(1)
    }
  }

  const resendVerificationCode = async () => {
    try {
      await API.resendOffPlatformRefundRequest(offPlatformRefundId)
      Analytics.track('button_click_request_refund_resend_verification_code', { application: 'webapp' })
      setVerificationCodeState('sent')
      setTimeout(() => {
        setVerificationCodeState('resend')
      }, 2000)
    } catch (err) {
      if (err.response.status === 429) {
        setVerificationCodeError('You have exceeded the maximum number of resend attempts')
      }
    }
  }

  const cancelRefundRequest = async () => {
    API.cancelOffPlatformRefundRequest(offPlatformRefundId)
    Analytics.track('button_click_request_refund_cancel_request', { application: 'webapp' })
    closeRequestRefundModal()
  }

  const submitRequest = async () => {
    try {
      setIsLoading(true)
      const OffPlatformRefundRequestRes = await API.createOffPlatformRefundRequest('BTC', address, amount.toString())
      Analytics.track('button_click_request_refund_resend_create_request', { application: 'webapp' })
      setOffPlatformRefundId(OffPlatformRefundRequestRes.data.offPlatformRefundId)
      setConfirmationError(false)
      setIsLoading(false)
      setModalPageNum(2)
      setShouldClickOff(false)
    } catch (err) {
      Logger.error(err)
      setConfirmationError(true)
      setIsLoading(false)
    }
  }

  const verifyRequest = async () => {
    try {
      await API.verifyOffPlatformRefundRequest(offPlatformRefundId, Number(verificationCode))
      setVerificationCodeError(false)
      Analytics.track('button_click_request_refund_submit_verification_code', { application: 'webapp' })
      const refundHistoryRes = await API.getOffPlatformRefunds()
      const refundHistory = refundHistoryRes.data
      setRefundHistory(refundHistory)
      await AuthUser.getAuthUser(true, false)
      closeRequestRefundModal()
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        switch (err.response.data.message) {
          case "Invalid refund id / verification code combination":
            setVerificationCodeError(
              "Invalid verification code. Please try again."
            );
            break;
          case "Too many verification attempts":
            setVerificationCodeError(
              "Please reach out to support@paywithmoon.com"
            );
            break;
          default:
            setVerificationCodeError(
              "Something went wrong. Please contact us if error persists."
            );
            break;
        }
        return;
      }
      setVerificationCodeError(
        "Something went wrong. Please contact us if error persists."
      );
    }
  };

  const handleAmountInput = (amount) => {
    amount = amount >= moonCreditAmount ? moonCreditAmount : amount
    const isAmountValid = amount >= MIN_REFUND_AMOUNT && amount < MAX_REFUND_AMOUNT && amount <= moonCreditAmount
    if (isAmountValid) {
      setAmountError('')
    } else {
      if (amount >= MAX_REFUND_AMOUNT) {
        setAmountError(`Each refund request must be less than $${MAX_REFUND_AMOUNT}`)
      } else if (amount < MIN_REFUND_AMOUNT) {
        setAmountError(`Refund requests must be greater than $${MIN_REFUND_AMOUNT}`)
      } else {
        setAmountError('Please enter a valid amount')
      }
    }
    setAmount(amount)
  }

  const handleAddressInput = (address) => {
    addressValidation(address)
    setAddress(address)
  }

  const handleVerificationCode = (code) => {
    if (code <= 999999) {
      setVerificationCode(code)
    }
  }

  const renderInputs = () => {
    return (
        <div>
            <p className='request-refund-p'>All refunds of Moon Credit are processed in Bitcoin. Refunds are sent minus network and partner processing fees. Please specify the amount of Moon Credit you would like refunded and the bitcoin address to where we should send the refund. Your refund request will be processed within 5 business days. <a style={{ color: 'inherit', textDecoration: 'underline' }} href="https://paywithmoon.com/faqs#refunds" target="_blank" rel="noreferrer">Learn more about refunds</a></p>
            <div className={isMobile ? '' : 'flex'} style={{ marginTop: '30px' }}>
                <div style={{ marginRight: !isMobile && '10px' }}>
                    <p className='request-refund-input-title request-refund-p'>Refund Amount:</p>
                    <p className='request-refund-p moon-credit-available'>Available: <span>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(moonCreditAmount)}</span></p>
                </div>
                <div>
                    <div className='refund-amount-input-container'>
                        <span className='currency-input'>
                            $
                            <input
                                type='number'
                                value={amount}
                                onChange={(e) => handleAmountInput(e.target.value)}
                                className='request-refund-input refund-amount'
                                placeholder='25.00'
                                style={{ maxWidth: '80px', border: 0, borderRadius: '0.25rem', paddingLeft: '2px' }}/>
                        </span>
                        <button onClick={() => handleAmountInput(moonCreditAmount)} className='max-btn'>Max</button>
                    </div>
                    {amountError && <p className='refund-request-error-text request-refund-p' style={{ bottom: '160px' }}>{amountError}</p>}
                </div>
            </div>
            <br/>
            <div className={isMobile ? '' : 'flex'}>
                <p style={{ marginRight: !isMobile && '10px' }} className='request-refund-input-title request-refund-p'>Bitcoin Address:</p>
                <div className='address-input-container'>
                    <input value={address} onChange={(e) => handleAddressInput(e.target.value)} className='request-refund-input address-input' type='text'></input>
                    {addressError && <p className='refund-request-error-text request-refund-p' style={{ bottom: '91px' }}>{addressError}</p>}
                </div>
            </div>
            <div className='refund-request-continue-btn-container'>
              <button className='btn btn-primary refund-request-continue-btn' disabled={!amount || address.length < MIN_ADDRESS_LENGTH || amountError || addressError} onClick={checkInputs}>
                  Continue
              </button>
            </div>
        </div>
    )
  }

  const renderConfirmation = () => {
    return (
        <div>
        <br/>
            <div style={{ textAlign: 'center' }}>
                <p className='request-refund-input-title request-refund-p' style={{ marginBottom: '5px', fontSize: !isMobile && '20px' }}>Is this correct?</p>
                <p className='request-refund-p'>Once submitted, your refund request cannot be modified.</p>
            </div>
            <br/>
            <p className='request-refund-input-title request-refund-p confirm-refund-amount'>Refund Amount: <span className='refund-amount-value'>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount)}</span></p>
            <br/>
            <p style={{ marginRight: !isMobile && '10px' }} className='request-refund-input-title request-refund-p confirm-refund-address'>Bitcoin Address: <span className='refund-amount-value' style={{ wordBreak: isMobile && 'break-word', fontSize: '14px', fontWeight: 400 }}>{address}</span></p>
            {confirmationError && <p className='refund-request-error-text request-refund-p' style={{ textAlign: 'center', position: 'relative', top: isMobile ? '10px' : '3px' }}>{'Something went wrong. Please contact us if error persists.'}</p>}
            {!isMobile &&
              <button className='btn refund-request-back-btn' onClick={() => setModalPageNum(0)}>
                  Back
              </button>
            }
            <div className='refund-request-continue-btn-container'>
              <button disabled={isLoading} className='btn btn-primary refund-request-continue-btn' onClick={submitRequest}>
                { !isLoading ? 'Continue' : <img className="throbber" style={{ width: '20px' }} src={throbber} alt="Loading..."/>}
              </button>
            </div>
        </div>
    )
  }

  const renderVerification = () => {
    return (
        <div>
        <br/>
            <div style={{ textAlign: 'center' }}>
                <p className='request-refund-p' style={{ marginTop: '8px', marginBottom: '10px' }}>Please check your email for a verification code and enter it below.</p>
            </div>
            <br/>
            <div style={{ textAlign: 'center' }}>
                <input type='number' value={verificationCode} onChange={(e) => handleVerificationCode(e.target.value)} placeholder='000000' className='request-refund-verification-code-input'/>
                <div>
                  {verificationCodeState === 'resend'
                    ? <span onClick={resendVerificationCode} className='resend-verification-code-btn'>Resend Code</span>
                    : <span className='resend-verification-code-success'>Sent!</span>
                  }
                </div>
                {verificationCodeError && <p className='refund-request-error-text request-refund-p' style={{ position: 'relative', top: isMobile ? '10px' : '25px' }}>{verificationCodeError}</p>}
            </div>
            <div style={{ textAlign: 'center' }}>
            <button className='btn btn-primary refund-request-continue-btn' onClick={verifyRequest}>
                Confirm
            </button>
            </div>
            <div style={{ textAlign: 'center' }}>
            <button className='btn refund-request-cancel-btn' onClick={cancelRefundRequest}>
                Cancel
            </button>
            </div>
        </div>
    )
  }

  return (
        <div id='moonCreditModal' className={isMobile ? '' : 'modal'}>
            <div
                className="request-refund-container"
                onClick={(e) => e.stopPropagation()}
            >
                <div style={{ textAlign: 'right' }} className='desktop-refund-close-button'>
                    <FaIcon icon='times' className='close-button' onClick={() => {
                      Analytics.track('button_click_request_refund_close', { application: 'webapp' })
                      modalPageNum === 2 ? cancelRefundRequest() : closeRequestRefundModal()
                    }} />
                </div>
                {isMobile
                  ? <h3 className='request-refund-title'>
                      Request a Refund
                  </h3>
                  : <h2 className='request-refund-title'>
                    Request a Refund
                </h2>}
                {modalPageNum === 0 && renderInputs()}
                {modalPageNum === 1 && renderConfirmation()}
                {modalPageNum === 2 && renderVerification()}
            </div>
        </div>
  )
}

export default RequestRefund
