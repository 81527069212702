/* -----------------    Authentication Action Identifiers     ------------------ */
export const ACTION_SET_AUTH_USER = 'AUTH_USER_SET';
export const ACTION_SET_AUTH_USER_TEMPORARY_ONBOARD_SKIP = 'AUTH_USER_TEMPORARY_ONBOARD_DELAY';

/* -----------------    Tab Data Action Identifiers     ------------------ */
export const ACTION_SET_TAB = 'TAB_INFORMATION_SET';

/* -----------------    Wallet Selection Identifiers     ------------------ */
export const ACTION_SET_SELECTED_WALLET = 'SELECTED_WALLET_SET';

/* -----------------    Apply Rewards State Identifiers     ------------------ */
export const ACTION_SET_APPLY_REWARDS_STATE = 'APPLY_REWARDS_STATE_SET';

/* -----------------    Current Card Action Identifiers     ------------------ */
export const ACTION_SET_CURRENT_CARD = 'CURRENT_CARD_SET';

/* -----------------    Developer Mode Action Identifiers     ------------------ */
export const ACTION_SET_DEVELOPER_MODE = 'DEVELOPER_MODE_SET';

/* -----------------    Reset State Identifiers     ------------------ */
export const ACTION_RESET_SESSION = 'AUTH_SESSION_RESET';

/* -----------------    Chrome Extension State Identifiers     ------------------ */
export const ACTION_SET_EXTENSION_INSTALLED = 'EXTENSION_INSTALLED_SET';

/* -----------------    On Chain Pending Invoices State Identifiers     ------------------ */
export const ACTION_SET_ON_CHAIN_PENDING_INVOICES = 'ON_CHAIN_PENDING_INVOICES_SET';

/* -----------------    Cards State Identifiers     ------------------ */
export const ACTION_SET_CARDS = 'CARDS_SET';

/* -----------------    Pending Info Cards State Identifiers     ------------------ */
export const ACTION_SET_PENDING_INFO_CARDS = 'PENDING_INFO_CARDS';

/* -----------------    Current Active Card Page State Identifiers     ------------------ */
export const ACTION_SET_CURRENT_ACTIVE_CARD_PAGE = 'CURRENT_ACTIVE_CARD_PAGE';

/* -----------------    Current Modal Identifiers     ------------------ */
export const ACTION_SET_CURRENT_MODAL = 'CURRENT_MODAL_SET';