import React from "react";
import "../Home.css";
import "bootstrap/dist/css/bootstrap.css";
import * as EmailValidator from "email-validator";
import axios from "axios";
import {MOON_API_URL} from "../../config";


class ContactUsPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            message: "",
            notify: false,
        };
    };

    isValidForm(){
        const valid = EmailValidator.validate(this.state.email);
        return valid && this.state.message.length > 0 && this.state.name.length > 0 && !this.state.notify;
    }

    sendEmail = async (email) => {
        const valid = EmailValidator.validate(email);
        if(valid && this.state.message.length > 0 && this.state.name.length > 0 && !this.state.notify){
           await axios.post(
                `${MOON_API_URL}/v1/public/contactform`,
                {
                    name: this.state.name,
                    email: email,
                    message: this.state.message
                 });
           this.setState({notify: true });
        }
    };

    render() {
        const { notify } = this.state;
        return (
            <div>
                <div className="container">
                    <div className="col-lg-6 offset-lg-3">
                        <h3 className="text-center">
                            Questions? Concerns? Feedback? We’d love to hear from you!
                        </h3>
                        <div className={`${(notify) ? 'hide' : ''}`}>
                            <div className="input-group mt-5">
                                <input
                                    type="text"
                                    name="name"
                                    placeholder="Name"
                                    className="form-control"
                                    defaultValue={this.state.name}
                                    onChange={(event) => {
                                        this.setState({ name: event.target.value });
                                    }}
                                />
                            </div>
                            <div className="input-group mt-3">
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Email address"
                                    className="form-control"
                                    defaultValue={this.state.email}
                                    onChange={(event) => {
                                        this.setState({ email: event.target.value });
                                    }}
                                />
                            </div>
                            <div className="input-group mt-3">
                                <textarea
                                    name="message"
                                    placeholder="How can we help you?"
                                    className="form-control"
                                    defaultValue={this.state.message}
                                    onChange={(event) => {
                                        this.setState({ message: event.target.value });
                                    }}
                                >
                                </textarea>
                            </div>
                            <button
                                value="send"
                                type="submit"
                                className="btn btn-primary mt-3"
                                onClick={() => this.sendEmail(this.state.email)}
                                disabled={!this.isValidForm()}
                            >
                                Lets Talk
                            </button>
                        </div>
                        <p className={`text-center${(notify) ? "" : " hide"}`}>
                            <br /><br />
                            Thanks for reaching out, we'll be in contact with you soon. <br/>
                            <a href="/">Return to home</a>
                        </p>
                    </div>
                </div>
            </div>
        );
    }

};

export default ContactUsPage;