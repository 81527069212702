import { Box, Typography } from "@material-ui/core";
import React from "react";
import useWindowSize from "../../../hooks/useWindowSize";
import { useAppContext } from "../../../libs/contextLib";
import { isMobile } from "react-device-detect";

const GiftCardDetailView = () => {
  const { card, mobileWidth, mobileHeight } = useAppContext();
  const size = useWindowSize()
  if (!size || !size.width || !card || !card.gift_card) return null

  return (
      <Box
        className="CardBox noHover"
        borderRadius={24}
        width={size.width > 1100 ? "449.73px" : !isMobile && mobileWidth ? mobileWidth : "351.7px"}
        height={size.width > 1100 ? "281px" : !isMobile && mobileHeight ? mobileHeight : "220px"}
        bgcolor={card.gift_card.primaryBrandColor}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        style={{backgroundSize: 'cover',backgroundImage: `url("data:image/png;base64,${card?.gift_card?.card_art_base64}")`}}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
          paddingLeft="32px"
          height={size.width > 1111 ? "67px" : "56px"}
          style={{backgroundColor:"rgba(255, 255, 255, 0.9)", borderRadius: "23px 23px 0 0", padding: "3px 0 0 32px"}}
        >
          <Typography
            style={{
              color: "black",
              fontSize: size.width > 1111 ? 50 : 40,
              lineHeight: "1.3"
            }}
            variant="h6"
          >
            ${card.balance}
          </Typography>
        </Box>
        <Box
          height={size.width > 1100 ? 106 : 75}
          width="auto"
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
        >
        </Box>
      </Box>
  );
};

export default GiftCardDetailView;