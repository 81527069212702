import { Button, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import useWindowSize from '../../../../hooks/useWindowSize'

const GiftCardViewButton = ({ merchantCardWebsite }) => {
  const size = useWindowSize()

  const goToMerchantWebsite = () => {
    if (merchantCardWebsite) {
      window.open(merchantCardWebsite, '_blank')
    }
  }

  return (
    <Button
      style={{
        width: '100%',
        height: 40,
        marginTop: 12,
        backgroundColor: '#007BFF',
        padding: '19px 16px;',
        textTransform: 'none'
      }}
      onClick={goToMerchantWebsite}
      disableElevation
      variant="contained"
      disabled={!merchantCardWebsite}
    >
      <Typography variant="h4" style={{
        fontSize: 16,
        fontWeight: 700,
        color: 'white',
        fontFamily: 'Quicksand, sans-serif !important;'
      }}>
        {merchantCardWebsite ? 'View Gift Card' : 'No Gift Card URL'}
      </Typography>
    </Button>
  )
}

GiftCardViewButton.propTypes = {
  merchantCardWebsite: PropTypes.string,
  card: PropTypes.object
}

export default GiftCardViewButton
