import CurrencyIcon from "../../misc/currencyicon/CurrencyIcon";
import './PaymentAmount.css'

function PaymentAmount({props}){
    const toDisplayCurrency = (currency) => {
        return currency === "CGLD" ? "CELO" : currency;
    }
    return (
        <div test-id='payment-amount' className="checkout-section no-highlight">
                <div className="checkout-section-label" style={{ display: props.isShowingWallets && props.wallets.length > 2 && props.wallets[1].provider !== "CONNECT_COINBASE" && 'none' }}>You Pay</div>
                {<div className="checkout-payment-total-value no-highlight">
                    <input
                        test-id='payment-amount-input'
                        type="text"
                        value={props.paymentCurrency === 'USDC' || props.paymentCurrency === 'USDT' ? Number(props.requiredAmountInQuote).toFixed(2) : props.requiredAmountInQuote}
                        disabled
                        style={{ fontSize: props.requiredAmountFontSize }}
                    />
                </div>}
                <div
                    className="checkout-section-currency-flag"
                >
                    <div>
                        {toDisplayCurrency(props.paymentCurrency)}
                        <CurrencyIcon currency={props.paymentCurrency} />
                    </div>
                </div>
            </div>
    )
}

export default PaymentAmount;