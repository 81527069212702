import { createTheme, ThemeProvider } from "@material-ui/core";
import PropTypes from "prop-types";
import { AppContext } from "../../libs/contextLib";
import "./GiftCard.css";
import GiftCardListView from "./GiftCardListView/GiftCardListView";
import GiftCardDetailView from "./GiftCardDetailView/GiftCardDetailView";

const giftCardTheme = createTheme({
  typography: {
    fontFamily: "'Roboto', serif !important;",
    h6: {
      fontSize: 40,
      fontWeight: 500,
    },
    h5: {
      fontSize: 14,
      fontWeight: 400,
      color: "#999999",
      fontFamily: "Quicksand, sans-serif !important;"
    },
    h4: {
      fontSize: 16,
      fontWeight: 700,
      color: "white",
      fontFamily: "Quicksand, sans-serif !important;"
    },
    h1: {
      fontFamily: "Quicksand !important;",
      fontWeight: 500,
      fontSize: 24,
    }
  },
  modal: {
    "&:focus": {
      outline: "none",
    },
  },
});

function GiftCard({ triggerGiftCardStatusChange, card, isListView, mobileWidth, mobileHeight }) {
  if (!card || !card.gift_card) return null;

  return (
    <AppContext.Provider value={{ triggerGiftCardStatusChange, card, isListView, mobileWidth, mobileHeight }}>
      <ThemeProvider theme={giftCardTheme}>
        {isListView ? (
          <GiftCardListView/>
        ) : (
          <GiftCardDetailView></GiftCardDetailView>
        )}
      </ThemeProvider>
    </AppContext.Provider>
  );
}

GiftCard.propTypes = {
  card: PropTypes.object,
  triggerGiftCardStatusChange: PropTypes.func,
  isListView: PropTypes.bool,
  mobileWidth: PropTypes.string,
  mobileHeight: PropTypes.string
};

export default GiftCard;
