import React, { useState } from 'react'
import './RefundHistory.css'
import moment from 'moment'
import { TransactionStatusLink } from '../utils/enums'
import Tooltip from '../components/Tooltip'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from './Alert'
import { copy } from './misc/copytoclipboard/copyToClipboard'
import Analytics from '../services/analytics/Mixpanel'

const RefundHistory = ({ isMobile, refundHistory }) => {
  const [copied, setCopied] = useState(false)
  const [justCopiedProperty, setJustCopiedProperty] = useState(null)

  const sortRefunds = (refunds) => {
    return refunds.sort((a, b) => {
      return new Date(b.time_requested_by_user) - new Date(a.time_requested_by_user)
    })
  }

  const handleStatus = (status) => (status === 'SENT') ? 'SENT' : 'PENDING'

  const copyToClipboard = (refund) => {
    copy(isMobile, setCopied, refund.coinbase_transaction_id, setJustCopiedProperty)
    Analytics.track('button_click_coinbase_transaction_id_copy', { application: 'webapp' })
  }

  const redirectRefundAddress = (address) => window.open(`https://www.blockchain.com/explorer/addresses/btc/${address}`)

  const redirectRefundTransaction = (transactionHash) => window.open(TransactionStatusLink.BITCOIN(transactionHash))

  const renderRefundStatus = (refund) => {
    if (refund.status === 'SENT') {
      if (refund.blockchain_transaction_hash) {
        return (
          <div
            onClick={() => redirectRefundTransaction(refund.blockchain_transaction_hash)}
            className='refund-history-status-sent'>
            {handleStatus(refund.status)}
          </div>
        )
      } else if (refund.coinbase_transaction_id) {
        return (
          <Tooltip default={true} hide={isMobile} setCopied={setCopied} marginTop='-40px' content={copied ? <span><span>{'\u2713'}</span>Copied to clipboard</span> : refund.coinbase_transaction_id} direction='top'>
            <div onClick={() => copyToClipboard(refund)}>
              <div
                className='refund-history-status-sent no-highlight'>
                {handleStatus(refund.status)}
              </div>
            </div>
          </Tooltip>
        )
      }
    } else {
      return (
        <div
          className='refund-history-status-pending'>
          {handleStatus(refund.status)}
        </div>
      )
    }
  }
  return (
    <div>
      <div style={{ marginTop: '35px' }}>
        <h2 className='refund-title'>Refunds</h2>

        <div className='refund-section'>
        <div className='refund-left-section history-title-amount'>Amount</div>
        <div className='refund-middle-section history-title-destination'><div className='history-title-destiniation-text'>Destination</div></div>
        <div className='refund-right-section history-title-status'>Status</div>
        </div>
        <div className='refund-title-underline'></div>
      </div>
      {refundHistory && sortRefunds(refundHistory).map((refund, i) => (
        <div
          className='refund-section'
          key={refund.id}
        >
          <div className='refund-left-section'>
            <div>${refund.requested_refund_amount}</div>
            {(!refund.time_transaction_initiated) && (<div className='refund-date'>{moment(refund.time_requested_by_user).format('M/D/YY, h:mm a')}</div>)}
            {(refund.time_transaction_initiated) && (<div className='refund-date'><div>Sent:</div>{moment(refund.time_transaction_initiated).format('M/D/YY, h:mm a')}</div>)}
          </div>
          <div className='refund-middle-section'>
            <div onClick={() => redirectRefundAddress(refund.customer_blockchain_address)} className='refund-history-address'>{isMobile ? refund.customer_blockchain_address.slice(0, 10) : refund.customer_blockchain_address.slice(0, 20)}...</div>
          </div>
          <div className='refund-right-section'>
            <div style={{ marginRight: handleStatus(refund.status) === 'SENT' && '30px', color: handleStatus(refund.status) === 'PENDING' ? '#F5BC42' : '#008000' }}>{renderRefundStatus(refund)}</div>
          </div>
        </div>
      ))}
      <Snackbar
        open={justCopiedProperty}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        style={{
          left: '50%',
          transform: 'translateX(-50%)',
          width: 'fit-content'
        }}
      >
        <Alert severity="success">Copied!</Alert>
      </Snackbar>
    </div>
  )
}

export default RefundHistory
