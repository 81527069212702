import React, { useState, useEffect } from "react";
import './CardFees.css'

function CardFees({ cardFees, showStriked }) {
    const cardCreationFee = cardFees ? cardFees.cardCreationFee.toFixed(2) : 0;
    const loadFee = cardFees ? cardFees.loadFeePercent.toFixed(1) : 0;
    return (
        <div className='fees-container' test-id='card-fees'>
            <span>
                Card Fee: {!showStriked ? <b><i>${cardCreationFee}</i></b> : <b><i className={"striked-text"}>$1.50</i>{" $0"}</b>}
            </span>
            <span>
                Load Fee: {!showStriked ? <b><i>{loadFee}%</i></b> : <b><i className={"striked-text"}>1.5%</i>{" 0%"}</b>}
            </span>
        </div>
    )
}

export default CardFees;
