
import QrCodeWithLogo from "qrcode-with-logos";

export const generateQRCode = async (QRCanvas, QRImage, address, setIsLoadingQR, Logo) => {
    const canvas = QRCanvas.current;
    const image = QRImage.current;
    let qrcode = new QrCodeWithLogo({
        canvas: canvas,
        content: address,
        width: 245,
        logo: {
            src: Logo,
            logoSize: 0.18,
            borderSize: 0.03
        },
        nodeQrCodeOptions: {
            margin: 0
        }
    });
    await qrcode.toCanvas();
    setIsLoadingQR(false);
}