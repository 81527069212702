import { forwardRef } from "react";
import FaIcon from "../../misc/fontawesome/FaIcon";
import { Box, Typography } from "@material-ui/core";
import useWindowSize from "../../../hooks/useWindowSize";
import GiftCard from "../GiftCard";
import GiftCardCreatedTransactionRow from "./GiftCardCreatedTransactionRow/GiftCardCreatedTransactionRow";
import GiftCardSecurityCodeInformation from "./GiftCardSecurityCodeInformation/GiftCardSecurityCodeInformation";
import GiftCardViewButton from "./GiftCardViewButton/GiftCardViewButton";
import GiftCardBarcodeInformation from "./GiftCardBarcodeInformation/GiftCardBarcodeInformation";
import store from '../../../redux/store';
import { ACTION_SET_CURRENT_MODAL } from '../../../redux/constants';

export function MobileGiftCardInfo({ close, card, markCardAsUsedorUnused }) {
  const setModalView = (currentModal) => store.dispatch({ type: ACTION_SET_CURRENT_MODAL, currentModal })

  const size = useWindowSize();

  const mobileTabsHeight =
    document.getElementsByClassName("mobile-tabs")[0]?.offsetHeight;
  const mobileNavbarContainerHeight = document.getElementsByClassName(
    "mobile-navbar-container"
  )[0]?.offsetHeight;
  const componentHeight =
    size.height + mobileTabsHeight + mobileNavbarContainerHeight;

  return (
    <Box
      alignSelf="flex-start"
      display="flex"
      bgcolor="white"
      width="100%"
      height={componentHeight}
    >
      <Box bgcolor="white" className="lander" width="100%">
        <Box
          padding="20px 15px 20px 0px"
          ml="15px"
          mr="auto"
          width="fit-content"
          style={{
            cursor: "pointer",
          }}
          onClick={() => {
            close()
            setModalView(null)
          }}
        >
          <FaIcon className="back-btn-icon" icon="chevron-left" />
          <span className="back-btn-text"> All Cards</span>
        </Box>
        <Box
          className="back-btn-line"
          style={{ margin: "0px 0 20px 0", backgroundColor: "#D8D8D8" }}
        />
        <Box
          flexDirection="column"
          width="100%"
          display="flex"
          alignItems="center"
        >
          <GiftCard card={card} />
          <Box mt={2} padding="0 20px" width="350px" display="flex" flexDirection="column">
            <Typography variant="h5">
              Support Token: {card.support_token || "No support token"}
            </Typography>
              <div
                style={{ marginTop: 6 }}
                className={`${card.gift_card.userMarkedAsUsed ? "recover-button" : "delete-button"} no-highlight`}
                onClick={markCardAsUsedorUnused}
              >
                {!card.gift_card.userMarkedAsUsed ? 'Mark as used' : 'Mark as unused'}
              </div>
          </Box>
        </Box>
        <Box display="flex" margin="auto" paddingTop="10px" flexDirection="column" width="350px">
          <GiftCardCreatedTransactionRow card={card} />
          {(card.gift_card.securityCode || card.gift_card.pin) && (
            <GiftCardSecurityCodeInformation cardInfo={card} />
          )}
          {card.gift_card.barcode && (
            <GiftCardBarcodeInformation cardInfo={card} />
          )}
          {card.gift_card.merchantCardWebsite && (
            <GiftCardViewButton
              merchantCardWebsite={card.gift_card.merchantCardWebsite}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default forwardRef((props, ref) => (
  <MobileGiftCardInfo {...props} forwardRef={ref} />
));
