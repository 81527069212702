/**
 * @author https://github.com/paywithmoon/cryptocurrency-icons for crypto
 * @author https://www.iconfinder.com/iconsets/ensign-11 for countries
 */

import React from "react";
import USA from "../../../images/flags/usa.svg";
import CAD from "../../../images/flags/cad.svg";
import BTC from "../../../images/flags/btc.svg";
import ETH from "../../../images/flags/eth.svg";
import LTC from "../../../images/flags/ltc.svg";
import BCH from "../../../images/flags/bch.svg";
import ETC from "../../../images/flags/etc.svg";
import CGLD from "../../../images/flags/celo.svg";
import ZEC from "../../../images/flags/zec.svg";
import USDC from "../../../images/flags/usdc.svg";
import LN from "../../../images/flags/ln.svg";
import USDT from "../../../images/flags/usdt.svg";

const currencyMap = {
    USD: USA,
    CAD: CAD,
    BTC: BTC,
    ETH: ETH,
    LTC: LTC,
    BCH: BCH,
    ETC: ETC,
    CGLD: CGLD,
    ZEC: ZEC,
    USDC: USDC,
    LN:  LN,
    USDT: USDT
};

const CurrencyIcon = (props) => {
    const flagSvg = currencyMap[props.currency] || USA;
    return <img {...props} src={flagSvg} alt={props.currency || "USA"}/>;
};

export default CurrencyIcon;
