import React, {useState, useEffect} from "react";
import "./DeleteCard.css";
import {connect} from "react-redux";
import FaIcon from "../components/misc/fontawesome/FaIcon";
import * as API from "../libs/apiLib";
import * as AuthUser from "../libs/authLib";
import LoadingBody from "../components/shared/Loading/LoadingBody";
import Analytics from "../services/analytics/Mixpanel"
import ErrorModal from '../components/shared/ErrorModal';
import Logger from '../utils/logger';
import { ACTION_SET_HAS_DELETED_CARD, ACTION_SET_CARDS } from '../constants/session';
import {NUM_CARDS_TO_LOAD} from '../components/pages/WebAppHomePage';
import { useHistory } from "react-router-dom";

import moment from "moment";

function DeleteCard({setShouldClickOff, toggleCardInfoModal, mobile, authUser, currentCard, setDeleteCard, onCardDeleted, isCardDeleted, setIsCardDeleted, addActivities, currentCards, setCurrentInactiveCardPage, onSetCards, setHasMoreCards, setHasMoreActivityItems, refreshCardsAndActivities, totalActiveCardsDisplayed, setNumsCardsDeleted, setOnChainInvoices, cardWasDeleted ,setCardWasDeleted}) {
    const [comments, setComments] = useState('');
    const [deletingCard, setDeletingCard] = useState(false);
    const [error, setError] = useState(false);
    const [refundedAmount, setRefundedAmount] = useState('');
    const [confirmedPaymentRefunds, setconfirmedPaymentRefunds] = useState(null)
    const history = useHistory();

    const errorBackBtn = () => {
        setDeletingCard(false);
        setError(false);
    }
    useEffect(() => {
        if (deletingCard) {
            setShouldClickOff(false)
        } else {
            setShouldClickOff(true)
        }
    }, [deletingCard, setShouldClickOff])

    const handleOnChainPaymentsRefund = async (onChainInvoiceId) => {
      const { data: { paymentRefunds, totalRefunded } } = await API.refundOnChainPayments(onChainInvoiceId)
      return { paymentRefunds, totalRefunded }
    }

    const deleteCard = async () => {
        Analytics.track('button_click_delete_card_refund_submit', { application: "webapp" });
        if (deletingCard) {
            return;
        }
        setDeletingCard(true);
        if((!authUser|| !authUser.cards ||
            authUser.cards.length < 1 || !currentCard.token) && !currentCard.on_chain_invoice_id){
            setDeletingCard(false);
            Logger.log("invalid card to delete");
            return;
        }

        try {
          if (currentCard.on_chain_invoice_id) {
            const { paymentRefunds, totalRefunded } = await handleOnChainPaymentsRefund(currentCard.on_chain_invoice_id)
            setconfirmedPaymentRefunds(paymentRefunds);
            setRefundedAmount(totalRefunded.toString());

            //Refresh cards, moon credit, and deletion activity
            await AuthUser.getAuthUser(true, false);
            setOnChainInvoices(prev => prev.filter(function( {invoice} ) {
                return invoice.id !== currentCard.on_chain_invoice_id;
            }))
            addActivities();
          } else {
            const deletedCardData = await API.deleteCard(currentCard.id, comments)
            setRefundedAmount(deletedCardData.data.refundedAmount)

            //Refresh cards and moon credit
            const activeMarqetaCards = authUser.cards.filter(card => card?.gift_card ? !card?.gift_card?.userMarkedAsUsed && card.id !== currentCard.id : card.balance > 0 && !card.deleted && moment().isBefore(card.expiration) && card.id !== currentCard.id)
            await AuthUser.getAuthUser(true, false)
            const cardsDataWithMarqeta = await API.getCards('', 1, NUM_CARDS_TO_LOAD, true)
            onSetCards(cardsDataWithMarqeta.cards.concat(activeMarqetaCards))
    
            const activeCards = currentCards.filter(card => card?.gift_card ? !card?.gift_card?.userMarkedAsUsed && card.id !== currentCard.id : card.balance > 0 && !card.deleted && moment().isBefore(card.expiration) && card.id !== currentCard.id)         
            const inactiveCardsData = await API.getCards('list', 1, NUM_CARDS_TO_LOAD, true)
            onCardDeleted(activeCards.concat(inactiveCardsData.cards))
            if (inactiveCardsData.pagination.total < NUM_CARDS_TO_LOAD) {
              setCurrentInactiveCardPage(1)
            } else {
              setCurrentInactiveCardPage(2)
            };

            //Add deletion activity
            addActivities(currentCard.id, "DELETION")
            Analytics.track("view_screen_delete_card", {application: "webapp"});
            setNumsCardsDeleted(prev => prev + 1)
            setIsCardDeleted(true);
            if(totalActiveCardsDisplayed - 1 <= NUM_CARDS_TO_LOAD){
                setHasMoreCards(true);
                setHasMoreActivityItems(true);
                refreshCardsAndActivities();
            }
          }
          setIsCardDeleted(true)
          setCardWasDeleted(true);
        }catch(err){
            Logger.log("Error deleting card:", err);
            if(err.response?.data?.message === "CHA is not accepted, failed to delete cards"){
              history.push("/cardholder-agreement");
            }else{
              setError(true);
            }
        } finally{
            setDeletingCard(false);
        }
    }

    const getBalanceMessage = () => {
        if (currentCard.balance > 0 && !!currentCard.on_chain_invoice_id) {
          return (
            <>
              <div style={{ marginTop: "15px" }}>
                A credit of <strong>${currentCard.balance}</strong> will be
                added to your Moon account if you delete this pending card.
                <br />
              </div>
            </>
          )
        }
        return currentCard.balance > 0 ? (
          <>
            <div style={{ marginTop: "15px" }}>
              There is an unspent amount on this card. A credit of{" "}
              <strong>${currentCard.balance}</strong> will be added to your Moon
              account if you delete this card. <br />
            </div>
          </>
        ) : (
          <div style={{ marginTop: "15px" }}>
            All funds on this card have been used, so no credit will be applied
            upon deletion.
          </div>
        )
    }

   const renderDeletedCardConfirmation = () =>{
        return (
            <div style={{marginTop:'25px'}}>
                <FaIcon icon={["fa", "check-circle"]} style={{fontSize: '80px', marginBottom:'15px'}} className="checkmark"/>
                <h2>Card Deleted</h2>
                {currentCard.balance < 1
                  ? <p style={{ color: 'black', marginBottom: '25px' }}>Thank you. Your {confirmedPaymentRefunds ? 'your payments have been refunded' : 'card has been deleted.'}</p>
                  : <p style={{ color: 'black', marginBottom: '25px' }}><strong>${refundedAmount}</strong> of credit has been added to your account.</p>
                }
                <button
                    className="btn btn-primary btnHover"
                    onClick={()=>{
                        setCardWasDeleted(false);
                        toggleCardInfoModal();
                        setIsCardDeleted(false);
                }}>Got it</button>
            </div>
        )
    }
    return (
        error ?
        <div>
            <span onClick={()=>errorBackBtn()} style={{cursor: 'pointer', float: 'left'}}><FaIcon className='back-btn-icon' icon="chevron-left"/> <span className='back-btn-text'> Back</span></span>
            <div style={{backgroundColor: 'white', width: !mobile && '430px', borderRadius: '0.25rem', height: '314px'}}>
                <ErrorModal noBottom={true}/>
            </div>
        </div> :
        deletingCard ?
            <div style={{width: !mobile && '430px'}}>
                <LoadingBody handledLayout background={true} text='Deleting Card'/>
                </div>:
                isCardDeleted ? renderDeletedCardConfirmation() :
                <div className='delete-card-container' style={{height: mobile ? 'auto' : '316px'}}>
                    <div className='text'>
                        <span onClick={()=>setDeleteCard(false)} style={{cursor: 'pointer'}}><FaIcon className='back-btn-icon' icon="chevron-left"/> <span className='back-btn-text'> Back</span></span>
                        <br/>
                        {getBalanceMessage()}
                <div style={{marginTop: '5px'}}>
                    {!currentCard.on_chain_invoice_id ? 'Once deleted, pending and future charges to the card will be declined.' : ''}
                </div>
                </div>
                <div className="text" style={{color: 'grey'}}>Comments (Optional)</div>
                <input
                    type="text"
                    placeholder=""
                    className="comments-input"
                    onChange={(e)=>setComments(e.target.value)}
                />
                <button
                    className="btn btn-primary my-3 w-100"
                    disabled={deletingCard || cardWasDeleted}
                    onClick={deleteCard}
                >
                    Submit & Delete Card
                </button>
            </div>
    )
}

const mapStateToProps = (state) => ({
    authUser: state.session.authUser,
    currentCard: state.session.currentCard,
});

const mapDispatchToProps = (dispatch) => ({
    onSetCards: (cards) => dispatch({ type: ACTION_SET_CARDS, cards })
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteCard);