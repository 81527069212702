import React, { useState, useEffect, useRef } from "react";
import './CardOptions.css';
import FaIcon from "../../misc/fontawesome/FaIcon";
import { isMobile } from "react-device-detect";
import CardAmountCheckbox from './../CardAmountCheckbox/CardAmountCheckbox';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { DEBIT_CARD_PRODUCT } from '../../../constants/card';

function CardOptions({ cardProduct, handleCardProductToggle, creditBalance, cardCurrency, moonCreditBalance, rewardsThatCanBeApplied, rewardsThatCanBeAppliedInBase, bitcoinExchangeRate, cardAmount, toggleApplyRewardSats, toggleApplyUsdCredit, applyUsdCredit, applyRewardSats, savedAmount, flags }) {
    const [showDropdown, setShowDropdown] = useState(false);
    const [dropDownWidth, setDropDownWidth] = useState(0);

    const optionsInputRef = useRef(null);
    const setWidth = () => {
        if (optionsInputRef)
            setDropDownWidth(optionsInputRef.current.offsetWidth)
    }

    useEffect(() => {
        //Add listener and resize dropdown when component mounts
        if (optionsInputRef.current.offsetWidth) {
            window.addEventListener('resize', setWidth);
            setDropDownWidth(optionsInputRef.current.offsetWidth);
        }
        return () => {
            window.removeEventListener('resize', setWidth);
        }
    }, [])

    const cardProductToggle = () => {
        return (
            <div>
                <div>
                    <ul test-id='card-amount-checkbox' className="" style={{ paddingLeft: '10px', marginBottom: '10px', listStyleType: 'none', display: 'flex', "--dot-bottom": '17px' }}>
                        <li className={'text-right'} style={{ paddingTop: '10px', paddingBottom: '0' }}>
                            <input
                                type='checkbox'
                                className={'apply-moon-credit-checkbox'}
                                checked={cardProduct === DEBIT_CARD_PRODUCT}
                                onChange={handleCardProductToggle}
                            />
                            <span className='apply-moon-credit-amount no-highlight'>
                                <span
                                    style={{ cursor: 'pointer' }}
                                > Process transactions as prepaid
                                    <span className='prepaid-card-tooltip' >
                                        <FaIcon color="gray" icon={['fa', 'question-circle']} />
                                        <span className="tooltiptext">Some merchants will only allow transactions on cards processed as 'prepaid'</span>
                                    </span>
                                </span>
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }

    const cardAmountCheckBox = () => {
        return (
            <div>
                <CardAmountCheckbox props={{ type: 'rewardSats', rewardSatsBalance: creditBalance, cardCurrency, moonCreditBalance, rewardsThatCanBeApplied, rewardsThatCanBeAppliedInBase, bitcoinExchangeRate, cardAmount, toggleApplyRewardSats, toggleApplyUsdCredit, applyUsdCredit, applyRewardSats, flags }} />
                <CardAmountCheckbox props={{ type: 'moonCredit', rewardSatsBalance: creditBalance, cardCurrency, moonCreditBalance, rewardsThatCanBeApplied, rewardsThatCanBeAppliedInBase, bitcoinExchangeRate, cardAmount, toggleApplyRewardSats, toggleApplyUsdCredit, applyUsdCredit, applyRewardSats, savedAmount, flags }} />
            </div>

        )
    }

    return (
        <div ref={optionsInputRef} className="checkout-section checkout-section-wallet-select no-highlight"
            onClick={(e) => {
                if(e.target.className === 'checkout-section checkout-section-wallet-select no-highlight'){
                    setShowDropdown(!showDropdown)
                }
            }}
        >
            <div
                className="checkout-section-label"
            >
                Options
            </div>
            <div
                test-id='toggle-options-dropdown'
                className={`checkout-wallet-select-change${showDropdown ? " inverse" : ""}`}
                onClick={() => {
                    setShowDropdown(!showDropdown)
                }}
            >
                <FaIcon icon="chevron-down" />
            </div>
            {<div className={`checkout-wallet-select-change-options-selector${showDropdown ? "" : " collapsed"}`}
                style={{
                    width: dropDownWidth + 2,
                    marginLeft: '-1px',
                    position: showDropdown && isMobile && 'sticky',
                    maxHeight: isMobile && showDropdown && 'fit-content',
                    top: isMobile && showDropdown && '0',
                    display: isMobile && !showDropdown && 'none',
                    '--desktop-bottom': '209px',
                    '--mobile-bottom': '542px',
                    cursor: 'initial',
                    pointerEvents: !showDropdown ? 'none' : ''
                }}>
                {cardAmountCheckBox()}
                {flags.cardProductToggle && cardProductToggle(cardProduct, handleCardProductToggle)}
            </div>}
        </div>
    )
}

export default (withLDConsumer()(CardOptions));