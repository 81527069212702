function CryptoConversionRate({props}){
    return (
        <div test-id='crypto-conversion-rate'>
        {
            !!props.exchangeRate &&
            <p className="my-0"
            >
                Conversion Rate: <b><i>1 {props.paymentCurrency} = {new Intl.NumberFormat("en-US", { style: "currency", currency: props.cardCurrency }).format(props.exchangeRate)}</i></b>
            </p>
        }
        {
            !!props.exchangeRateLastUpdated &&
            <p className="my-0 font-size-80"
            >
                <i>Last updated {props.exchangeRateLastUpdated}</i>
            </p>
        }
        </div>
    )
}

export default CryptoConversionRate;