import React, { useState, useEffect } from 'react'
import './CardProductSelector.css'
import { Box, Grid } from '@material-ui/core'
import Analytics from '../../services/analytics/Mixpanel'
import { OpenOrClosedLoop } from '../../utils/enums'
import VisaLogo from '../../images/visa-logo.png'
import { getCardProducts } from '../../libs/apiLib'
import LoadingBody from '../../components/shared/Loading/LoadingBody'
import CardRibbon from '../../components/CardRibbon/CardRibbon'
import { percentageFormat } from '../../utils/numberFormat'
import PropTypes from 'prop-types'
import { Logger } from 'aws-amplify'
import InfiniteScroll from "react-infinite-scroll-component";
import Throbber from '../shared/Throbber/Throbber'
import { isMobile } from "react-device-detect";

const CardProductSelector = ({ setIsCardProductSelected, setSelectedCardProduct, flags }) => {
  const [currentCardProducts, setCurrentCardProducts] = useState([])
  const [page, setPage] = useState(1)
  const [pagination, setPagination] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  let perPage = 16
  const [totalCardProducts, setTotalCardProducts] = useState(0)

  useEffect(() => {
    if (window.innerWidth < 1080 && window.innerWidth > 599) {
      perPage = 32;
    }
    fetchCardProducts();
  }, [])

  const onSelectCardProduct = (cardProductDetails) => {
    Analytics.track('button_click_card_product', { application: 'webapp', product: cardProductDetails.name })
    setIsCardProductSelected(true)
    setSelectedCardProduct(cardProductDetails)
  }

  const fetchCardProducts = async () => {
    try {
      setIsLoading(true)
      const cardProductsRes = await getCardProducts(1, perPage);

      let cardProducts = []
      if (page === 0 && flags?.createBitnobCards) {
        cardProducts = [
          { open_or_closed_loop: OpenOrClosedLoop.OPEN_LOOP, merchant: { card_art_base64: VisaLogo, primary_brand_color: 'black', name: 'visa' } },
          ...cardProductsRes?.data?.giftCards
        ]
      } else {
        cardProducts = cardProductsRes?.data?.giftCards
      }
      setPagination(cardProductsRes?.data?.pagination)
      setTotalCardProducts(cardProductsRes?.data?.pagination?.total)
      setCurrentCardProducts(cardProducts);
      setIsLoading(false)
    }
    catch (e) {
      Logger.log('Error fetching card products', err);
      setIsLoading(false);
    }
  }

  const renderInfiniteScrollLoader = () => {
    return (
      <div>
        <Throbber style={{ height: 40 }} />
        <div className="text-center" style={{ padding: '0 30px', maxHeight: 160 }}>
          <p style={{ fontSize: '12px', marginTop: '0' }}>{'Loading more card products'}</p>
        </div>
      </div>
    )
  }

  const fetchMoreCardProducts = async () => {
    try {
      const cardProductsRes = await getCardProducts(page + 1, perPage);
      const cardProducts = [...currentCardProducts, ...cardProductsRes?.data?.giftCards]

      setCurrentCardProducts(cardProducts)
      setPage(page + 1)
      setPagination(cardProductsRes?.data?.pagination)
    } catch (e) {
      Logger.log('Error fetching card products', err);
    }
  };

  return (
    !totalCardProducts || isLoading
      ? <LoadingBody text='Loading...' />
      : <div id="scrollableDiv" style={{
        height: "95%"
      }}>
        <InfiniteScroll
          height={!isMobile && "100%"}
          style={{ height: '100%' }}
          dataLength={currentCardProducts.length}
          next={fetchMoreCardProducts}
          hasMore={pagination && pagination?.currentPage < pagination?.lastPage}
          loader={renderInfiniteScrollLoader()}
          scrollableTarget={!isMobile  && "scrollableDiv"}
          scrollThreshold={!isMobile  && 0.5}
          endMessage={
            <p style={{ fontSize: '12px', textAlign: 'center' }}>{'Yay! You have seen it all'}</p>
          }
        >
          <Box className='card-products-container' sx={{ flexGrow: 1 }}>
            <Grid container spacing={2} style={{ width: '100%', margin: '-15px' }}>
              {currentCardProducts?.map((cardProduct, i) => {
                const merchantBrandColor = cardProduct?.merchant?.primary_brand_color || 'black';
                const merchantLogo = cardProduct?.merchant?.card_art_base64;
                return (
                  merchantLogo && merchantBrandColor &&
                  <Grid item xs={6} sm={4} md={3} key={i} style={{ padding: '16px 8px' }}>
                    <Box border={`solid 1px ${merchantBrandColor === '#FFFFFF' ? 'black' : merchantBrandColor}`} onClick={() => onSelectCardProduct(cardProduct)} className='card-product-container' bgcolor={merchantBrandColor}>
                      {cardProduct?.discountPercentage ? <CardRibbon ribbonText={`${percentageFormat(cardProduct?.discountPercentage)} Discount`} /> : null}
                        <img src={cardProduct.open_or_closed_loop === OpenOrClosedLoop.OPEN_LOOP && cardProduct.merchant.name !== "Blackhawk Reloadly" ? merchantLogo : `data:image/png;base64,${merchantLogo}`} className='card-product-logo' alt={cardProduct?.merchant?.name} />
                    </Box>
                  </Grid>
                )
              })}
            </Grid>
          </Box>
        </InfiniteScroll>
      </div>
  )
}

CardProductSelector.propTypes = {
  setIsCardProductSelected: PropTypes.func.isRequired,
  setSelectedCardProduct: PropTypes.func.isRequired,
  flags: PropTypes.object.isRequired
}

export default CardProductSelector
