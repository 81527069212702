import { exponentialNumberToDecimal } from '../../CreateCardInputs/CreateCardInputs';
import Decimal from "decimal.js";
import CurrencyIcon from "../../misc/currencyicon/CurrencyIcon";
import FaIcon from "../../misc/fontawesome/FaIcon";
import Analytics from "../../../services/analytics/Mixpanel";
import './BitcoinPaymentMethodModal.css';

function BitcoinPaymentMethodModal({ props }) {
    return (
        <div test-id='bitcoin-payment-method-modal' className={props.isLoadingQR ? 'hide' : 'ui-bitcoin-payment-method-container'}>
            {!props.mobile && <div className={`mobile-body text-center ${props.method === 'lightning' ? 'bitcoin-payment-method-qr-code-canvas-lightning' : 'bitcoin-payment-method-qr-code-canvas-onchain'}`}>
                <canvas ref={props.QRCanvas} style={{ height: '0' }} />
                <img src="" alt="" id="image" ref={props.QRImage} />
            </div>}
            {props.mobile && props.flags.bitcoinBtc && props.flags.lightningPayments && <br />}
            <div>
                <div className="checkout-section" style={{ width: 'fit-content', padding: '0 5px 0 10px' }}>
                    {
                        <div className="checkout-payment-total-value" style={{ width: 'fit-content', paddingRight: '10px' }}>
                            <span
                                style={{ fontSize: '23px', fontWeight: '700', position: 'relative', top: '2px' }}
                            >
                                {exponentialNumberToDecimal(Decimal(props.bitcoinAmount).toString())}
                            </span>
                        </div>
                    }
                    <div
                        className="checkout-section-currency-flag no-highlight"
                    >
                        <div>
                            <span>BTC</span>
                            <CurrencyIcon currency={'BTC'} />
                        </div>
                    </div>
                </div>
            </div>
            <div className='bitcoin-payment-method-modal-usd-amount' style={{ marginTop: '5px', display: 'flex', placeContent: 'center' }}>
                <span style={{ marginRight: '5px', fontWeight: '700' }}>≈ ${`${parseFloat(props.amountUsd).toFixed(2)} ${props.baseCurrency}`}</span>
                <CurrencyIcon style={{ width: '20px' }} currency={props.baseCurrency} />
            </div>
            <div test-id='bitcoin-payment-qr-code' style={{margin: '10px 0 10px 0'}} className={props.method === 'lightning' ? 'bitcoin-payment-method-qr-code-canvas-lightning' :'bitcoin-payment-method-qr-code-canvas-onchain'}>
                <canvas 
                    id="canvas" 
                    style={{cursor:'pointer'}}
                    ref={props.QRCanvas}
                    onClick={() => {
                        if(props.method === 'on-chain'){
                            window.open(`bitcoin:${props.address}?amount=${props.bitcoinAmount}`);
                            Analytics.track("button_click_on_chain_qr_code", { application: "webapp" });
                        }else if(props.method === 'lightning'){
                            window.open("lightning:" + props.address);
                            Analytics.track("button_click_paywall_qr_code", { application: "webapp" });
                        }
                    }}
                ></canvas>
                <img src="" alt="" id="image" ref={props.QRImage} />
            </div>
            <div className={props.method === 'lightning' ? "bitcoin-payment-method-address-lightning" : "bitcoin-payment-method-address-onchain"}>
                {
                    props.method === 'on-chain' ? 
                    <div style={{ fontSize: '14px' }}>BITCOIN ADDRESS</div> :                     
                    <div style={{ fontSize: '14px' }}>LIGHTNING INVOICE <a href="https://paywithmoon.com/lightning-network" target="_blank" rel='noreferrer'><FaIcon style={{ color: '#777f8a' }} icon={["fa", "question-circle"]} /></a></div>
                }
                <div
                    className="bitcoin-payment-method-order-total-value"
                    style={{ cursor: 'pointer', width: (!props.mobile && !props.flags.bitcoinBtc) && '100%' }}
                    onClick={() => {
                        props.copyAddress();
                        if(props.method === 'on-chain') {
                            Analytics.track("button_click_on_chain_copy_address", { application: "webapp" });
                        }else if(props.method === 'lightning'){
                            Analytics.track("button_click_paywall_copy_invoice", { application: "webapp" });
                        }
                    }}
                >
                    <div title="Copy" className='wallet-address'>
                        <span   
                            className="bitcoin-payment-method-modal-address no-highlight"
                            style={{ cursor: 'pointer' }}
                        >{props.address.substring(0,32)}...</span>
                    </div>
                </div>
            </div>
            <div className="bitcoin-payment-method-content-container">
                {
                    props.isActive && props.showTimer &&
                    <div
                        className="bitcoin-payment-method-content expiration"
                    >
                        <span>Price expires in </span>
                        <span style={{ fontWeight: '800' }} ref={props.setTimerDiv}>{props.method === 'on-chain' ? '...' : '03:00'}</span>
                    </div>
                }
            </div>
            <div
                className={`btn btn-primary my-3 w-100 ${props.method === 'lightning' ? 'bitcoin-payment-method-open-wallet-btn-lightning' : 'bitcoin-payment-method-open-wallet-btn-onchain'}`}
                onClick={() => {
                    if(props.method === 'on-chain'){
                        window.open(`bitcoin:${props.address}?amount=${props.bitcoinAmount}`);
                        Analytics.track("button_click_on_chain_open_in_wallet", { application: "webapp" });
                    }else if(props.method === 'lightning'){
                        window.open("lightning:" + props.address);
                        Analytics.track("button_click_paywall_open_in_wallet", { application: "webapp" });
                    }
                }}
            >
                <div>
                    Open In Wallet
                </div>
            </div>
            {!props.mobile && <div className="bitcoin-payment-method-content-container">
                <span className="cancel" onClick={() => {
                    if(props.method === 'on-chain'){
                        props.cancelPayment();
                        Analytics.track("button_click_on_chain_cancel", { application: "webapp" });
                    }else if(props.method === 'lightning'){
                        props.cancelPayment();
                        Analytics.track("button_click_paywall_cancel", { application: "webapp" });
                    }
                }}>Cancel</span>
            </div>}
        </div>
    )
}

export default BitcoinPaymentMethodModal;