import React from "react";

function CardDiscount({cardDiscount}){
    return (
        cardDiscount > 0 && <div>
            Card Discount: <b><i>{` ${cardDiscount}%`}</i></b>
        </div>
    )
}

export default CardDiscount;