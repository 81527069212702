import {
  Box,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography
} from '@material-ui/core'
import { copyToClipboard } from '../../../misc/utils/dom'
import { FileCopy } from '@material-ui/icons'
import useWindowSize from '../../../../hooks/useWindowSize'
import PropTypes from 'prop-types'
import GiftCardInstructionsModal from '../GiftCardInstructionsModal/GiftCardInstructionsModal'

const GiftCardBarcodeInformation = ({ cardInfo }) => {
  const size = useWindowSize()

  const copy = () => {
    copyToClipboard(cardInfo.gift_card.barcode)
  }

  if (!size || !size.width || !cardInfo) return null

  return (
      <Box mt={size.width > 1100 ? '10px' : '24px'} width={'100%'} display="flex" flexDirection="column">
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Typography
            style={{
              fontWeight: 400,
              color: '#737373',
              fontSize: 14
            }}
          >
            Barcode
          </Typography>
          {cardInfo.gift_card &&
          cardInfo.gift_card.merchantRedemptionInstructions &&
          !(cardInfo.gift_card.securityCode || cardInfo.gift_card.pin) && (
            <GiftCardInstructionsModal
              instructions={cardInfo.gift_card.merchantRedemptionInstructions}
              title={'Redemption Instructions'}
            />
          )}
        </Box>
        <OutlinedInput
          id="barcode"
          type={'text'}
          value={cardInfo.gift_card.barcode || 'No barcode'}
          disabled
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="copy barcode"
                onClick={copy}
                edge="end"
              >
                <FileCopy />
              </IconButton>
            </InputAdornment>
          }
        />
      </Box>
  )
}

GiftCardBarcodeInformation.propTypes = {
  cardInfo: PropTypes.object.isRequired
}

export default GiftCardBarcodeInformation
