import { Box, Modal, Typography } from "@material-ui/core";
import useToggle from "../../../../hooks/useToggle";
import FaIcon from '../../../../components/misc/fontawesome/FaIcon';

const GiftCardInstructionsModal = ({ title, instructions }) => {
  const [isOpen, toggle] = useToggle(false);

  return (
    <>
      <button
        style={{
          border: "none",
          backgroundColor: "transparent",
          cursor: "pointer",
          padding: 0,
          margin: 0,
          outline: "none",
          color: "inherit",
        }}
        onClick={toggle}
      >
        <Typography
          className="hoverLink"
          style={{
            fontWeight: 400,
            color: "#007BFF",
            fontSize: 15,
          }}
        >
          {title}
        </Typography>
      </button>
      <Modal
        style={{
          outline: "none",
          border: "none",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={isOpen}
        onClose={toggle}
      >
        <Box
          style={{
            overflowY: "auto",
          }}
          flexDirection="column"
          bgcolor="white"
          display="flex"
          width="70%"
          height="70%"
          px={6}
          py={6}
          alignItems="center"
          position='relative'
        >
          <Box position='absolute' right='30px' top='30px'><FaIcon icon='times' className='close-button' onClick={toggle}/></Box>
          <Typography variant="h3">{title}</Typography>
          <Typography
            style={{
              marginTop: 20,
            }}
            variant="body1"
            dangerouslySetInnerHTML={
              {
                __html: instructions,
              }
            }
          >
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

export default GiftCardInstructionsModal;
