import React, { useEffect, useState } from 'react'
import FaIcon from '../misc/fontawesome/FaIcon'
import './../Profile.css'
import './ApiKeys.css'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import * as API from '../../libs/apiLib'

function ApiKeys({ setShowMobileSideMenu, setMobileOptionActive }) {
    const [userApiKeys, setUserApiKeys] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [showError, setShowError] = useState(false)
    const [showApiKeyModal, setShowApiKeyModal] = useState(false)
    const [apiKeyWithSecret, setApiKeyWithSecret] = useState({})

    const getApiKeysForUser = async () => {
        const userApiKeysData = await API.getUserApiKeys({})
        setIsLoading(false)
        if (userApiKeysData && userApiKeysData.data) {
            setUserApiKeys(userApiKeysData.data)
        }
    }

    useEffect(async () => {
        getApiKeysForUser()
    }, [])

    const getDateToDisplay = (dateIso) => {
        return new Date(dateIso).toLocaleDateString()
    }

    const createApiKey = async () => {
        setIsLoading(true)
        try {
            const apiKeyData = await API.createNewApiKey()
            setApiKeyWithSecret(apiKeyData.data[0])
            setShowApiKeyModal(true)
        } catch (e) {
            setIsLoading(false)
            setShowError(true)
        }
        getApiKeysForUser()
    }

    const deleteApiKey = async () => {
        setIsLoading(false)
        await API.deleteApiKey()
        getApiKeysForUser()
    }

    const closeModal = () => {
        setShowApiKeyModal(false)
    }

    const renderApiKeysModal = () => {
        return (
            <Modal show={showApiKeyModal} onHide={closeModal} backdrop="static" keyboard={false} centered size="lg">
                <Modal.Header closeButton style={{border: 'none'}}>
                    <Modal.Title className='custom-modal-title w-100 text-center'>Your API Credentials</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{padding: '0 40px'}}>
                    <div style={{marginBottom: '5px'}}><span style={{ fontWeight: 'bold' }}>API Key:  </span>{apiKeyWithSecret.api_key}</div>
                    <div style={{marginBottom: '5px'}}><span style={{ fontWeight: 'bold' }}>Client Id:  </span>{apiKeyWithSecret.clientId}</div>
                    <div style={{marginBottom: '5px'}}><span style={{ fontWeight: 'bold' }}>Client Secret:  </span>{apiKeyWithSecret.clientSecret}</div>
                    <div className='modal-divider'></div>
                    <div>
                        <span>To make a request to the Moon API, first fetch an access token:</span>
                        <div className='custom-code-container'>
                            <span>
                                <p className="custom-code-text">curl --location --request POST 'https://staging-card-issuing-api.auth.us-east-1.amazoncognito.com/oauth2/token' \</p>
                                <p className="custom-code-text">--header 'content-type: application/x-www-form-urlencoded' \</p>
                                <p className="custom-code-text">--data-urlencode 'grant_type=client_credentials' \</p>
                                <p className="custom-code-text">--data-urlencode 'client_id={apiKeyWithSecret.clientId}' \</p>
                                <p className="custom-code-text">--data-urlencode 'client_secret={apiKeyWithSecret.clientSecret}'</p>
                            </span>
                        </div>
                    </div>

                    <div style={{marginTop: '20px'}}>
                        <span>Then make your request using the access token and your API key:</span>
                        <div className='custom-code-container'>
                            <div>
                                <p className="custom-code-text">curl --location --request POST 'https://cards.stagingapi.paywithmoon.com/v1/prepaid/lightning' \</p>
                                <p className="custom-code-text">--header 'Authorization: Bearer {"<ACCESS_TOKEN>"}' \</p>
                                <p className="custom-code-text">--header 'x-api-key: {apiKeyWithSecret.api_key}' \</p>
                                <p className="custom-code-text">--header 'Content-Type: application/json' \</p>
                                <p className="custom-code-text">{`--data-raw '{
                                    "card_amount": "1.00",
                                    "client_customer_id": "91404b38-a776-4a72-97b6-84af231d8a26"
                                }'`}</p>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer style={{justifyContent: 'left', border: 'none', padding: '15px 40px'}}>
                    <a href='#'>View more documentation on Moon’s Card Issuing API</a>
                </Modal.Footer>
            </Modal>
        )
    }

    return (
        <div test-id='api-keys-component'>
            {renderApiKeysModal()}
            <span onClick={() => {
                setShowMobileSideMenu(true)
                setMobileOptionActive('')
            }} style={{ cursor: 'pointer' }}>
                <div className='api-keys-back-btn-icon'>
                    <FaIcon className='back-btn-icon' icon="chevron-left" />
                    <span className='back-btn-text' test-id='api-keys-back-btn'> Back</span>
                </div>
            </span>
            <h3 className='api-keys-title' test-id='api-keys-title'>Api Keys</h3>
            {!userApiKeys.length && <div className='create-key-button' test-id='api-keys-create-btn'>
                {!isLoading && <Button variant="primary" onClick={createApiKey}>Create API Key</Button>}
                {isLoading && <Button variant="primary" test-id='api-keys-loading'>Loading...</Button>}
                <br />
                {showError && <span test-id='api-keys-error-msg' style={{ color: 'red' }}>Failed to create an API key, please try again</span>}
            </div>}
            <br />
            {userApiKeys.length > 0 && <div className='api-keys-table'>
                {<Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>API Key</th>
                            <th>Rate Limit</th>
                            <th>Created</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{userApiKeys[0].api_key}</td>
                            <td></td>
                            <td>{getDateToDisplay(userApiKeys[0].created_at)}</td>
                            <td>
                                <Button variant="secondary" onClick={deleteApiKey}>Delete Key</Button>
                            </td>
                        </tr>
                    </tbody>
                </Table>}
            </div>}
        </div>
    )
}

export default ApiKeys
