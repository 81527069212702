import React from "react";
import PropTypes from "prop-types";
import "./CardRibbon.css";

const CardRibbon = ({ ribbonText, isCardDetailView }) => {
  return (
    <div class={`discount-ribbon ${isCardDetailView ? "detail-view" : ""}`}>
      {ribbonText}
    </div>
  );
};

CardRibbon.propTypes = {
  ribbonText: PropTypes.string.isRequired,
  isCardDetailView: PropTypes.bool,
};

export default CardRibbon;
