export const LIGHTNING = "LIGHTNING";
export const ON_CHAIN = "ON_CHAIN";

const existingBtcTransactions = [{
    label: 'Lightning',
    key: LIGHTNING
}, {
    label: 'On-Chain',
    key: ON_CHAIN
}];

export default existingBtcTransactions;