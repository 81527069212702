import { exponentialNumberToDecimal } from '../../CreateCardInputs/CreateCardInputs'
import Decimal from 'decimal.js'
import CurrencyIcon from '../../misc/currencyicon/CurrencyIcon'
import FaIcon from '../../misc/fontawesome/FaIcon'
import Analytics from '../../../services/analytics/Mixpanel'
import './UsdcPaymentMethodModal.css'
import { PaymentOptions } from '../../../utils/enums'

function UsdcPaymentMethodModal ({ props }) {
  const openLink = () => {
    const isTron = PaymentOptions[props.method] === 'TRON'
    const isEthereum = PaymentOptions[props.method] === 'ETHEREUM'

    // TODO: edit address (with eth/tron value)
    if (isTron) {
      //window.open(props.address)
      Analytics.track('button_click_tron_qr_code', { application: 'webapp' })
    } else if (isEthereum) {
      window.open(`ethereum:${props.address}`)
      Analytics.track('button_click_ethereum_qr_code', { application: 'webapp' })
    }
  }
  return (
        <div test-id='usdc-payment-method-modal' className={props.isLoadingQR ? 'hide' : 'ui-usdc-payment-method-container'}>
            {!props.mobile && <div className={`mobile-body text-center ${PaymentOptions[props.method] === 'ETHEREUM' ? 'usdc-payment-method-qr-code-canvas-ethereum' : 'usdc-payment-method-qr-code-canvas-tron'}`}>
                <canvas ref={props.QRCanvas} style={{ height: '0' }} />
                <img src="" alt="" id="image" ref={props.QRImage} />
            </div>}
            {(props.mobile) && <br />}
            <div>
                <div className="checkout-section" style={{ width: 'fit-content', padding: '0 5px 0 10px' }}>
                    {
                        <div className="checkout-payment-total-value" style={{ width: 'fit-content', paddingRight: '10px' }}>
                            <span
                                style={{ fontSize: '23px', fontWeight: '700', position: 'relative', top: '2px' }}
                            >
                                {Number(props.amountUsdc).toFixed(2)}
                            </span>
                        </div>
                    }
                    <div
                        className="checkout-section-currency-flag no-highlight"
                    >
                        <div>
                            <span>USDC</span>
                            <CurrencyIcon currency={'USDC'} />
                        </div>
                    </div>
                </div>
            </div>
            <div test-id='usdc-payment-qr-code' style={{ margin: '22px 0px 10px 0px' }} className={PaymentOptions[props.method] === 'ETHEREUM' ? 'usdc-payment-method-qr-code-canvas-ethereum' : 'usdc-payment-method-qr-code-canvas-tron'}>
                <canvas
                    id="canvas"
                    style={{ cursor: 'pointer' }}
                    ref={props.QRCanvas}
                    onClick={openLink}
                ></canvas>
                <img src="" alt="" id="image" ref={props.QRImage} />
            </div>
            <div className={PaymentOptions[props.method] === 'ETHEREUM' ? 'usdc-payment-method-address-ethereum' : 'usdc-payment-method-address-tron'}>
                {
                    PaymentOptions[props.method] === 'ETHEREUM'
                      ? <div style={{ fontSize: '14px' }}>ETHEREUM ADDRESS</div>
                      : <div style={{ fontSize: '14px' }}>TRON ADDRESS</div>
                }
                <div
                    className="usdc-payment-method-order-total-value"
                    style={{ cursor: 'pointer', width: (!props.mobile) && '100%' }}
                    onClick={() => {
                      props.copyAddress()
                      if (PaymentOptions[props.method] === 'TRON') {
                        Analytics.track('button_click_tron_copy_address', { application: 'webapp' })
                      } else if (PaymentOptions[props.method] === 'ETHEREUM') {
                        Analytics.track('button_click_paywall_copy_invoice', { application: 'webapp' })
                      }
                    }}
                >
                    <div title="Copy" className='wallet-address'>
                        <span
                            className="usdc-payment-method-modal-address no-highlight"
                            style={{ cursor: 'pointer' }}
                        >{props.address.substring(0, 32)}...</span>
                    </div>
                </div>
            </div>
            <div
                className={`btn btn-primary my-3 w-100 ${PaymentOptions[props.method] === 'ETHEREUM' ? 'usdc-payment-method-open-wallet-btn-ethereum' : 'usdc-payment-method-open-wallet-btn-tron'}`}
                onClick={() => {
                  if (PaymentOptions[props.method] === 'TRON') {
                    // TODO: edit address (with eth/tron value)
                    //window.open(props.address)
                    Analytics.track('button_click_tron_open_in_wallet', { application: 'webapp' })
                  } else if (PaymentOptions[props.method] === 'ETHEREUM') {
                    window.open(`ethereum:${props.address}`)
                    Analytics.track('button_click_ethereum_open_in_wallet', { application: 'webapp' })
                  }
                }}
            >
                <div>
                    Open In Wallet
                </div>
            </div>
            {!props.mobile && <div className="usdc-payment-method-content-container">
                <span className="cancel" onClick={() => {
                  if (PaymentOptions[props.method] === 'TRON') {
                    Analytics.track('button_click_tron_cancel', { application: 'webapp' })
                  } else if (PaymentOptions[props.method] === 'ETHEREUM') {
                    Analytics.track('button_click_ethereum_cancel', { application: 'webapp' })
                  }
                  props.cancelPayment()
                }}>Cancel</span>
            </div>}
        </div>
  )
}

export default UsdcPaymentMethodModal
