export const cardFundEnums = {
    PAID: 'PAID',
    UNDERFUNDED: 'UNDERFUNDED',
    PENDING: 'PENDING',
    REFUNDED_TO_CREDIT: 'REFUNDED_TO_CREDIT'
}

export const PaymentOptions = {
    TRON: 'TRON',
    ETHEREUM: 'ETHEREUM'
  }

export const ConfirmationCountToRefundPerBlockchain = {
    BITCOIN: 3,
    ETHEREUM: 12,
    TRON: 19,
};

export const ConfirmationCountToIssueCard = {
    BITCOIN: 1,
    ETHEREUM: 12,
    TRON: 19,
};

export const TransactionStatusLink = {
    BITCOIN: (transactionHash) => `https://www.blockchain.com/btc/tx/${transactionHash}`,
    ETHEREUM: (transactionHash) => `https://etherscan.io/tx/${transactionHash}`,
    TRON: (transactionHash) => `https://tronscan.org/#/transaction/${transactionHash}`
}

export const OpenOrClosedLoop = {
    OPEN_LOOP: 'OPEN_LOOP',
    CLOSED_LOOP: 'CLOSED_LOOP'
}
