import React, { useState, useEffect } from "react";
import UIPayWall from '../misc/uiblocker/UIPayWall';
import BitcoinPaymentTabs from "./BitcoinPaymentTabs/BitcoinPaymentTabs";
import OnChainTransaction from '../OnChainTransaction/OnChainTransaction';

import * as AuthUser from '../../libs/authLib';
import { LIGHTNING, ON_CHAIN } from '../../utils/existingBtcTransactions';

const BaseCurrency = {
    USD: 'USD',
};
// isOnChain & isLightning : handles which payment method we want to initially display
// displayOnChain & displayLightning: handles which payment method we want to display with tab logic
export function BitcoinPayment({ props }) {
    const [isOnChain, setIsOnChain] = useState(props.flags.lightningPayments && props.flags.bitcoinBtc ? false : props.flags.bitcoinBtc ? true : false);
    const [isLoading, setIsLoading] = useState(true);
    const [isLightning, setIsLightning] = useState(props.flags.lightningPayments && props.flags.bitcoinBtc ? false : props.flags.lightningPayments ? true : false);
    const [displayOnChain, setDisplayOnChain] = useState(props.flags.lightningPayments && props.flags.bitcoinBtc ? false : props.flags.bitcoinBtc ? true : false);
    const [displayLightning, setDisplayLightning] = useState(props.flags.lightningPayments && props.flags.bitcoinBtc ? false : props.flags.lightningPayments ? true : false);
    const [bitcoinPaymentLoading, setBitcoinPaymentLoading] = useState(false);

    useEffect(() => {
        if(props.flags.lightningPayments && props.flags.bitcoinBtc){
            if (!props.isUnderpaidOnChainInvoice) {
                AuthUser.getAuthUser(true, false)
                .then(user => {
                    switch (user.defaultBtcPaymentMethod) {
                        case LIGHTNING:
                            setDisplayLightning(true);
                            setIsLightning(true);
                            setDisplayOnChain(false);
                            setIsOnChain(false);
                            break;
                        case ON_CHAIN:
                            setDisplayOnChain(true);
                            setIsOnChain(true);
                            setDisplayLightning(false);
                            setIsLightning(false);
                            break;
                        default:
                            setDisplayOnChain(true);
                            setIsOnChain(true);
                            setDisplayLightning(false);
                            setIsLightning(false);
                    }
                }).catch(() => {
                    setDisplayOnChain(true);
                    setIsOnChain(true);
                    setDisplayLightning(false);
                    setIsLightning(false);
                }).finally(() => { setIsLoading(false) });
            } else {
                setDisplayOnChain(true);
                setIsOnChain(true);
                setDisplayLightning(false);
                setIsLightning(false);
            }
        }
    }, [props.isUnderpaidOnChainInvoice]);

    const renderHeader = (type) => {
        //Header for desktop
        const hasAllPaymentOptions = props.flags.lightningPayments && props.flags.bitcoinBtc;
        const isOnlyOnChain = !props.flags.lightningPayments && props.flags.bitcoinBtc;
        const isOnlyLightning = props.flags.lightningPayments && !props.flags.bitcoinBtc;
        const isLoaded = !bitcoinPaymentLoading && !isLoading && !props.isLoading;
        const isNotUnderpaidOnChainInvoice = !props.isUnderpaidOnChainInvoice;
        if(type === 'tabs'){
            return (
                hasAllPaymentOptions && 
                isNotUnderpaidOnChainInvoice && 
                isLoaded && 
                <BitcoinPaymentTabs 
                    props={{ 
                        device: 'desktop', 
                        setIsLightning, 
                        setIsOnChain, 
                        setDisplayLightning, 
                        setDisplayOnChain, 
                        displayOnChain, 
                        displayLightning 
                    }} 
                />
            )
        }else if(type === 'title'){
            return (
                (!props.isLoading && !bitcoinPaymentLoading) && 
                (isOnlyOnChain || isOnlyLightning) && 
                <div className='payment-title-desktop'>{isOnChain ? 'On-Chain' : 'Lightning'}</div>
            )
        }
    }
    
    return (
        <div test-id='bitcoin-payment'>
            {
                renderHeader('tabs')
            }
            {
                renderHeader('title')
            }
            {isOnChain &&
                <OnChainTransaction props={{
                    setIsOnChain,
                    tabProps: { setIsLightning, setDisplayLightning, setDisplayOnChain, displayOnChain, displayLightning },
                    isActive: true,
                    cardAmount: props.cardAmount,
                    applyRewardSats: props.applyRewardSats,
                    applyUsdCredit: props.applyUsdCredit,
                    setIsLoading: props.setIsLoading,
                    setHideCard: props.setHideCard,
                    mobile: props.mobile,
                    baseCurrency: BaseCurrency.USD,
                    setToggleConfirmation: props.setToggleConfirmation,
                    setShowBitcoinPayment: props.setShowBitcoinPayment,
                    setBitcoinPaymentModal: props.setBitcoinPaymentModal,
                    setApplyRewardSats: props.setApplyRewardSats,
                    setApplyUsdCredit: props.setApplyUsdCredit,
                    bitcoinPaymentLoading: bitcoinPaymentLoading,
                    setBitcoinPaymentLoading: setBitcoinPaymentLoading,
                    setError: props.setError,
                    setUserError: props.setUserError,
                    setUserErrorText: props.setUserErrorText,
                    setOnChainComplete: props.setOnChainComplete,
                    isUnderpaidOnChainInvoice: props.isUnderpaidOnChainInvoice,
                    onChainTransactionData: props.onChainTransactionData,
                    setIsOnChainPaymentCanceled: props.setIsOnChainPaymentCanceled,
                    setCreditOrSatsComplete: props.setCreditOrSatsComplete,
                    setPopUpMessageData: props.setPopUpMessageData,
                    setPopUpMessage: props.setPopUpMessage,
                    cardProduct: props.cardProduct,
                    flags: props.flags

                }} />}
                { isLightning && <UIPayWall
                    tabProps={{setIsLightning, setDisplayLightning, setDisplayOnChain, displayOnChain, displayLightning}}
                    bitcoinPaymentLoading={bitcoinPaymentLoading}
                    setBitcoinPaymentLoading={setBitcoinPaymentLoading}
                    setPopUpMessage={props.setPopUpMessage}
                    setPopUpMessageData={props.setPopUpMessageData}
                    setApplyRewardSats={props.setApplyRewardSats}
                    setApplyUsdCredit={props.setApplyUsdCredit}
                    applyRewardSats={props.applyRewardSats}
                    applyUsdCredit={props.applyUsdCredit}
                    setUserErrorText={props.setUserErrorText}
                    setUserError={props.setUserError}
                    mobile={props.mobile}
                    error={props.error}
                    setIsOnChain={setIsOnChain}
                    setBitcoinPaymentModal={props.setBitcoinPaymentModal}
                    setLightningComplete={props.setLightningComplete}
                    isActive={true}
                    setError={props.setError}
                    cardAmount={props.cardAmount}
                    baseCurrency={BaseCurrency.USD}
                    isPayDeposit={false}
                    setToggleConfirmation={props.setToggleConfirmation}
                    isLoading={true}
                    setAmount={props.setAmount}
                    setIsLoading={props.setIsLoading}
                    setShowBitcoinPayment={props.setShowBitcoinPayment}
                    setHideCard={props.setHideCard} 
                    createCardErrorBackBtn={props.createCardErrorBackBtn}
                    cardProduct={props.cardProduct}
                    flags={props.flags}
                />
            }
        </div>
    )
}

export default BitcoinPayment;
