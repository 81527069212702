import React, { useEffect, useState } from 'react'
import * as API from '../libs/apiLib'
import * as AuthUser from '../libs/authLib'
import { connect } from 'react-redux'
import FaIcon from './misc/fontawesome/FaIcon'
import './MoonCredit.css'
import { useHistory } from 'react-router-dom'
import Logger from '../utils/logger'
import { useAppContext } from '../libs/contextLib'
import RequestRefund from './RequestRefund'
import Analytics from '../services/analytics/Mixpanel'
import RefundHistory from './RefundHistory'

function MoonCredit ({ authUser, setShowMobileSideMenu, setMobileOptionActive, isMobile }) {
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()
  const { isUserRestricted } = useAppContext()
  const [shouldClickOff, setShouldClickOff] = useState(true)
  const [requestRefundModal, setRequestRefundModal] = useState(false)
  const [amount, setAmount] = useState('')
  const [address, setAddress] = useState('')
  const [refundHistory, setRefundHistory] = useState([])
  const [modalPageNum, setModalPageNum] = useState(0)

  useEffect(() => {
    if (isUserRestricted) {
      history.push('/dashboard')
    }

    const fetchRefundHistory = async () => {
      const refundHistoryRes = await API.getOffPlatformRefunds()
      const refundHistory = refundHistoryRes.data
      setRefundHistory(refundHistory)
    }

    fetchRefundHistory()
  }, [])

  const modalAction = () => {
    if (requestRefundModal) {
      if (window.innerWidth < 1080) {
        document.documentElement.style.overflow = 'auto'
        window.scrollTo(0, 0)
        document.getElementsByClassName('container')[0].style.display = 'none'
      } else {
        document.getElementsByClassName('container')[0].style.display = 'initial'
        document.documentElement.style.overflow = 'hidden'
      }
    }
  }

  // Prevents scrolling when modal appears
  useEffect(() => {
    if (!isMobile) {
      if (requestRefundModal) {
        if (window.innerWidth < 1080) {
          document.documentElement.style.overflow = 'auto'
          window.scrollTo(0, 0)
          document.getElementsByClassName('container')[0].style.display = 'none'
        } else {
          document.getElementsByClassName('container')[0].style.display = 'revert'
          document.documentElement.style.overflow = 'hidden'
        }
      } else {
        document.getElementsByClassName('container')[0].style.display = 'revert'
        document.documentElement.style.overflow = 'auto'
      }

      window.addEventListener('resize', modalAction)

      return () => { window.removeEventListener('resize', modalAction) }
    }
  }, [requestRefundModal])

  const handleBackButton = () => {
    if (!requestRefundModal) {
      setShowMobileSideMenu(true)
      setMobileOptionActive('')
    } else if (requestRefundModal && isMobile) {
      if (modalPageNum === 0) {
        closeRequestRefundModal()
      } else {
        setModalPageNum(0)
      }
    }
  }

  const closeRequestRefundModal = () => {
    setRequestRefundModal(false)
    setAddress('')
    setAmount('')
    setShouldClickOff(true)
    setModalPageNum(0)
    Analytics.track('button_click_request_refund_close', { application: 'webapp' })
  }

  const renderMoonCredit = () => {
    if (isUserRestricted) {
      return
    }
    return (
        <div>
            <h3 className='moon-credit-title'>Moon Credit</h3>
            <div className='credit-section' style={{ marginBottom: '20px' }}>
                <h2 className="moon-credit-element-title">Amount</h2>
                <span className='credit'>{authUser.moonCredit > 0 ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(authUser.moonCredit) : '$0'}</span>
            </div>
            <p className='credit-definition'>
                When you delete a Moon Visa® Prepaid Card, you will receive the unspent amount on the card as Moon Credit. Moon Credit can be applied towards the purchase of a Moon card. <a style={{ color: 'inherit', textDecoration: 'underline' }} href="https://paywithmoon.com/terms-conditions#moon-credit" target="_blank" rel="noreferrer">Read more</a>
            </p>
            <button className='btn btn-primary refund-request-btn' onClick={(e) => {
              Analytics.track('button_click_request_refund_open', { application: 'webapp' })
              e.stopPropagation()
              setRequestRefundModal(true)
            }}
              disabled={authUser.moonCredit === 0}
            >
                Request a Refund
            </button>
        </div>
    )
  }
  return (
        <div onClick={() => shouldClickOff ? closeRequestRefundModal() : null}>
            <div className={isMobile ? 'mobile-moon-credit-view' : 'desktop-moon-credit-view'}>
                {modalPageNum < 2 && isMobile &&
                    <span onClick={handleBackButton}
                          style={{ cursor: 'pointer' }}>
                          <FaIcon className='back-btn-icon' icon="chevron-left"/>
                          <span className='back-btn-text'> Back</span>
                      </span>
                }
                {((requestRefundModal && !isMobile) || !requestRefundModal) && renderMoonCredit()}
                {requestRefundModal &&
                    <RequestRefund
                      modalPageNum={modalPageNum}
                      setModalPageNum={setModalPageNum}
                      isMobile={isMobile}
                      setShouldClickOff={setShouldClickOff}
                      setRefundHistory={setRefundHistory}
                      closeRequestRefundModal={closeRequestRefundModal}
                      setAmount={setAmount}
                      amount={amount}
                      moonCreditAmount={authUser.moonCredit}
                      address={address}
                      setAddress={setAddress}/>
                }
                {((requestRefundModal && !isMobile) || !requestRefundModal) && refundHistory.length > 0 && <RefundHistory refundHistory={refundHistory} isMobile={isMobile}/>}
            </div>
        </div>
  )
}

const mapStateToProps = (state) => ({
  authUser: state.session.authUser
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(MoonCredit)
