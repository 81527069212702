import React from "react";
import "./WaysToPayAndVirtualCards.css";
import "../../shared/Styling/Product.css";

export default class WaysToPayAndVirtualCards extends React.Component {

    render() {
        return (
            <section className="product-content">
                <div className="container">

                    <div className="row">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col col-lg-5 float-md-left" style={{maxWidth: "80%"}}>
                                    <img src="/images/moon-card.png"  className="img-fluid" alt=""/>
                                </div>
                                <div className="col-lg-5 float-md-right py-3" style={{paddingLeft: "30px", paddingRight: "30px"}}>
                                    {/*<div className="col py-3">*/}
                                        <h2 className="h3">What is a Virtual Card?</h2>
                                        <div>
                                            <p>A virtual card is a temporary digital payment card that can be used to make online purchases.</p>
                                            <p>Moon Cards are virtual cards that can be loaded with cryptocurrency.</p>
                                            <p>Crypto is converted to USD the moment you purchase a card and funds are immediately available to spend where you want. </p>
                                            {/*<p>Each card can only be used once, so even if your payment information is leaked, there is no risk to the security of your coins.</p>*/}
                                        </div>
                                    {/*</div>*/}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="container pt-5 pb-5">
                            <div className="row justify-content-center">
                                <div className="col-lg-5 order-md-12">
                                    <div className="row justify-content-center">
                                        <div className="col-md-10" >
                                            <img src="/images/crypto-to-card.png"  className="img-fluid" alt="Coinbase Bitcoin Litcoin Ether Bitcoin Cash"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5 py-3 ordermd--1" style={{paddingLeft: "30px", paddingRight: "30px"}}>
                                    <h2 className="h3">Bitcoin, Litecoin, Ether, Oh My!</h2>
                                    <div>
                                        <p>Purchase Moon cards with Bitcoin, Litecoin, Ether, or Bitcoin Cash directly from your Coinbase account, or with Bitcoin using your favorite Lightning Network wallet.</p>
                                        <p>There are no trading fees, waiting times, or hurdles to prevent you from using your card.</p>
                                    </div>
                                    <div className="row py-3 ">
                                        <div className="col-md-6 text-center py-3">
                                            <img src="/images/coinbase/moon-coinbase-logo.png"  className="img-fluid ways-to-pay" alt="Coinbase Bitcoin Litcoin Ether Bitcoin Cash"/>
                                        </div>
                                        <div className="col-md-6 text-center">
                                            <img src="/images/lightning-logo-2.png"  className="img-fluid" alt="Lightning Network"/>
                                            <small><a href="/lightning-network">What is Lightning Network?</a></small>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}