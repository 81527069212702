import React from "react";
import "./AuthLoading.css";

export default function AuthLoading(){
    return (
        <div className="AuthLoading">
            <div className="lander">
                <h1>Logging you in...</h1>
                <p>Please do not close this window</p>
            </div>
        </div>
    );
}