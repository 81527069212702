/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import crypto from "crypto";
import React, { useEffect, useRef, useState } from 'react'
import Logger from '../../../utils/logger'
import Logo from '../../../images/moon-logo-black.svg'
import FaIcon from '../../misc/fontawesome/FaIcon'
import LoadingBody from '../../shared/Loading/LoadingBody'
import Snackbar from '@material-ui/core/Snackbar'
import { isMobile } from 'react-device-detect'
import Analytics from '../../../services/analytics/Mixpanel'
// import './OnChainTransaction.css'
import Poller from '../../misc/utils/Poller'
import UsdtPaymentMethodModal from '../UsdtPaymentMethodModal/UsdtPaymentMethodModal'
import { generateQRCode } from '../../misc/qrgenerator/qrGenerator'
import { copy } from '../../misc/copytoclipboard/copyToClipboard'
import * as API from '../../../libs/apiLib'
import { ACTION_SET_CURRENT_CARD } from '../../../redux/constants'
import { connect } from 'react-redux'
import store from '../../../redux/store'
import { getOnChainAddressOrCard } from '../../../libs/apiLib'
import { PaymentOptions } from '../../../utils/enums'
import Alert from '../../Alert'
import UsdtPaymentTabs from '../UsdtPaymentTabs/UsdtPaymentTabs'

function UsdtPaymentOptions({ props, onSetCurrentCard, currentCard }) {
  const QRCanvas = useRef(null)
  const QRImage = useRef(null)
  const [address, setAddress] = useState('')
  const [amountUsdt, setAmountUsdt] = useState(0)
  const [copied, setCopied] = useState(false)
  const [isLoading, setIsLoading] = useState(!props.isUnderpaidOnChainInvoice)
  const [justCopiedProperty, setJustCopiedProperty] = useState(null)
  const [isLoadingQR, setIsLoadingQR] = useState(true)

  useEffect(() => {
    const isTronPaymentCompleted = store.getState().session.currentCard.usdtTronPaymentCompleted
    const isEthereumPaymentCompleted = store.getState().session.currentCard.usdtEthereumPaymentCompleted
    if (isTronPaymentCompleted) {
      onSetCurrentCard({ ...currentCard, usdtTronPaymentCompleted: false })
    } else if (isEthereumPaymentCompleted) {
      onSetCurrentCard({ ...currentCard, usdtEthereumPaymentCompleted: false })
    }

    props.setIsOnChainPaymentCanceled(false)
    if (isLoading === true) {
      getUsdtAddress(props.cardAmount, props.cardProduct)
    } else {
      if (props.isUnderpaidOnChainInvoice) {
        setAddress(props.onChainTransactionData.address)
        setAmountUsdt(props.onChainTransactionData.cryptoAmountOwed)
        generateQRCode(QRCanvas, QRImage, props.onChainTransactionData.blockchain === 'ETHEREUM' ? `ethereum:${props.onChainTransactionData.address}` : props.onChainTransactionData.address, setIsLoadingQR, Logo)
        pollForDeposit(props.onChainTransactionData.invoiceId)
      }
    }
  }, [])

  const copyAddress = () => {
    copy(isMobile, setCopied, address, setJustCopiedProperty)
  }

  const cancelPayment = () => {
    setCopied(false)
    setAddress('')
    props.setUsdtPaymentModal(false)
    props.setHideCard(false)
    props.setToggleConfirmation(true)
    props.setShowUsdtPayment(false)
    props.setIsOnChainPaymentCanceled(true)
  }

  const completePayment = (onChainInvoiceId, OnChainInvoiceBalance) => {
    Logger.log('completePayment')
    if (PaymentOptions[props.paymentOption] === 'TRON') {
      props.tabProps.setDisplayEthereum(false)
      props.tabProps.setDisplayTron(true)
    } else if (PaymentOptions[props.paymentOption] === 'ETHEREUM') {
      props.tabProps.setDisplayEthereum(true)
      props.tabProps.setDisplayTron(false)
    }
    onSetCurrentCard({
      on_chain_invoice_id: onChainInvoiceId,
      balance: OnChainInvoiceBalance,
      usdtTronPaymentCompleted: PaymentOptions[props.paymentOption] === 'TRON',
      usdtEthereumPaymentCompleted: PaymentOptions[props.paymentOption] === 'ETHEREUM'
    })
    if (!props.isUnderpaidOnChainInvoice) {
      props.setApplyRewardSats(false)
      props.setApplyUsdCredit(false)
    }
    setCopied(false)
    props.setUsdtPaymentLoading(true)
    setIsLoading(true)
    setAddress('')
    props.setOnChainComplete(true)
  }

  const pollForDeposit = (onChainInvoiceID) => {
    // call query on depositAddress every second for 5 minutes to check if the deposit is done

    const pollDuration = 1000 * 60 * 5
    let elapsedDuration = 0
    let retries = 0

    // Set 5s timeout between polls
    // note: this is previous request + processing time + timeout
    const poller = new Poller(5000)

    // Wait till the timeout sent our event to the EventEmitter
    poller.onPoll(async () => {
      Logger.log('triggered')
      try {
        const onChainInvoiceData = await API.getOnChainInvoices({
          onChainInvoiceID,
          includeExchangeRateLock: false
        })
        // polling for a new invoice payment after we displayed the address to the user
        const paid = onChainInvoiceData.data[0].payments.length > 0 &&
          currentCard.invoicePayments !== onChainInvoiceData.data[0].payments.length &&
          onChainInvoiceData.data[0].paymentStatus !== 'PROCESSING'
        elapsedDuration += 5000
        if (!paid) {
          Logger.log('usdt invoice not paid yet')
          const isTronPaymentCompleted = store.getState().session.currentCard.usdtTronPaymentCompleted
          if (isTronPaymentCompleted) {
            Logger.log('stopped polling due to payment completion (usdt)')
          } else if (elapsedDuration < pollDuration) {
            poller.poll() // Go for the next poll
          } else {
            Logger.log('usdt invoice expired')
          }
        } else {
          completePayment(onChainInvoiceID, onChainInvoiceData.data[0].invoice.requestedCardValue)
        }
      } catch (err) {
        retries += 1
        if (retries < 4) {
          poller.poll()
        }
        Logger.log(`Retry #${retries}, ${err}`)
      }
    })

    // Start polling
    poller.poll()
  }

  const getUsdtAddress = async (amount, cardProduct) => {
    try {
      if (address.length < 1 && props.isActive) {
        Logger.log('getUsdtAddress inside')
        const cardProductId = store.getState().session.currentCard?.cardProductId;
        const cardProductLogo = store.getState().session.currentCard?.cardProductLogo;
        const cardProductHash = cardProductLogo ? crypto.createHash("sha256").update(cardProductLogo).digest("hex") : null;

        const onChainData = await getOnChainAddressOrCard(amount, props.applyRewardSats, props.applyUsdCredit, 'USDT', cardProduct, 'TRON', undefined, 'USDT', cardProductId, cardProductHash)
        if (onChainData.data.type === 'CARD') {
          setIsLoadingQR(false)
          props.setIsLoading(false)
          props.setUsdtPaymentLoading(true)
          Logger.log('completePayment')
          props.setApplyRewardSats(false)
          props.setApplyUsdCredit(false)
          setCopied(false)
          setIsLoading(true)
          setAddress('')
          props.setCreditOrSatsComplete(true)
          props.setOnChainComplete(true)
          onSetCurrentCard({
            on_chain_invoice_id: null,
            balance: null
          })
        } else {
          props.setHideCard(true)
          pollForDeposit(onChainData.data.onChainInvoiceId)
          setAddress(onChainData.data.address)
          setAmountUsdt(onChainData.data.cryptoAmountOwed)
          setIsLoading(false)
          // TODO: edit address (with eth/tron value)
          generateQRCode(QRCanvas, QRImage, onChainData.data.address, setIsLoadingQR, Logo)
          props.setIsLoading(false)
        }
      }
    } catch (err) {
      if (err.response && err.response.status === 402) {
        Logger.log(err.response.data.message)
        props.setUserError(true)
        props.setUserErrorText(err.response.data.message)
        props.setIsLoading(false)
      } else {
        props.setError(true)
        props.setIsLoading(false)
      }
      props.setShowUsdtPayment(false)
      props.setUsdtPaymentModal(false)
      setAddress('Sorry, try again later.')
      setAmountUsdt('X')
      setIsLoading(false)
      Logger.error('USDT INV ERROR ' + err)
      Analytics.track('create_new_card_create_card_usdt_error', { application: 'webapp' })
    }
  }

  const renderHeader = (type) => {
    //Header for mobile
    const hasAllPaymentOptions = props.flags.tronUsdt && props.flags.ethereumUsdt;
    const isOnlyTron = props.flags.tronUsdt && !props.flags.ethereumUsdt;
    const isOnlyEthereum = !props.flags.tronUsdt && props.flags.ethereumUsdt;
    const didLoadQR = !isLoadingQR;
    const isNotUnderpaidOnChainInvoice = !props.isUnderpaidOnChainInvoice;
    const didLoadUsdtPayment = !props.usdtPaymentLoading;

    if(type === 'tabs'){
      return (
        hasAllPaymentOptions && 
        didLoadQR && 
        isNotUnderpaidOnChainInvoice && 
        didLoadUsdtPayment && 
        <UsdtPaymentTabs 
          props={{ 
              setIsEthereum: props.tabProps.setIsEthereum, 
              device: 'mobile', 
              setIsTron: props.tabProps.setIsTron, 
              setDisplayEthereum: props.tabProps.setDisplayEthereum, 
              setDisplayTron: props.tabProps.setDisplayTron, 
              displayTron: props.tabProps.displayTron, 
              displayEthereum: props.tabProps.displayEthereum 
            }} 
        />
      )
  }else if(type === 'title'){
      return (
        (didLoadQR && 
        !props.isLoading && 
        didLoadUsdtPayment) && 
        (isOnlyEthereum || isOnlyTron) && 
        <div className='payment-title-mobile'>{props.paymentOption === 'ETHEREUM' ? 'Ethereum' : 'Tron'}</div>
      )
  }
  }

  return (
    props.isActive &&
    <div style={{ display: (PaymentOptions[props.paymentOption] === 'ETHEREUM' ? props.tabProps.displayTron : props.tabProps.displayEthereum) ? 'none' : 'block' }}>
      {!isLoading &&
        <div className='mobile-view'>
          <div style={{ display: 'flex' }}>
            <div onClick={() => {
              cancelPayment()
              Analytics.track('button_click_on_chain_cancel', { application: 'webapp' })
            }} style={{ height: 'auto', cursor: 'pointer', marginLeft: '15px', marginRight: 'auto', width: 'fit-content', padding: '30px 15px 20px 0px' }}>
              <FaIcon className='back-btn-icon' icon="chevron-left" />
              <span className='back-btn-text'> Back</span>
            </div>
            <span style={{ padding: '20px 15px 20px 0px', fontWeight: '700', fontSize: '20px' }}>Create a New Card</span>
          </div>
          <div className='back-btn-line' style={{ margin: '0px 0 20px 0', backgroundColor: '#D8D8D8' }} /></div>
      }
      {
        renderHeader('tabs')
      }
      {
        renderHeader('title')
      }
      <div id="moon-ui-on-chain">
        <div id="moon-ui-on-chain-ui">
          {props.mobile && (props.flags.tronUsdt && props.flags.ethereumUsdt) && <br />}
          {
            !!props.usdtPaymentLoading &&
            <div className={isMobile ? 'ui-on-chain-loading-container-mobile' : 'ui-on-chain-loading-container'}>
              <LoadingBody text='Creating Card' />
            </div>
          }
          {
            !isLoading && <UsdtPaymentMethodModal
              props={{
                method: (props.onChainTransactionData?.blockchain === 'ETHEREUM' || PaymentOptions[props.paymentOption] === 'ETHEREUM') ? 'ETHEREUM' : 'TRON',
                isLoadingQR,
                QRCanvas,
                QRImage,
                amountUsdt,
                copyAddress,
                address,
                cancelPayment,
                isActive: props.isActive,
                mobile: props.mobile,
                flags: props.flags
              }}
            />
          }
          {
            isLoadingQR &&
            <LoadingBody text='Loading...' />
          }
        </div>
      </div>
      <Snackbar
        open={justCopiedProperty}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        style={{
          left: '50%',
          transform: 'translateX(-50%)',
          width: 'fit-content'
        }}
      >
        <Alert severity="success">Copied!</Alert>
      </Snackbar>
    </div>
  )
}

const mapStateToProps = (state) => ({
  currentCard: state.session.currentCard
})

const mapDispatchToProps = (dispatch) => ({
  onSetCurrentCard: (currentCard) => dispatch({ type: ACTION_SET_CURRENT_CARD, currentCard })
})

export default connect(mapStateToProps, mapDispatchToProps)(UsdtPaymentOptions)
