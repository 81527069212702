import React, {useEffect} from "react"
import { useHistory } from "react-router-dom";
import {connect} from "react-redux";
import FaIcon from "./misc/fontawesome/FaIcon";
import './Profile.css';
import CoinbaseIcon from "../components/misc/coinbase/CoinbaseIcon";
import {URL_COINBASE_AUTH} from '../constants/coinbase';
import './ConnectToCoinbase.css';
import Logger from '../utils/logger';
import * as AuthUser from "../libs/authLib";

function ConnectToCoinbase({authUser, setShowMobileSideMenu, setMobileOptionActive}){
    const history = useHistory();

    const launchCoinbaseAuthFlow = () => {
        if (process.env.NODE_ENV === "production") {
            window.open(URL_COINBASE_AUTH, '_blank', '');
        } else {
            history.push("/dashboard/connect-coinbase")
        }
    };

    useEffect(()=>{
        async function refreshAuthUser(){
            await AuthUser.getAuthUser(true, true);
        }
        refreshAuthUser();
    }, []);

    return (
        <div>
        <span onClick={()=>{
            setShowMobileSideMenu(true)
            setMobileOptionActive('')
        }} style={{cursor: 'pointer'}}>
            <div className='connect-to-coinbase-back-btn-icon'>
                <FaIcon className='back-btn-icon' icon="chevron-left"/> 
                <span className='back-btn-text'> Back</span>
            </div>
        </span>
            <h3 className='connect-to-coinbase-title'>Connect to Coinbase</h3>
            <div>
            {Logger.log(authUser.wallets)}
            {
                authUser.wallets && authUser.wallets.length > 0  ?

                    <div>
                        <div className='coinbase-connected'>
                        <FaIcon icon={["fa", "check-circle"]} className="checkmark"/>
                        <p className='coinbase-connect-text'>COINBASE IS CONNECTED. </p>
                        </div>
                        <br/>
                        <button className="btn-connect-coinbase" onClick={() => {
                            launchCoinbaseAuthFlow();
                        }}>
                            <div className="btn-icon"><CoinbaseIcon/></div>
                            <div className="btn-text">Reconnect Coinbase</div>
                        </button>
                    </div>

                    :

                    <div>
                        <p>Link your Coinbase account to Moon to make purchases from your Coinbase wallets</p>
                        <button className="btn-connect-coinbase" onClick={() => {
                            launchCoinbaseAuthFlow();
                        }}>
                            <div className="btn-icon"><CoinbaseIcon/></div>
                            <div className="btn-text">Connect Coinbase</div>
                        </button>
                    </div>
            }

        </div>
        </div>

    );
}

const mapStateToProps = (state) => ({
    authUser: state.session.authUser
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(ConnectToCoinbase);