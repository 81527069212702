import React, { useEffect } from "react";
import CurrencyIcon from "../misc/currencyicon/CurrencyIcon";
import FaIcon from "../misc/fontawesome/FaIcon";
import CoinbaseIcon from "../misc/coinbase/CoinbaseIcon";
import { URL_COINBASE_AUTH } from '../../constants/coinbase';
import { isMobile } from "react-device-detect";
import { MIN_REWARD_SATS_BALANCE_FOR_USE, MIN_MOON_CREDIT_BALANCE_FOR_USE } from '../CreateCardInputs/CreateCardInputs';

const renderSelectedWallet = (selectedWallet, setIsShowingWallets, isShowingWallets, cardCurrency, wallets) => {
    const showWallets = (e) => {
        //Prevents "Learn more" from opening wallet dropdown
        if (e.target.tagName === 'A') {
            return;
        }
        setIsShowingWallets(!isShowingWallets)
    }
    return (
            selectedWallet &&
            <div
                style={{cursor: (wallets.length <= 1 && wallets[0].provider !== "CONNECT_COINBASE") && 'default'}}
                className="checkout-wallet-select-selected-wallet"
                onClick={(e) => {
                    if(wallets.length === 1 && wallets[0].provider === "CONNECT_COINBASE"){
                        window.open(URL_COINBASE_AUTH, '_blank', '');
                    }else if(wallets.length > 1){
                        showWallets(e)
                    }
                }}
            >
                {(selectedWallet.provider === "BITCOIN_PAYMENT" || selectedWallet.provider === "USDC_PAYMENT" || selectedWallet.provider === "USDT_PAYMENT" ) && <div style={{paddingBottom:'17.094px'}}></div>}
                <div className="checkout-wallet-select-selected-wallet-name" style={{fontSize: (selectedWallet.provider === "BITCOIN_PAYMENT" || selectedWallet.provider === "USDC_PAYMENT" || selectedWallet.provider === "USDT_PAYMENT") && '22px', fontWeight: (selectedWallet.provider === "BITCOIN_PAYMENT" || selectedWallet.provider === "USDC_PAYMENT" || selectedWallet.provider === "USDT_PAYMENT") && '200', position: (selectedWallet.provider === "BITCOIN_PAYMENT" || selectedWallet.provider === "USDC_PAYMENT" || selectedWallet.provider === "USDT_PAYMENT") && 'relative', bottom: (selectedWallet.provider === "BITCOIN_PAYMENT" || selectedWallet.provider === "USDC_PAYMENT" || selectedWallet.provider === "USDT_PAYMENT") && '1px'}}>{selectedWallet.name}</div>
                {(selectedWallet.provider !== "BITCOIN_PAYMENT" || selectedWallet.provider === "USDC_PAYMENT" || selectedWallet.provider === "USDT_PAYMENT") && <div className={`checkout-wallet-select-selected-wallet-balance ${(selectedWallet.provider === "BITCOIN_PAYMENT" || selectedWallet.provider === "CONNECT_COINBASE" || selectedWallet.provider === "USDC_PAYMENT" || selectedWallet.provider === "USDT_PAYMENT") ? " hide" : ""}`}>{selectedWallet.balance}</div>}
                {(selectedWallet.provider === "BITCOIN_PAYMENT" || selectedWallet.provider === "USDC_PAYMENT" || selectedWallet.provider === "USDT_PAYMENT" ) ? <div style={{paddingTop: '17.094px'}}></div> : <div className={`checkout-wallet-select-selected-wallet-balance ${(selectedWallet.provider === "CONNECT_COINBASE" || selectedWallet.provider === "USDC_PAYMENT" || selectedWallet.provider === "USDT_PAYMENT") ? "" : " hide"}`}>{selectedWallet.alias}</div>}
                <div className={`checkout-wallet-select-selected-wallet-balance-base ${(selectedWallet.provider === "BITCOIN_PAYMENT" || selectedWallet.provider === "CONNECT_COINBASE" || selectedWallet.provider === "USDC_PAYMENT" || selectedWallet.provider === "USDT_PAYMENT") ? " hide" : ""}`}>
                    {
                        new Intl.NumberFormat("en-US", { style: "currency", currency: cardCurrency }).format(selectedWallet.balanceInBase)
                    }
                </div>
            </div>
    )
}

const renderDropDownArrow = (isShowingWallets) => {
    return (
        <div
            test-id='toggle-wallets-dropdown'
            className={`checkout-wallet-select-change${isShowingWallets ? " inverse" : ""}`}
        >
            <FaIcon icon="chevron-down" />
        </div>
    )
}

const renderWallets = (props) => {
    return (
        props.wallets && props.sortWallets(props.wallets)
                .filter(wallet => !props.selectedWallet || wallet.id !== props.selectedWallet.id)
                .map(wallet =>
                    <div
                        key={wallet.id}
                        style={{ height: wallet.provider !== "CONNECT_COINBASE" && '43px' }}
                        test-id={`wallet-${wallet.currency}`}
                        className={`checkout-wallet-select-change-wallet-selection ${wallet.provider}`}
                        onClick={() => {
                            if (wallet.provider === "CONNECT_COINBASE") {
                                window.open(URL_COINBASE_AUTH, '_blank', '');
                            } else {
                                props.changeWallet(wallet);
                                props.setSavedSelectedWallet(wallet);
                                props.setIsShowingWallets(false)
                            }
                        }}
                    >
                        {
                            wallet.provider === "CONNECT_COINBASE" ?
                                <div className="btn-coinbase-icon pt-2 pl-2 pr-2"><CoinbaseIcon /></div>
                                :
                                <CurrencyIcon currency={wallet.currency} className="pl-2" style={{ height: 'fit-content' }} />
                        }

                        <div style={{position: wallet.provider === "CONNECT_COINBASE" && 'relative', bottom: wallet.provider === "CONNECT_COINBASE" && '5px'}} className={`pl-2 checkout-wallet-select-change-wallet-text-area ${(wallet.provider === "CONNECT_COINBASE") ? "pt-2 pb-2" : ""}`}>
                            <p className="my-0 font-weight-bold" style={{ fontSize: '13px', top: (wallet.provider === 'BITCOIN_PAYMENT' || wallet.provider === 'USDC_PAYMENT' || wallet.provider === "USDT_PAYMENT") && '20%', position: (wallet.provider === 'BITCOIN_PAYMENT' || wallet.provider === 'USDC_PAYMENT' || wallet.provider === "USDT_PAYMENT") && 'relative' }}>{wallet.name}</p>
                            <p
                                className={`checkout-wallet-select-change-wallet-selection-value my-0 font-size-80 ${(wallet.provider === "BITCOIN_PAYMENT" || wallet.provider === "USDC_PAYMENT" || wallet.provider === "USDT_PAYMENT" || wallet.provider === "CONNECT_COINBASE" ? "hide" : "")}`}
                                style={{ fontSize: '12px' }}
                            >
                                <i>{wallet.balance}</i>
                            </p>
                            <p
                                className={`checkout-wallet-select-change-wallet-selection-value my-0 font-size-80 ${(wallet.provider === "BITCOIN_PAYMENT" || wallet.provider === "USDC_PAYMENT" || wallet.provider === "USDT_PAYMENT"  || wallet.provider === "CONNECT_COINBASE" ? "" : "hide")}`}
                                style={{ fontSize: (wallet.provider === 'CONNECT_COINBASE' || wallet.provider === 'BITCOIN_PAYMENT' || wallet.provider === "USDC_PAYMENT" || wallet.provider === "USDT_PAYMENT" ) && '12px' }}
                            >
                                <i>{wallet.alias}</i>
                            </p>
                        </div>
                        <div className={`checkout-wallet-select-change-wallet-selection-fiat-value ${(wallet.provider === "BITCOIN_PAYMENT" || wallet.provider === "USDC_PAYMENT" || wallet.provider === "USDT_PAYMENT"  || wallet.provider === "CONNECT_COINBASE" ? "hide" : "")}`}>
                            <p>{new Intl.NumberFormat("en-US", { style: "currency", currency: props.cardCurrency }).format(wallet.balanceInBase)}</p>
                        </div>
                    </div>
                )
    )
}

const renderDropDownOptions = (props) => {
    return (
        <div className={`checkout-wallet-select-change-wallet-selector${props.isShowingWallets ? "" : " collapsed"}`}
                    style={{
                        width: props.dropDownWidth,
                        position: props.isShowingWallets && isMobile && 'sticky',
                        maxHeight: isMobile && props.isShowingWallets && 'fit-content',
                        top: isMobile && props.isShowingWallets && '0',
                        display: isMobile && !props.isShowingWallets && 'none',
                        '--desktop-bottom': props.flags.cardProductToggle && props.displayCardOptions ? '317px' : ((props.moonCreditBalance > MIN_MOON_CREDIT_BALANCE_FOR_USE && props.creditBalance >= MIN_REWARD_SATS_BALANCE_FOR_USE) ? '311px' : props.creditBalance >= MIN_REWARD_SATS_BALANCE_FOR_USE || props.moonCreditBalance > MIN_MOON_CREDIT_BALANCE_FOR_USE ? '277px' : '248px'),
                        '--mobile-bottom': props.flags.cardProductToggle && props.displayCardOptions ? '650px' : ((props.moonCreditBalance > MIN_MOON_CREDIT_BALANCE_FOR_USE && props.creditBalance >= MIN_REWARD_SATS_BALANCE_FOR_USE) ? '644px' : props.creditBalance >= MIN_REWARD_SATS_BALANCE_FOR_USE || props.moonCreditBalance > MIN_MOON_CREDIT_BALANCE_FOR_USE ? '611px' : '582px')
                    }}>
                    {renderWallets(props)}
                </div>
    )
}

function PaymentMethodSelector({props}) {
    useEffect(()=> {
        if(props.savedSelectedWallet){
            props.setSelectedWallet(props.savedSelectedWallet)
        }

    }, [props.selectedWallet])
    return(
            <div style={{cursor: props.wallets.length <= 1 && 'default'}} className="checkout-section checkout-section-wallet-select no-highlight"
                onClick={() => {
                    if(props.wallets.length > 1){
                        props.setIsShowingWallets(!props.isShowingWallets)
                    }
                }}
            >
                <div
                    className="checkout-section-label"
                >
                    Payment Method
                </div>
                {renderSelectedWallet(props.selectedWallet, props.setIsShowingWallets, props.isShowingWallets, props.cardCurrency, props.wallets)}
                {
                    !props.selectedWallet &&
                    <div className="checkout-wallet-select-selected-wallet" />
                }
                {props.wallets.length > 1 ? renderDropDownArrow(props.isShowingWallets) : <span style={{width: isMobile ? '12px' : '34px'}}></span>}
                {renderDropDownOptions(props)}
            </div>
    )
}

export default PaymentMethodSelector;