import React from "react";
import { Select, MenuItem, makeStyles } from "@material-ui/core";
import "./DenominationSelector.css";

const DenominationSelector = ({
    denominations, cardAmount, setCardAmount, paymentMethodSelectorCardAmountChanged,
}) => {
    const useStyles = makeStyles(() => ({
        select: {
            "& .MuiSelect-select:focus": {
                background: "none",
            },
            "&:before": {
                background: "none",
                border: "none",
            },
            "&:after": {
                background: "none",
                border: "none",
            },
        },
    }));
    const [filteredDenominations, setFilteredDenominations] = React.useState(denominations.filter((denomination) => denomination.inStock));

    React.useEffect(() => {
        setFilteredDenominations(denominations.filter((denomination) => denomination.inStock));
    }, [denominations]);

    const classes = useStyles();

    return (
        <Select
            className={classes.select}
            disableUnderline
            style={{ borderBottom: "1px solid black" }}
            value={cardAmount}
            displayEmpty
            onChange={(e) => {
                setCardAmount(e.target.value);
                paymentMethodSelectorCardAmountChanged(e);
            }
            }
            MenuProps={{
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                },
                getContentAnchorEl: null,
            }}
        >
            <MenuItem value="" disabled>
                <span className='amount-select' style={{ color: "rgb(128 128 128)", fontSize: "15px" }}>Select Amount</span>
            </MenuItem>
            {filteredDenominations?.map((denomination) => {
                const amount = denomination.denomination;
                return (
                    <MenuItem key={amount} value={amount}>
                        <span className='amount-select' style={{ fontSize: "18px", fontWeight: "700" }}>{amount}</span>
                    </MenuItem>);
            })}
        </Select>
    );
};

export default DenominationSelector;
