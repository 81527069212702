import React from "react";
import Analytics from "../../../services/analytics/Mixpanel";
import './UsdcPaymentTabs.css';

function UsdcPaymentTabs({ props }) {

    const onToggle = (paymentOption) => {
        const isEthereum = paymentOption === 'ethereum'
        const isTron = paymentOption === 'tron'
    
        props.setDisplayEthereum(isEthereum);
        props.setDisplayTron(isTron);

        //Stays as true to prevent address change
        if(isEthereum){
            props.setIsEthereum(true);
        }else if(isTron){
            props.setIsTron(true);
        }
    }
    return (
        <div test-id='usdc-payment-tabs' className={`usdc-payment-tabs-${props.device}`}>
            <button className='usdc-payment-left-tab'
                    disabled={props.displayTron}
                    style={{
                        color: props.displayTron ? '#0667d0' : '#9b9b9b',
                        border: props.displayEthereum ? '1px solid lightgray' : '2px solid #a2abce',
                        backgroundColor: props.displayTron ? '#e3edf7' : 'white',
                    }}
                    onClick={() => {                        
                        onToggle('tron')
                        Analytics.track("button_click_tron_tab", { application: "webapp" });
                    }}
            >
                Tron
            </button>
            <button className='usdc-payment-right-tab'
                disabled={props.displayEthereum}
                style={{
                    color: props.displayEthereum ? '#0667d0' : '#9b9b9b',
                    border: props.displayTron ? '1px solid lightgray' : '2px solid #a2abce',
                    backgroundColor: props.displayEthereum ? '#e3edf7' : 'white',
                }}
                onClick={() => {
                    onToggle('ethereum')
                    Analytics.track("button_click_ethereum_tab", { application: "webapp" });
                }}
            >
                Ethereum
            </button>
        </div>
    )
}

export default UsdcPaymentTabs;
