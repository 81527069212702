import React, { useState, useEffect, useRef } from "react";
import "./CreateCardInputs.css";
import moment from "moment";
import Decimal from "decimal.js";
import { getRequiredAmountInQuote, getWalletBalanceInBase, getRewardsBalanceInBase, getExchangeRates } from "../../utils/exchangerates";
import * as sb from "satoshi-bitcoin";
import { connect } from "react-redux";
import Logger from '../../utils/logger';
import { isMobile } from "react-device-detect";
import deepEqual from 'deep-equal';
import CardAmountInput from "./CardAmountInput/CardAmountInput";
import CardOptions from "./CardOptions/CardOptions";
import PaymentMethodSelector from '../PaymentMethodSelector/PaymentMethodSelector';
import CryptoConversionRate from '../CryptoConversionRate/CryptoConversionRate';
import PaymentAmount from './PaymentAmount/PaymentAmount';
import Analytics from "../../services/analytics/Mixpanel";
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import CardAmountCheckbox from './CardAmountCheckbox/CardAmountCheckbox';
import CardFees from '../CardFees/CardFees';
import CardDiscount from '../CardDiscount/CardDiscount';
import { getCardFees, getCardDiscount } from "../../libs/apiLib";
import { RELOADLY_VISA_CARD_PRODUCT_ID } from "../../config";

export const SUPPORTED_CURRENCIES = ["BTC", "ETH", "LTC", "BCH", "CGLD", "ZEC", "USDC", "USDT"];
export const MIN_REWARD_SATS_BALANCE_FOR_USE = 50;
export const MIN_MOON_CREDIT_BALANCE_FOR_USE = 0;

export const exponentialNumberToDecimal = (amount) => {
    if (amount.indexOf('e') !== -1) {
        const length = amount.toString().length;
        const exp = amount.toString().indexOf('-');
        const fixed = amount.toString().slice(exp + 1, length);
        return Number(amount).toFixed(fixed);
    } else {
        return amount;
    }
};

const THIRTY_SECONDS_IN_MS = 1000*30;

function CreateCardInputs({
    authUser, setExchangeRates, exchangeRates, savedSelectedWallet, onWalletChanged, onExchangeRatesChanged, setError,
    setPaymentMethodSelectorError, savedExchangeRates, setSavedExchangeRates, amount, applyRewardSats, applyUsdCredit,
    onConfirmation, setSavedSelectedWallet, onCardAmountChanged, toggleApplyRewardSats, toggleApplyUsdCredit, savedAmount, cardProduct, handleCardProductToggle, maxCardValue, flags, selectedCardProduct
}) {
    const [requiredAmountInQuote, setRequiredAmountInQuote] = useState(0);
    const [isShowingWallets, setIsShowingWallets] = useState(false);
    const [selectedQuickViewCurrency, setSelectedQuickViewCurrency] = useState(SUPPORTED_CURRENCIES[0]);
    const [cardAmount, setCardAmount] = useState(0);
    const [cardCurrency, setCardCurrency] = useState('USD');
    const [selectedWallet, setSelectedWallet] = useState(null);
    const [dropDownWidth, setDropDownWidth] = useState(0);
    const [wallets, setWallets] = useState(Array.from(authUser.wallets) || []);
    const [fieldValidator, setFieldValidator] = useState({ cardAmount: true });
    const [gotInitExchangeRates, setGotInitExchangeRates] = useState(false);
    const cardAmountInputRef = useRef(null);
    const [displayCardOptions, setDisplayCardOptions] = useState(true);
    const [cardFees, setCardFees] = useState(null);
    const [cardDiscount, setCardDiscount] = useState(0);

    const usePrevious = value => {
        const ref = React.useRef();
        React.useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    };

    const prevExchangeRates = usePrevious(exchangeRates);
    const prevAuthUser = usePrevious(authUser);

    const addFiatBalancesToWallets = (wallets) => {
        try {
            wallets.forEach((wallet, index, wallets) => {
                if (!!wallet.currency && !!wallet.balance && wallet.provider === "COINBASE") {
                    const exchangeRate = exchangeRates[`${wallet.currency}_${cardCurrency}`].bid;
                    wallets[index] = {
                        ...wallet,
                        balanceInBase: getWalletBalanceInBase(wallet.balance, exchangeRate)
                    }
                }
            });
        } catch (err) {
            Logger.log(err);
            Analytics.track("view_screen_create_card_error", { application: "webapp" });
            setError(true);
            setPaymentMethodSelectorError(true);
        }
    };

    const addBitcoinPaymentToWallets = (wallets) => {

        if (!flags.lightningPayments && (!flags.bitcoinBtc || !flags.onChainTransactions)) {
            return;
        }

        const isBitcoinPaymentWalletPresent = wallets.find((wallet) => {
            return wallet.provider === "BITCOIN_PAYMENT";
        });

        if (isBitcoinPaymentWalletPresent) {
            return;
        }

        const bitcoinPayment = {
            id: 'Bitcoin Payment',
            name: 'Bitcoin',
            balance: 0, // used to bypass outdated logic that needs to be refactored, always just enough balance
            alias: '',
            provider: 'BITCOIN_PAYMENT',
            currency: 'BTC',
            balanceInBase: 0
        };
        // make sure Bitcoin Payment shows up at the top position in the list in the drop down
        wallets.push(bitcoinPayment);
    };

    const addUsdcPaymentToWallets = (wallets) => {
        if (!flags.onChainTransactions || (!flags.tronUsdc && !flags.ethereumUsdc)) {
            return
        }

        const isUsdcPaymentWalletPresent = wallets.find((wallet) => {
            return wallet.provider === "USDC_PAYMENT";
        });

        if (isUsdcPaymentWalletPresent) {
            return;
        }

        const usdcPayment = {
            id: 'USDC Payment',
            name: 'USDC',
            balance: 0, // used to bypass outdated logic that needs to be refactored, always just enough balance
            alias: '',
            provider: 'USDC_PAYMENT',
            currency: 'USDC',
            balanceInBase: 0
        };
        // make sure USDC Payment shows up at the top position in the list in the drop down
        wallets.push(usdcPayment);
    };

    const addUsdtPaymentToWallets = (wallets) => {
        if (!flags.onChainTransactions || (!flags.tronUsdt && !flags.ethereumUsdt)) {
            return;
        }

        const isUsdtPaymentWalletPresent = wallets.find((wallet) => {
            return wallet.provider === "USDT_PAYMENT";
        });

        if (isUsdtPaymentWalletPresent) {
            return;
        }

        const usdtPayment = {
            id: 'USDT Payment',
            name: 'USDT',
            balance: 0, // used to bypass outdated logic that needs to be refactored, always just enough balance
            alias: '',
            provider: 'USDT_PAYMENT',
            currency: 'USDT',
            balanceInBase: 0
        };
        // make sure USDT Payment shows up at the top position in the list in the drop down
        wallets.push(usdtPayment);
    };

    const walletsContainCoinbaseWallets = (wallets) => {
        return wallets.find((wallet) => {
            return wallet.provider === "COINBASE" || wallet.provider === "CONNECT_COINBASE";
        });
    }

    const addCoinbaseConnectToWallets = (wallets) => {

        if(flags.disableCoinbase){
            return;
        }

        // do this instead of checking boolean value isCoinbaseconnected in case there is
        // an error fetching the info from Coinbase we can get the user to reconnect Coinbase. Maybe change in future
        const isCoinbaseConnected = walletsContainCoinbaseWallets(wallets);

        if (!isCoinbaseConnected) {
            const connectCoinbase = {
                id: 'Connect Coinbase',
                name: 'Connect Coinbase',
                balance: 0,
                alias: 'Pay from your Coinbase wallets',
                provider: 'CONNECT_COINBASE',
                currency: 'BTC',
                balanceInBase: 0
            };
            wallets.push(connectCoinbase);
        }
    };

    const updateExchangeRates = async () => {
        try {
            const paymentMethod = selectedWallet && selectedWallet.provider === "COINBASE" ? "COINBASE" : null;
            const pairs = SUPPORTED_CURRENCIES.map(quote => ({ quote, base: cardCurrency }));
            const currentExchangeRates = await getExchangeRates(pairs);
            setExchangeRates(currentExchangeRates);
            onExchangeRatesChanged(currentExchangeRates);
            if (savedExchangeRates) {
                setSavedExchangeRates(currentExchangeRates);
            }
            setGotInitExchangeRates(true);
        } catch (err) {
            Analytics.track("view_screen_create_card_error", { application: "webapp" });
            Logger.log("UPDATE EXCHANGE RATE ERROR", err);
        }
    };

    const sortWallets = (wallets) => {
        if (wallets.find(wallet => wallet.provider === "CONNECT_COINBASE")) {
            return wallets;
        }
        return wallets.sort((a, b) => b.balanceInBase - a.balanceInBase);
    };

    const changeWallet = (selectedWallet) => {
        setSelectedWallet(selectedWallet)
        onWalletChanged(selectedWallet);
    };

    const setWidth = () => {
        if (cardAmountInputRef)
            setDropDownWidth(cardAmountInputRef.current.offsetWidth)
    }

    const addFiatBalanceToWallet = (wallet) => {
        try {
            // set balance for currently selected wallet
            if (!!wallet && wallet.provider === "COINBASE") {
                const exchangeRate = exchangeRates[`${wallet.currency}_${cardCurrency}`].bid;
                wallet.balanceInBase = getWalletBalanceInBase(wallet.balance, exchangeRate);
            }
        } catch (err) {
            Logger.log(err);
            Analytics.track("view_screen_create_card_error", { application: "webapp" });
            setError(true);
            setPaymentMethodSelectorError(true);
        }
    };

    const getFontSize = (str) => {
        if (!str || str.length <= 12) {
            return 18;
        } else {
            // Formula created using excel manually. LOL.
            return Math.floor(227.71 * Math.pow(str.length, -0.951));
        }
    };

    const paymentMethodSelectorCardAmountChanged = async (event) => {
        let cardAmount = Math.abs(parseFloat(event.target.value)); // non negative
        const isAmountValid = cardAmount <= maxCardValue
        setFieldValidator({ cardAmount: isAmountValid })
        // If entered amount is greater than than the maximum acceptable amount, the amount should be reduced to the greatest acceptable amount plus $0.01
        cardAmount = Math.min(cardAmount, maxCardValue + 0.01);
        if (Number.isNaN(cardAmount)) {
            cardAmount = 0;
        }
        const newCardAmount = Number(cardAmount).toFixed(2);
        setCardAmount(newCardAmount)
        onCardAmountChanged(newCardAmount)

        if(flags.createBitnobCards){
            const cardFeesRes = await getCardFees(selectedCardProduct?.cardProductId);
            const cardCreationFee = Number(cardFeesRes?.data.cardCreationFee);
            const loadFeePercent = Number(cardFeesRes?.data.loadFeePercent);
            const loadFeeUnrounded = Number(cardAmount * (Number(cardFeesRes?.data.loadFeePercent) / 100));
            const loadFee = Math.round(loadFeeUnrounded * 100) / 100;
            setCardFees({cardCreationFee, loadFeePercent, loadFee});
        }
    };

    const updatePaymentAmount = () => {
        const requiredAmountInQuoteBeforeRewardsDecimalString = getRequiredAmountInQuote(cardAmountBeforeRewardsWithFees, exchangeRate);
        const requiredAmountInQuoteBeforeRewards = (!!Number(requiredAmountInQuoteBeforeRewardsDecimalString) && requiredAmountInQuoteBeforeRewardsDecimalString) || "0";
        setRequiredAmountInQuote(requiredAmountInQuoteBeforeRewards);

        // apply rewards to crypto amount
        if (applyRewardSats) {
            const cardAmountAfterSats = Decimal.max(Decimal(requiredAmountInQuoteBeforeRewards).minus(rewardsThatCanBeAppliedInQuote), 0).toString();
            setRequiredAmountInQuote(exponentialNumberToDecimal(cardAmountAfterSats))
        } else if (applyUsdCredit) {
            const cardAmountAfterMoonCredit = Decimal.max(Decimal(requiredAmountInQuoteBeforeRewards).minus(moonCreditThatCanBeAppliedInQuote), 0).toString();
            setRequiredAmountInQuote(exponentialNumberToDecimal(cardAmountAfterMoonCredit))
        }
    }

    useEffect(() => {
        if(creditBalance <= MIN_REWARD_SATS_BALANCE_FOR_USE && moonCreditBalance <= MIN_MOON_CREDIT_BALANCE_FOR_USE && !flags.cardProductToggle){
            setDisplayCardOptions(false);
        }

        // set default exchange rate data
        onExchangeRatesChanged(exchangeRates);

        updateExchangeRates(); // eventually have exchange rates fetched when first opening moon in additon to here
        const exchangeRateUpdateTimer = setInterval(() => updateExchangeRates(), THIRTY_SECONDS_IN_MS);

        //Add listener and resize dropdown when component mounts
        if (cardAmountInputRef.current.offsetWidth) {
            window.addEventListener('resize', setWidth);
            setDropDownWidth(cardAmountInputRef.current.offsetWidth);
        }

        return () => {
            clearInterval(exchangeRateUpdateTimer);
            window.removeEventListener('resize', setWidth);
        }
    }, [])

    useEffect(() => {
        async function getCardDiscountPercent() {
            const cardDiscountRes = await getCardDiscount(selectedCardProduct?.id);
            const cardDiscountPercent = cardDiscountRes.data.discountPercent;
            setCardDiscount(cardDiscountPercent)
        }
        async function getSavedCardFees() {
            if(savedAmount && flags.createBitnobCards){
                const cardFeesRes = await getCardFees(selectedCardProduct?.id);
                const cardCreationFee = Number(cardFeesRes?.data.cardCreationFee);
                const loadFeePercent = Number(cardFeesRes?.data.loadFeePercent);
                const loadFeeUnrounded = Number(Number(savedAmount) * (loadFeePercent / 100));
                const loadFee = Math.round(loadFeeUnrounded * 100) / 100;
                setCardFees({cardCreationFee, loadFeePercent, loadFee});
            }
        }

        if (selectedCardProduct?.id) {
            getSavedCardFees();
            getCardDiscountPercent();
        }
    }, [selectedCardProduct, savedAmount, flags.createBitnobCards])

    useEffect(()=>{
        //Set greatest valued wallet to default selected wallet when exchange rates are retrieved for the first time
        if(gotInitExchangeRates){
            let wallets = Array.from(authUser.wallets);
            if(walletsContainCoinbaseWallets(wallets) && flags.disableCoinbase){
                wallets = []
            }
            if(wallets.find(wallet=> wallet.provider !== "CONNECT_COINBASE")){
                addFiatBalancesToWallets(wallets);
                addBitcoinPaymentToWallets(wallets);
                addUsdcPaymentToWallets(wallets);
                addUsdtPaymentToWallets(wallets);
                if(!savedSelectedWallet){
                    let sortedWallets = sortWallets(wallets);
                    setWallets(sortedWallets);
                    changeWallet(sortedWallets[0]);
                }else{
                    changeWallet(savedSelectedWallet);
                }
            }
            Analytics.track("view_screen_create_card", { application: "webapp" });
        }
    },[gotInitExchangeRates])

    useEffect(() => {
        updateExchangeRates();
    }, [selectedWallet]);



    useEffect(() => {
        // check to see if the exchange rates updated, if so update wallet balances
        if (exchangeRates !== prevExchangeRates) {
            addFiatBalancesToWallets(wallets);
            addFiatBalanceToWallet(selectedWallet);
            setWallets(wallets);
            setSelectedWallet(selectedWallet);
        }
        updatePaymentAmount();
    }, [exchangeRates, cardAmount, applyRewardSats, applyUsdCredit, cardFees])

    useEffect(() => {
        // check if authUser just updated. If so, update the wallets list
        if (!deepEqual(authUser, prevAuthUser)) {
            let wallets = Array.from(authUser.wallets);
            if(walletsContainCoinbaseWallets(wallets) && flags.disableCoinbase){
                wallets = []
            }

            addFiatBalancesToWallets(wallets);
            addFiatBalanceToWallet(selectedWallet);
            addBitcoinPaymentToWallets(wallets);
            addUsdcPaymentToWallets(wallets);
            addUsdtPaymentToWallets(wallets);
            addCoinbaseConnectToWallets(wallets);

            sortWallets(wallets);
            if (!selectedWallet) { // set selected wallet if it doesn't exist
                setWallets(wallets);
                changeWallet(wallets[0])
            } else if (walletsContainCoinbaseWallets(authUser.wallets) && !walletsContainCoinbaseWallets(prevAuthUser.wallets)) {
                // set selected wallet to a coinbase wallet if we just connected to coinbase
                setWallets(wallets);
                changeWallet(wallets[0]);
            }else{
                setWallets(wallets);
                setSelectedWallet(selectedWallet);
            }
        }
    }, [authUser])


    const creditBalance = !!authUser ? authUser.rewardSats : 0;
    const moonCreditBalance = !!authUser ? authUser.moonCredit : 0;
    const bitcoinExchangeRate = (savedExchangeRates && savedExchangeRates[`BTC_${cardCurrency}`].bid) || (exchangeRates && exchangeRates[`BTC_${cardCurrency}`].bid);
    const exchangeRate = selectedWallet && exchangeRates && exchangeRates[`${selectedWallet.currency}_${cardCurrency}`].bid;
    const exchangeRateLastUpdated = (selectedWallet && exchangeRates) && moment(Date.parse(exchangeRates[`${selectedWallet.currency}_${cardCurrency}`].lastUpdated)).fromNow();

    let cardAmountBeforeRewards = amount || cardAmount;
    
    if(cardDiscount && cardDiscount > 0){
        const discountAmount = (cardAmountBeforeRewards * cardDiscount) / 100;
        const discountedPrice = cardAmountBeforeRewards - discountAmount;
        cardAmountBeforeRewards = Number(discountedPrice).toFixed(2);
    }

    const cardAmountBeforeRewardsWithFees = flags.createBitnobCards && cardFees && cardFees.cardCreationFee && cardFees.loadFee ? (Number(cardAmountBeforeRewards) + cardFees?.cardCreationFee + cardFees?.loadFee).toString() : cardAmountBeforeRewards;
    let cardAmountInBitcoinDecimalString = getRequiredAmountInQuote(cardAmountBeforeRewardsWithFees, bitcoinExchangeRate);

    //Checks this because the exchange rate is not retrieved before the render, so getRequiredAmountInQuote can either return NaN or Infinity
    if (!Number(cardAmountInBitcoinDecimalString) || !isFinite(cardAmountInBitcoinDecimalString)) {
        cardAmountInBitcoinDecimalString = "0";
    }

    const cardAmountInSatoshi = sb.toSatoshi(cardAmountInBitcoinDecimalString);
    // can't apply more rewards than the cart amount
    const rewardsThatCanBeApplied = Math.min(Decimal(creditBalance), Decimal(cardAmountInSatoshi));
    // convert rewardsThatCanBeApplied to fiat
    const rewardsThatCanBeAppliedInBaseDecimalString = getRewardsBalanceInBase(rewardsThatCanBeApplied, bitcoinExchangeRate);
    const rewardsThatCanBeAppliedInBase = (!!Number(rewardsThatCanBeAppliedInBaseDecimalString) && rewardsThatCanBeAppliedInBaseDecimalString) || "0";
    // get the amount in crypto to deduct from the crypto total
    const rewardsThatCanBeAppliedInQuote = (selectedWallet && selectedWallet.currency === "BTC" ? sb.toBitcoin(parseInt(rewardsThatCanBeApplied))
        : getRequiredAmountInQuote(rewardsThatCanBeAppliedInBase, exchangeRate));

    const moonCreditBalanceInBitcoinDecimalString = getRequiredAmountInQuote(moonCreditBalance, bitcoinExchangeRate);
    const moonCreditBalanceInBitcoin = ((!!Number(moonCreditBalanceInBitcoinDecimalString) && isFinite(moonCreditBalanceInBitcoinDecimalString)) && moonCreditBalanceInBitcoinDecimalString) || "0";
    const moonCreditBalanceInSatoshi = sb.toSatoshi(moonCreditBalanceInBitcoin);
    const moonCreditThatCanBeApplied = Math.min(Decimal(moonCreditBalanceInSatoshi), Decimal(cardAmountInSatoshi));
    const moonCreditThatCanBeAppliedInBaseDecimalString = getRewardsBalanceInBase(moonCreditThatCanBeApplied, bitcoinExchangeRate);
    const moonCreditThatCanBeAppliedInBase = ((!!Number(moonCreditThatCanBeAppliedInBaseDecimalString) && isFinite(moonCreditThatCanBeAppliedInBaseDecimalString)) && moonCreditThatCanBeAppliedInBaseDecimalString) || "0";

    const moonCreditThatCanBeAppliedInQuote = (selectedWallet && selectedWallet.currency === "BTC" ? sb.toBitcoin(parseInt(moonCreditThatCanBeApplied))
        : getRequiredAmountInQuote(moonCreditThatCanBeAppliedInBase, exchangeRate));

    let cardAmountWithCreditOrSats = 0;

    // apply rewards to fiat amount
    if (applyRewardSats) {
        cardAmountWithCreditOrSats = (Decimal(cardAmount).minus(rewardsThatCanBeAppliedInBase).toFixed(2, Decimal.ROUND_UP).toString());
    } else if (applyUsdCredit) {
        cardAmountWithCreditOrSats = (Decimal(cardAmount).minus(moonCreditBalance).toFixed(2, Decimal.ROUND_UP).toString());
    }

    const cardAmountFontSize = getFontSize(cardAmountWithCreditOrSats ? cardAmountWithCreditOrSats : cardAmount);
    const requiredAmountFontSize = getFontSize(requiredAmountInQuote);
    const paymentCurrency = (selectedWallet && selectedWallet.currency) || selectedQuickViewCurrency;

    const renderCardOptions = () => {
        return (
            displayCardOptions ?
            <>
                <ul className="sequence"></ul>
                    <CardOptions
                        cardProduct={cardProduct}
                        handleCardProductToggle={handleCardProductToggle}
                        creditBalance={creditBalance}
                        cardCurrency={cardCurrency}
                        moonCreditBalance={moonCreditBalance}
                        rewardsThatCanBeApplied={rewardsThatCanBeApplied}
                        rewardsThatCanBeAppliedInBase={rewardsThatCanBeAppliedInBase}
                        bitcoinExchangeRate={bitcoinExchangeRate}
                        cardAmount={cardAmount}
                        toggleApplyRewardSats={toggleApplyRewardSats}
                        toggleApplyUsdCredit={toggleApplyUsdCredit}
                        applyUsdCredit={applyUsdCredit}
                        applyRewardSats={applyRewardSats}
                        savedAmount={savedAmount}
                    />
                    <ul className="sequence"></ul>
            </>
            : <ul className="sequence"></ul>
        )
    }

    const renderCheckboxes = () => {
        return (
            <div>
                <CardAmountCheckbox props={{type: 'rewardSats', rewardSatsBalance: creditBalance, cardCurrency, moonCreditBalance, rewardsThatCanBeApplied, rewardsThatCanBeAppliedInBase, bitcoinExchangeRate, cardAmount, cardFees, toggleApplyRewardSats, toggleApplyUsdCredit, applyUsdCredit, applyRewardSats, flags}}/>
                <CardAmountCheckbox props={{type: 'moonCredit', rewardSatsBalance: creditBalance, cardCurrency, moonCreditBalance, rewardsThatCanBeApplied, rewardsThatCanBeAppliedInBase, bitcoinExchangeRate, cardAmount, cardFees, toggleApplyRewardSats, toggleApplyUsdCredit, applyUsdCredit, applyRewardSats, savedAmount, flags}}/>
                {
                    moonCreditBalance <= MIN_MOON_CREDIT_BALANCE_FOR_USE && creditBalance < MIN_REWARD_SATS_BALANCE_FOR_USE &&
                    <ul className="sequence"></ul>
                }
            </div>
        )
    }
    return (
        <div test-id='create-card-inputs' className={(onConfirmation ? "payment-method-selector-container-shown" : undefined) && "payment-method-selector-container"}>
            <CardAmountInput props={{denominations: selectedCardProduct?.denominations, cardAmountInputRef, paymentMethodSelectorCardAmountChanged, amount, cardAmountFontSize, fieldValidator}}/>
            {
                flags.cardProductToggle ? renderCardOptions() : renderCheckboxes()
            }
            <PaymentMethodSelector props={{setSelectedWallet, savedSelectedWallet, displayCardOptions, setIsShowingWallets, isShowingWallets, selectedWallet, dropDownWidth, moonCreditBalance, creditBalance, wallets, sortWallets, changeWallet, setSavedSelectedWallet, cardCurrency, flags}}/>
            <ul className="sequence no-highlight" style={{ "--dot-bottom": flags.createBitnobCards ? '81px' : '40px'}}>
                <li className="text-right">
                    {flags.createBitnobCards && <CardFees cardFees={cardFees}/>}
                    {selectedCardProduct?.id === RELOADLY_VISA_CARD_PRODUCT_ID && <CardFees cardFees={cardFees} showStriked={true} />}

                    <CardDiscount cardDiscount={cardDiscount}/>
                    <CryptoConversionRate props={{exchangeRate, paymentCurrency, cardCurrency, exchangeRateLastUpdated }}/>
                </li>
            </ul>
            <PaymentAmount props={{isShowingWallets, wallets, requiredAmountInQuote, requiredAmountFontSize, paymentCurrency, }}/>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        authUser: {
            isAuthenticated: state.session.authUser.isAuthenticated,
            name: state.session.authUser.name,
            email: state.session.authUser.email,
            wallets: state.session.authUser.wallets,
            isCoinbaseConnected: state.session.authUser.isCoinbaseConnected,
            rewardSats: state.session.authUser.rewardSats,
            moonCredit: state.session.authUser.moonCredit,
            active: state.session.authUser.active
        },
    }
}

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(withLDConsumer()(CreateCardInputs));
