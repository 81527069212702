import React from "react";
import Drawer from "@material-ui/core/Drawer";
import "./DrawerMenu.css"
import {Nav} from "react-bootstrap";
import Analytics from "../services/analytics/Mixpanel";
import CloseIcon from '@material-ui/icons/Close';
import HomeIcon from '@material-ui/icons/Home';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import WorkIcon from '@material-ui/icons/Work';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import SettingsIcon from '@material-ui/icons/Settings';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import { Redirect } from 'react-router-dom'

class DrawerMenu extends React.Component {

    constructor(props) {
        super(props);
    }

    state = {
        drawerOpen: this.props.drawerOpen,
        errorOccurred: false
    }

    toggleSupport() {
        Analytics.track("button_click_header_support", {application: "webapp"});
        try {
            window.Tawk_API.maximize();
        } catch(e) {
            this.setState({errorOccurred: true})
        }
    }

    renderAuthConditionalItems() {
        if(this.props.isAuthenticated) {
            return (
                <div>
                    <div className="drawer-item">
                        <SettingsIcon fontSize="large"/>
                        <div className="drawer-icon-spacer" />
                        <Nav.Link className="drawer-link" href="/settings">Settings</Nav.Link>
                    </div>

                    <div className="drawer-item">
                        <QuestionAnswerIcon fontSize="large" />
                        <div className="drawer-icon-spacer" />
                        <Nav.Link className="drawer-link" onClick={()=>{
                            this.toggleSupport();
                            this.props.closeDrawer();
                        }}>Support</Nav.Link>
                    </div>

                    <div className="drawer-item">
                        <ExitToAppIcon fontSize="large" />
                        <div className="drawer-icon-spacer" />
                        <Nav.Link className="drawer-link" onClick={()=>{
                            this.props.logOut();
                            this.props.closeDrawer();
                        }}>Log Out</Nav.Link>
                    </div>
                </div>
            )
        } else {
            return (
                <div>
                    <div className="drawer-item">
                        <VpnKeyIcon fontSize="large" />
                        <div className="drawer-icon-spacer" />
                        <Nav.Link className="drawer-link" href="/login">Log In</Nav.Link>
                    </div>

                    <div className="drawer-item">
                        <PersonAddIcon fontSize="large" />
                        <div className="drawer-icon-spacer" />
                        <Nav.Link className="drawer-link" href="/signup">Sign Up</Nav.Link>
                    </div>

                </div>
            )
        }
    }

    render() {
        if(this.state.errorOccurred) {
            this.setState({errorOccurred: false})
            return <Redirect to="/error"/>;
        }
        const authConditionalItems = this.renderAuthConditionalItems();
        return (
            <div>
                <Drawer anchor="right" open={this.props.drawerOpen}>
                    <div className="drawer-close-icon">
                        <CloseIcon onClick={() => this.props.closeDrawer()} />
                    </div>
                    <div className="drawer-item">
                        <HomeIcon fontSize="large" />
                        <div className="drawer-icon-spacer" />
                        <Nav.Link href="/" className="drawer-link" onClick={() => Analytics.track("button_click_header_home", {application: "webapp"})}>Home</Nav.Link>
                    </div>

                    <div className="drawer-item">
                        <ContactSupportIcon fontSize="large" />
                        <div className="drawer-icon-spacer" />
                        <Nav.Link className="drawer-link" href="/faqs" onClick={() => Analytics.track("button_click_header_faq", {application: "webapp"})}>FAQ</Nav.Link>
                    </div>

                    <div className="drawer-item">
                        <WorkIcon fontSize="large"/>
                        <div className="drawer-icon-spacer" />
                        <Nav.Link className="drawer-link" target="_blank" href="https://angel.co/company/moon-13/jobs" onClick={() => Analytics.track("button_click_header_careers", {application: "webapp"})}>Careers</Nav.Link>
                    </div>

                    <div className="drawer-item">
                        <EmojiPeopleIcon fontSize="large"/>
                        <div className="drawer-icon-spacer" />
                        <Nav.Link className="drawer-link" href="/about" onClick={() => Analytics.track("button_click_header_about_us", {application: "webapp"})}>About Us</Nav.Link>
                    </div>



                    {authConditionalItems}
                </Drawer>
            </div>
        )
    }
}

export default DrawerMenu;
