import React, {useEffect, useState} from "react"
import {formatSatoshis} from '../utils/formatSatoshis';
import RoundedLogo from '../images/logo_app_rounded_512.png';
import * as API from '../libs/apiLib';
import * as AuthUser from '../libs/authLib';
import {connect} from "react-redux";
import FaIcon from "./misc/fontawesome/FaIcon";
import './Profile.css';
import {useHistory} from "react-router-dom";
import Logger from '../utils/logger';
import existingBtcTransactions from '../utils/existingBtcTransactions';
import { useAppContext } from "../libs/contextLib";
import DOMPurify from 'dompurify';

function Profile({authUser, setShowMobileSideMenu, setMobileOptionActive, isFederatedUser, isMobile, flags}) {
    const [newName, setNewName] = useState(authUser.name);
    const [defaultBtcPaymentMethod, setDefaultBtcPaymentMethod] = useState(null);
    const [editName, setEditName] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [limits, setLimits] = useState(null);
    const [dailyPercentage, setDailyPercentage] = useState(0);
    const [monthlyPercentage, setMonthlyPercentage] = useState(0);
    const history = useHistory();
    const { isUserRestricted } = useAppContext()

    async function getLimits() {
        try {
            let limits = await API.getCardLimits();
            if (limits.data.userBanReason) {
                history.push('/dashboard')
            }
            setLimits(limits.data.limits);
            setDailyPercentage(~~((limits.data.limits.dailySpending / limits.data.limits.dailyLimit) * 100));
            setMonthlyPercentage(~~((limits.data.limits.monthlySpending / limits.data.limits.monthlyLimit) * 100));
        } catch(err){
            Logger.log("get card limits exception: ", err);
        }
    }

    useEffect(() => {
        if (isUserRestricted) {
            history.push('/dashboard')
        }
    }, [])

    useEffect(() => {
        async function update() {
            try{
                const user = await AuthUser.getAuthUser(true, false);
                setDefaultBtcPaymentMethod(user.defaultBtcPaymentMethod);
                setNewName(user.name);
            } catch(err){
                setIsLoading(false);
                history.push("/error", {errorCode: err?.response?.status});
                Logger.error(err);
            }
        }

        if (!authUser.name || !defaultBtcPaymentMethod) {
            update();
        }
        getLimits();
    }, [authUser.name, defaultBtcPaymentMethod, history]);

    const updateUser = async () => {
        if(newName){
            setIsLoading(true);
            setIsSaved(false);
            try {
                await API.updateUser(newName, null);
                await AuthUser.getAuthUser(true, false);
                setIsLoading(false);
                setIsSaved(true);
            }catch(err){
                setIsLoading(false);
                Logger.error(err);
                history.push("/error", {errorCode: err?.response?.status});
            }
        }
        setEditName(false);
    }

    const changeNewName = (name) => {
        if (name === authUser.name) {
            setEditName(false);
        } else {
            setEditName(true);
        }
        const purifiedName = DOMPurify.sanitize(name);
        setNewName(purifiedName);
    }

    const handleChangeDefaultBtcPaymentMethod = (event) => {
        setIsLoading(true);
        setDefaultBtcPaymentMethod(event.target.value);
        API.updateUserPreferences({
            defaultBtcPaymentMethod: event.target.value
        }).then(() => {
            setIsSaved(true);
        }).finally(() => {
            setIsLoading(false);
        });
    }

    const renderProfile = () => {
        if (isUserRestricted) {
            return;
        }
        return <div>
        <h3 className='profile-title'>Profile</h3>
        {
            !flags.offPlatformRefund && 
            <div className='credit-section' style={{marginTop: '10px'}}>
                <h2 className="element-title">Credit</h2>
                <span className='credit'>{authUser.moonCredit > 0 ? new Intl.NumberFormat("en-US", {style: "currency", currency: 'USD' }).format(authUser.moonCredit) : '$0'}</span>
            </div>
        }
        <div className='rewards-section' style={{marginBottom: '30px'}}>
            <h2 className="element-title">Rewards</h2>
            <span className='rewards-sats' style={{bottom: '-4px'}}>{authUser && formatSatoshis(authUser.rewardSats)} sats</span>
        </div>
        <div className='profile-info'>
            <img className='moon-logo' src={RoundedLogo} alt="Moon"/>
            <div className='profile-name-email'>
                <div className='name-section'>
                    <h2 className="element-title">Name</h2>
                    {
                    <div>
                        <input className='name-text' value={newName} onChange={(e)=>changeNewName(e.target.value)}/>
                        <div className='line'></div>
                    </div>}
                </div>
                {isMobile && <br/>}
                <div className='email-section'>
                <h2 className="element-title">Email</h2>
                    { <div className='email-container'>
                        <span className='email-text' style={{fontSize: authUser && authUser.email.length <= 20 ? '100%' : authUser && authUser.email.length > 20  ? '80%' : '60%'}}>{authUser.email || <font className='hidden-text'>...</font>}</span>
                        <div className='line'></div>
                    </div>}
                </div>

                <button className='btn btn-primary my-3 w-100'
                        style={{position:'relative', top: '16px'}}
                        onClick={() => updateUser()}
                        disabled={!editName || isLoading}>{isLoading ? 'Loading...' : 'Save'}</button>
                {isSaved ? <div className='green-text'>Profile updated!</div> : <div className='hidden-text'>.</div>}
            </div>
        </div>
        {limits && dailyPercentage >= 0 && monthlyPercentage >= 0 ? <div className='cardLimit-section'>
        <h2 className="element-title" style={{width: 'fit-content'}}>Spend Limits</h2>
            <div>
                <div style={{textAlign: 'left', marginTop: '25px'}}>
                <span style={{marginRight: '8px'}}>Daily:</span>
                {`$${limits.dailySpending && limits.dailySpending.toLocaleString()} / $${limits.dailyLimit && limits.dailyLimit.toLocaleString()}`}
                    <div className="progress" style={{"--width": `${dailyPercentage}%`}}>
                    </div>
                </div>
                <div style={{marginTop: '10px', textAlign: 'left'}}>
                <span style={{marginRight: '8px'}}>Monthly:</span>
                {`$${limits.monthlySpending && limits.monthlySpending.toLocaleString()} / $${limits.monthlyLimit && limits.monthlyLimit.toLocaleString()}`}
                    <div className="progress" style={{"--width": `${monthlyPercentage}%`}}>
                    </div>
                </div>
            </div>
        </div> : null }
        <div style={{marginTop: '20px'}} />
        {defaultBtcPaymentMethod ? <div className='default-bitcoin-payment-method-section'>
        <h2 className="element-title default-bitcoin-payment-text" style={{width: 'fit-content'}}>Default Bitcoin Payment Method</h2>
            <div>
                <div className='default-bitcoin-payment-method-divider'>
                <select disabled={isLoading} onChange={handleChangeDefaultBtcPaymentMethod} value={defaultBtcPaymentMethod} className="bitcoin-payment-method-dropdown" style={{width: 'fit-content'}} name="btc-payment-method" id="btc-payment-method">
                    {existingBtcTransactions.map(existingBtcTransaction => (
                        <option key={existingBtcTransaction.key} id={existingBtcTransaction.key} value={existingBtcTransaction.key}>{existingBtcTransaction.label}</option>
                    ))}
                </select>
                </div>
            </div>
        </div> : null }
        {!isMobile && !isFederatedUser &&
            <button
                className='option'
                style={{marginTop: '25px'}}
                onClick={()=>{
                        history.push("/login/reset")
                }}
            >
                <FaIcon style={{margin: '0 7px'}} icon="key"/>
                    Change Password
            </button>
        }
    </div>
    }

    return (
        <div>
            <div className={isMobile ? 'mobile-profile-view' : 'desktop-profile-view'}>
                {isMobile &&
                    <span onClick={() => {
                    setShowMobileSideMenu(true)
                    setMobileOptionActive('')
                    }}
                        style={{ cursor: 'pointer' }}>
                        <FaIcon className='back-btn-icon' icon="chevron-left"/>
                        <span className='back-btn-text'> Back</span>
                    </span>
                }
                {renderProfile()}
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    authUser: state.session.authUser
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
