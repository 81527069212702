import React from "react";
import "./FooterCallToAction.css";
import "../../shared/Styling/Product.css";
import AddToBrowser from "../../shared/CTA/AddToBrowser";
import Analytics from "../../../services/analytics/Mixpanel";

export default class FooterCallToAction extends React.Component {

    render() {
        return (
            <section className="product-content">
                <div className="container">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 offset-lg-3 mb-3 col-md-8 offset-md-2">
                                <h3 className="text-center">Ready to Try Moon?</h3>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 offset-lg-3 text-center col-md-8 offset-md-2">
                                <button
                                    className="btn btn-primary btn-lg"
                                    onClick={()=>{
                                        window.location = '/signup';
                                        Analytics.track("button_click_home_get_started", {location: 'footer_cta', application: "webapp"});
                                    }}
                                >
                                    Get Started
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
