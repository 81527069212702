export const transactionLabelTypes = {
    CREATION: 'Card Created',
    DELETION: 'Card Deleted',
    ONCHAINPENDINGPAYMENT: 'Pending Transaction',
    DECLINE: 'Declined Transaction'
}

export const transactionTypeEnums = {
    CREATION: 'CREATION',
    DELETION: 'DELETION',
    DECLINE: 'DECLINE',
    ONCHAINPENDINGPAYMENT: 'ONCHAINPENDINGPAYMENT',
    AUTH: 'AUTH',
    REFUND: 'REFUND',
    CLEARING: 'CLEARING',
}