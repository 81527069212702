import { Box, Typography } from '@material-ui/core'
import GiftCard from '../GiftCard'
import GiftCardCreatedTransactionRow from './GiftCardCreatedTransactionRow/GiftCardCreatedTransactionRow'
import GiftCardSecurityCodeInformation from './GiftCardSecurityCodeInformation/GiftCardSecurityCodeInformation'
import GiftCardViewButton from './GiftCardViewButton/GiftCardViewButton'
import { forwardRef } from 'react'
import FaIcon from '../../../components/misc/fontawesome/FaIcon'
import GiftCardBarcodeInformation from './GiftCardBarcodeInformation/GiftCardBarcodeInformation'
import PropTypes from 'prop-types'

export function DesktopGiftCardInfo ({ card, markCardAsUsedorUnused, closeModal }) {
  return (
    <Box position="relative">
      <Box position="absolute" right="0" padding="30px 50px">
        <FaIcon icon="times" className="close-button" onClick={closeModal} 
        />
      </Box>
      <Box
        bgcolor="white"
        width={1030}
        height={407}
        display="flex"
        alignItems="center"
        borderRadius="0.25rem"
      >
        <Box display="flex" marginX="32px" flexDirection="row" width="100%">
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <GiftCard card={card} />
            <Box
              marginX={1}
              mt={1}
              width="450px"
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Typography variant="h5">
                Support Token: {card.support_token || 'No support token'}
              </Typography>
              <div
                style={{ marginRight: 12 }}
                className={`${card.gift_card.userMarkedAsUsed ? "recover-button" : "delete-button"} no-highlight`}
                onClick={markCardAsUsedorUnused}
              >
                {!card.gift_card.userMarkedAsUsed ? 'Mark as used' : 'Mark as unused'}
              </div>
            </Box>
          </Box>
          <Box mx={5} display="flex" flexDirection="column" width="100%" mt={2}>
            <GiftCardCreatedTransactionRow card={card} />
            <Box display="flex" flexDirection="column">
              {(card.gift_card.securityCode || card.gift_card.pin) && <GiftCardSecurityCodeInformation cardInfo={card} />}
              {card.gift_card.barcode && (<GiftCardBarcodeInformation cardInfo={card} />)}
            </Box>
            {card.gift_card.merchantCardWebsite && (
              <GiftCardViewButton
                merchantCardWebsite={card.gift_card.merchantCardWebsite}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

DesktopGiftCardInfo.propTypes = {
  card: PropTypes.object,
  markCardAsUsedorUnused: PropTypes.func,
  closeModal: PropTypes.func
}

// eslint-disable-next-line react/display-name
export default forwardRef((props, ref) => (
  <DesktopGiftCardInfo {...props} forwardRef={ref} />
))
