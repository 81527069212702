import React from "react"
import "../../../fonts/FontFace.css"
import "../../Card.css"
import "./CardLoadingPlaceholder.css"
import "../LoadingPlaceholder.css"


class CardLoadingPlaceholder extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="CardsContainer">
                <div className="Cards loading-card">
                    <div className="loading-virtual-card">Virtual Card</div>
                    <div className="loading-amount linear-background">.</div>
                    <div className="loading-pan linear-background">.</div>
                    <div className="exp-cvv-cont">
                        <div className="loading-exp linear-background"></div>
                        <div className="loading-cvv linear-background"></div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CardLoadingPlaceholder

