import crypto from "crypto";
import React, { useEffect, useRef, useState } from "react";
import Logger from '../../utils/logger';
import { getOnChainAddressOrCard, pollOnChainAddress } from '../../libs/apiLib';
import Logo from '../../images/moon-logo-black.svg';
import { exponentialNumberToDecimal } from '../CreateCardInputs/CreateCardInputs';
import Decimal from "decimal.js";
import CurrencyIcon from "../misc/currencyicon/CurrencyIcon";
import moment from "moment";
import FaIcon from "../misc/fontawesome/FaIcon";
import LoadingBody from "../shared/Loading/LoadingBody";
import Snackbar from "@material-ui/core/Snackbar";
import { isMobile } from "react-device-detect";
import { copyToClipboard } from "../misc/utils/dom";
import Analytics from "../../services/analytics/Mixpanel"
import BitcoinPaymentTabs from "../BitcoinPayment/BitcoinPaymentTabs/BitcoinPaymentTabs";
import * as sb from "satoshi-bitcoin";
import './OnChainTransaction.css';
import Poller from "../misc/utils/Poller";
import BitcoinPaymentMethodModal from '../BitcoinPayment/BitcoinPaymentMethodModal/BitcoinPaymentMethodModal';
import {generateQRCode} from '../misc/qrgenerator/qrGenerator';
import {copy} from '../misc/copytoclipboard/copyToClipboard';
import * as API from '../../libs/apiLib';
import { ACTION_SET_CURRENT_CARD } from '../../redux/constants';
import { connect } from "react-redux";
import store from '../../redux/store';
import { useHistory } from "react-router-dom";
import Alert from '../Alert';
import ErrorModal from "../../components/shared/ErrorModal";

function OnChainTransaction({ props, onSetCurrentCard, currentCard }) {
    const QRCanvas = useRef(null);
    const QRImage = useRef(null);
    const [address, setAddress] = useState("");
    const [amountUsd, setAmountUsd] = useState(0);
    const [bitcoinAmount, setBitcoinAmount] = useState(0);
    const [copied, setCopied] = useState(false);
    const [isOnChainLoading, setIsOnChainLoading] = useState(props.isUnderpaidOnChainInvoice ? false : true);
    const [justCopiedProperty, setJustCopiedProperty] = useState(null);
    const [isLoadingQR, setIsLoadingQR] = useState(true);
    const [showTimer, setShowTimer] = useState(false);
    const setTimerDiv = useRef(null);
    const timer = useRef(null);
    const history = useHistory();
    const [error, setError] = useState(false);

    useEffect(() => {
        if(store.getState().session.currentCard.lightningPaymentCompleted){
            onSetCurrentCard({...currentCard, lightningPaymentCompleted: false});
        }
        props.setIsOnChainPaymentCanceled(false);
        if (isOnChainLoading === true) {
            getOnChainAddress(props.cardAmount, props.cardProduct);
        }else{
            if(props.isUnderpaidOnChainInvoice){
                setAddress(props.onChainTransactionData.address);
                setBitcoinAmount(props.onChainTransactionData.cryptoAmountOwed);
                setAmountUsd(props.onChainTransactionData.usdAmountOwed);
                generateQRCode(QRCanvas, QRImage, `bitcoin:${props.onChainTransactionData.address}?amount=${props.onChainTransactionData.cryptoAmountOwed}`, setIsLoadingQR, Logo);
                if (props.isActive) {
                    setTimer(props.onChainTransactionData.exchangeRateLockExpiration);
                } else {
                    clearInterval(timer.current);
                }
                pollForDeposit(props.onChainTransactionData.invoiceId);
            }
        }
    }, []);

    const setTimer = (exchangeRateLockExpiration) => {
        if (props.isActive) {
            Logger.log('timer set');
            const expiration = exchangeRateLockExpiration;
            const currentDate = Date.now();

            const diffTime = expiration - currentDate;
            const timeUntilExpiration = moment.duration(diffTime, 'milliseconds');

            let duration = moment.duration({ 'minutes': timeUntilExpiration.minutes(), 'seconds': timeUntilExpiration.seconds() + 1 });

            let interval = 1

            // clear the timer if a timer already exists before creating a new one
            if (!!timer.current) {
                clearInterval(timer.current);
            }
            timer.current = setInterval(() => {
                duration = moment.duration(duration.asSeconds() - interval, 'seconds');
                let min = duration.minutes(),
                    sec = duration.seconds();

                if (min < 0) return clearInterval(timer.current);

                if (sec < 0 && min > 0) {
                    min -= 1;
                    sec = 59;
                }

                let secDisplay = sec.toString();
                let minDisplay = min.toString();
                if (min < 10) {
                    minDisplay = `0${min}`;
                }
                if (sec < 10) {
                    secDisplay = `0${sec}`;
                }
                if (setTimerDiv.current) {
                    setTimerDiv.current.innerText = `${minDisplay}:${secDisplay}`;
                }
                if(min < 2){
                    setShowTimer(true);
                }
                if (min === 0 && sec === 0) {
                    props.setPopUpMessageData({text: 'If you’ve made a payment before the timer ran out, don’t worry. Your card will be created once we receive notification of the transaction. However, if you paid after the timer ran out, we’ll use the prevailing exchange rate at the time the transaction is broadcast.', icon:'hourglass-end', color:'#0667D0'});
                    props.setBitcoinPaymentModal(false);
                    props.setHideCard(false);
                    props.setIsOnChainPaymentCanceled(true);
                    props.setPopUpMessage(true);
                }
            }, 1000);
        }
    };

    const copyAddress = () => {
        copy(isMobile, setCopied, address, setJustCopiedProperty);
    }

    const cancelPayment = () => {
        clearInterval(timer.current);
        setCopied(false);
        setAddress('');
        props.setBitcoinPaymentModal(false);
        props.setHideCard(false);
        props.setToggleConfirmation(true);
        props.setShowBitcoinPayment(false);
        props.setIsOnChainPaymentCanceled(true);
    };

    const completePayment = (onChainInvoiceId, OnChainInvoiceBalance) => {
        Logger.log("completePayment");
        props.tabProps.setDisplayOnChain(true);
        props.tabProps.setDisplayLightning(false);
        onSetCurrentCard({
            "on_chain_invoice_id": onChainInvoiceId,
            balance: OnChainInvoiceBalance,
            onChainPaymentCompleted: true,
        });
        clearInterval(timer.current);
        if(!props.isUnderpaidOnChainInvoice){
            props.setApplyRewardSats(false);
            props.setApplyUsdCredit(false);
        }
        setCopied(false);
        props.setBitcoinPaymentLoading(true);
        setIsOnChainLoading(true);
        setAddress('');
        props.setOnChainComplete(true);
    };

    const pollForDeposit = (onChainInvoiceID) => {
        // call query on depositAddress every second for 5 minutes to check if the deposit is done

        const pollDuration = 1000 * 60 * 5;
        let elapsedDuration = 0;
        let retries = 0;

        // Set 5s timeout between polls
        // note: this is previous request + processing time + timeout
        let poller = new Poller(5000);

        // Wait till the timeout sent our event to the EventEmitter
        poller.onPoll(async () => {
            Logger.log('triggered');
            try{
                const onChainInvoiceData = await API.getOnChainInvoices({
                    onChainInvoiceID,
                    includeExchangeRateLock: false
                })
                //polling for a new invoice payment after we displayed the address to the user
                const paid =  onChainInvoiceData.data[0].payments.length > 0
                    && currentCard.invoicePayments !== onChainInvoiceData.data[0].payments.length
                    && onChainInvoiceData.data[0].paymentStatus !== "PROCESSING"
                elapsedDuration += 5000;
                if (!paid) {
                    Logger.log('on-chain invoice not paid yet');
                    if(store.getState().session.currentCard.lightningPaymentCompleted) {
                        Logger.log('stopped polling due to payment completion (on-chain)');
                    }
                    else if (elapsedDuration < pollDuration) {
                        poller.poll(); // Go for the next poll
                    } else {
                        Logger.log('on-chain invoice expired');
                    }
                } else {
                    completePayment(onChainInvoiceID, onChainInvoiceData.data[0].invoice.requestedCardValue);
                }
            }catch(err){
                retries += 1
                if(retries < 4){
                    poller.poll();
                }
                Logger.log(`Retry #${retries}, ${err}`);
            }
        });

        // Start polling
        poller.poll();

    };

    const getOnChainAddress = async (amount, cardProduct) => {
        try {
            if (address.length < 1 && props.isActive) {
                Logger.log("getOnChainAddress inside");
                const cardProductId = store.getState().session.currentCard?.cardProductId;
                const cardProductLogo = store.getState().session.currentCard?.cardProductLogo;
                const cardProductHash = cardProductLogo ? crypto.createHash("sha256").update(cardProductLogo).digest("hex") : null;
        
                let onChainTransactionData = await getOnChainAddressOrCard(amount, props.applyRewardSats, props.applyUsdCredit, 'BTC', cardProduct, 'BITCOIN', 'BTC', undefined, cardProductId, cardProductHash);
                if (onChainTransactionData.data.type === 'CARD') {
                    setIsLoadingQR(false);
                    props.setIsLoading(false);
                    props.setBitcoinPaymentLoading(true);
                    Logger.log("completePayment");
                    clearInterval(timer.current);
                    props.setApplyRewardSats(false);
                    props.setApplyUsdCredit(false);
                    setCopied(false);
                    setIsOnChainLoading(true);
                    setAddress('');
                    props.setCreditOrSatsComplete(true);
                    props.setOnChainComplete(true);
                    onSetCurrentCard({
                        "on_chain_invoice_id": null,
                        balance: null
                    });
                } else {
                    props.setHideCard(true);
                    pollForDeposit(onChainTransactionData.data.onChainInvoiceId);
                    setAddress(onChainTransactionData.data.address);
                    setBitcoinAmount(onChainTransactionData.data.cryptoAmountOwed);
                    setAmountUsd(onChainTransactionData.data.usdAmountOwed);
                    setIsOnChainLoading(false);
                    generateQRCode(QRCanvas, QRImage, `bitcoin:${onChainTransactionData.data.address}?amount=${onChainTransactionData.data.cryptoAmountOwed}`, setIsLoadingQR, Logo);
                    if (props.isActive) {
                        setTimer(onChainTransactionData.data.exchangeRateLockExpiration);
                    } else {
                        clearInterval(timer.current);
                    }
                    props.setIsLoading(false);
                }
            }
        } catch (err) {
            if(err.response?.data?.message === "CHA is not accepted, failed to create cards"){
                history.push("/cardholder-agreement");
            } else if (err.response && err.response.status === 402) {
                Logger.log(err.response.data.message);
                props.setUserError(true);
                props.setUserErrorText(err.response.data.message);
                props.setIsLoading(false);
            } else {
                setError(true);
                props.setIsLoading(false);
            }
            clearInterval(timer.current);
            setAddress("Sorry, try again later.");
            setBitcoinAmount('X');
            setIsOnChainLoading(false);
            Logger.error("ON-CHAIN INV ERROR " + err)
            Analytics.track("create_new_card_create_card_on_chain_error", { application: "webapp" });
        }
    };

    const renderHeader = (type) => {
        //Header for mobile
        const hasAllPaymentOptions = props.flags.lightningPayments && props.flags.bitcoinBtc;
        const isOnlyOnChain = !props.flags.lightningPayments && props.flags.bitcoinBtc;
        const didLoadQR = !isLoadingQR;
        const isNotUnderpaidOnChainInvoice = !props.isUnderpaidOnChainInvoice 
        const didLoadBitcoinPayment = !props.bitcoinPaymentLoading

        if(type === 'tabs'){
            return (
                hasAllPaymentOptions && 
                isNotUnderpaidOnChainInvoice && 
                didLoadBitcoinPayment && 
                <BitcoinPaymentTabs 
                    props={{ 
                        setIsOnchain: props.setIsOnChain, 
                        device: 'mobile', 
                        setIsLightning: props.tabProps.setIsLightning, 
                        setDisplayLightning: props.tabProps.setDisplayLightning, 
                        setDisplayOnChain: props.tabProps.setDisplayOnChain, 
                        displayOnChain: props.tabProps.displayOnChain, 
                        displayLightning: props.tabProps.displayLightning 
                    }} 
                />
            )
        }else if(type === 'title'){
            return (
                didLoadQR && isOnlyOnChain && <div className='payment-title-mobile'>On-Chain</div>
            )
        }
    }

    return (
        props.isActive &&
        <div style={{display: props.tabProps.displayLightning ? 'none' : 'block'}}>
            {!isOnChainLoading &&
                <div className='mobile-view'>
                    <div style={{ display: 'flex' }}>
                        <div onClick={() => {
                            cancelPayment();
                            Analytics.track("button_click_on_chain_cancel", { application: "webapp" })
                        }} style={{ height: 'auto', cursor: 'pointer', marginLeft: '15px', marginRight: 'auto', width: 'fit-content', padding: '30px 15px 20px 0px' }}>
                            <FaIcon className='back-btn-icon' icon="chevron-left" />
                            <span className='back-btn-text'> Back</span>
                        </div>
                        <span style={{ padding: '20px 15px 20px 0px', fontWeight: '700', fontSize: '20px' }}>Create a New Card</span>
                    </div>
                    <div className='back-btn-line' style={{ margin: '0px 0 20px 0', backgroundColor: '#D8D8D8' }} /></div>
            }
            {
                renderHeader('tabs')
            }
            {
                renderHeader('title')
            }
            {error ? !props.tabProps.displayLightning && 
            <div style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
            }}>
                <ErrorModal noBottom /> 
                <span className="cancel" onClick={()=>{
                    cancelPayment()}
                }>cancel</span>
            </div> :
            <div id="moon-ui-on-chain">
                <div id="moon-ui-on-chain-ui">
                    {props.mobile && !props.flags.lightningPayments && props.flags.bitcoinBtc && <br />}
                    {
                        !!props.bitcoinPaymentLoading &&
                        <div className={isMobile ? 'ui-on-chain-loading-container-mobile' : 'ui-on-chain-loading-container'}>
                            <LoadingBody text='Creating Card' />
                        </div>
                    }
                    {
                        !isOnChainLoading && <BitcoinPaymentMethodModal
                            props={{
                                method:'on-chain',
                                isLoadingQR,
                                QRCanvas,
                                QRImage,
                                bitcoinAmount,
                                amountUsd,
                                copyAddress,
                                address,
                                setTimerDiv,
                                showTimer,
                                cancelPayment,
                                baseCurrency: props.baseCurrency,
                                isActive: props.isActive,
                                mobile:props.mobile,
                                flags: props.flags,
                            }}
                            />
                    }
                    {
                        isLoadingQR &&
                        <LoadingBody text='Loading...' />
                    }
                </div>
            </div>}
            <Snackbar
                open={justCopiedProperty}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                style={{
                    left: '50%',
                    transform: 'translateX(-50%)',
                    width: 'fit-content'
                }}
            >
                <Alert severity="success">Copied!</Alert>
            </Snackbar>
        </div>
    )
}

const mapStateToProps = (state) => ({
    currentCard: state.session.currentCard,
});

const mapDispatchToProps = (dispatch) => ({
    onSetCurrentCard: (currentCard) => dispatch({ type: ACTION_SET_CURRENT_CARD, currentCard }),
});

export default connect(mapStateToProps, mapDispatchToProps)(OnChainTransaction);
