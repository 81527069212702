
import Decimal from "decimal.js";
import { MIN_REWARD_SATS_BALANCE_FOR_USE, MIN_MOON_CREDIT_BALANCE_FOR_USE } from '../CreateCardInputs';
import { formatSatoshis } from "../../../utils/formatSatoshis";
import { getRewardsBalanceInBase } from "../../../utils/exchangerates";
import './CardAmountCheckbox.css';

function CardAmountCheckbox({ props }) {
    const renderCheckbox = () => {
        let cardAmount = 0;
        if (!props.savedAmount) {
            cardAmount = props.cardAmount;
        } else if (props.savedAmount && props.cardAmount === 0) {
            cardAmount = props.savedAmount;
        } else {
            cardAmount = props.cardAmount;
        }
        const totalCardFees = props.cardFees ? Number(props.cardFees.cardCreationFee) + ((Number(props.cardFees.loadFeePercent) / 100) * props.cardAmount) : 0;
        const moonCreditThatCanBeApplied = Math.min(Decimal(props.moonCreditBalance), Decimal(Number(cardAmount) + Number(totalCardFees)));
        return (
            <ul test-id='card-amount-checkbox' className={props.flags.cardProductToggle ? "" : "sequence"} style={{ paddingLeft: props.flags.cardProductToggle && '10px', marginBottom: props.flags.cardProductToggle && '5px', listStyleType: props.flags.cardProductToggle && 'none', display: 'flex', "--dot-bottom": (props.moonCreditBalance > 0 && props.rewardSatsBalance >= 50) ? props.type === 'moonCredit' ? '18px' : '6px' : '18px' }}>
                <li className={`text-right`} style={{ paddingTop: props.flags.cardProductToggle && '10px', paddingBottom: props.type === 'rewardSats' && props.moonCreditBalance > 0 && '0' }}>
                    <input
                        type="checkbox"
                        className={props.type === 'moonCredit' ? "apply-moon-credit-checkbox" : "apply-rewards-credit-checkbox"}
                        checked={props.type === 'moonCredit' ? props.applyUsdCredit : props.applyRewardSats}
                        onChange={
                            () => {
                                if (props.type === 'moonCredit') {
                                    if (props.applyRewardSats) {
                                        props.toggleApplyRewardSats();
                                    }
                                    props.toggleApplyUsdCredit();
                                } else {
                                    if (props.applyUsdCredit) {
                                        props.toggleApplyUsdCredit();
                                    }
                                    props.toggleApplyRewardSats();
                                }
                            }
                        }
                    />
                    {props.type === 'moonCredit' ?
                        <span className='apply-moon-credit-amount no-highlight'>
                            <span
                                style={{ cursor: 'pointer', fontSize: props.rewardSatsBalance >= 100000000 && '12px' }}
                                onClick={
                                    () => {
                                        if (props.applyRewardSats) {
                                            props.toggleApplyRewardSats();
                                        }
                                        props.toggleApplyUsdCredit();
                                    }
                                }
                            > Apply credit: {moonCreditThatCanBeApplied ? new Intl.NumberFormat("en-US", { style: "currency", currency: props.cardCurrency }).format(moonCreditThatCanBeApplied) :
                                new Intl.NumberFormat("en-US", { style: "currency", currency: props.cardCurrency }).format(props.moonCreditBalance)}
                            </span>
                        </span> :
                        <span
                            className="apply-rewards-credit-amount no-highlight"
                            style={{ fontSize: props.rewardSatsBalance >= 100000000 && '12px' }}
                            onClick={() => {
                                if (props.applyUsdCredit) {
                                    props.toggleApplyUsdCredit();
                                }
                                props.toggleApplyRewardSats();
                            }}
                        > Apply rewards: <i>{props.rewardsThatCanBeApplied ? formatSatoshis(props.rewardsThatCanBeApplied) : formatSatoshis(props.rewardSatsBalance)} sats </i>({new Intl.NumberFormat("en-US", { style: "currency", currency: props.cardCurrency }).format(props.rewardsThatCanBeAppliedInBase > 0 ? props.rewardsThatCanBeAppliedInBase : getRewardsBalanceInBase(props.rewardSatsBalance, props.bitcoinExchangeRate))})
                        </span>
                    }
                </li>
            </ul>
        )
    }
    return (
        <div>
            {
                props.type === 'rewardSats' && props.rewardSatsBalance >= MIN_REWARD_SATS_BALANCE_FOR_USE &&
                renderCheckbox()
            }
            {
                props.type === 'moonCredit' && props.moonCreditBalance > MIN_MOON_CREDIT_BALANCE_FOR_USE &&
                renderCheckbox()
            }
        </div>
    )
}

export default CardAmountCheckbox;
