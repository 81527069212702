import Decimal from "decimal.js";
import * as sb from "satoshi-bitcoin";

import store from "../redux/store";
import {getDelayedMinutes} from "./datetime";
import {ACTION_SET_EXCHANGE_RATES_CACHE} from "../redux/ducks/api/api";
import * as API from "../libs/apiLib";
import Logger from '../utils/logger';

const MINUTES_TTL_DURATION = 3;

const updateExchangeRate = (exchangeRate) => store.dispatch({exchangeRate, type: ACTION_SET_EXCHANGE_RATES_CACHE});

export const getExchangeRate = async (quote, base) => {
    const CODE = `${quote}_${base}`;

    const rates = await getExchangeRates([{
        quote: quote,
        base: base
    }]);

    return rates[CODE];
};

/**
 * @param pairs {Array<{quote: string, base: string}>}
 */
export const getExchangeRates = async (pairs, paymentMethod = null, forceRefetch = false) => {
    try{
        let exchangeRatesCache = store.getState().api.exchangeRates;

        const invalidPairs = pairs.filter(({quote, base}) => (
            (!exchangeRatesCache[`${quote}_${base}`] ||
            new Date() >= new Date(exchangeRatesCache[`${quote}_${base}`].ttl))
        ));

        let exchangeRatesToCache = {};

        const pairsToUse = forceRefetch ? pairs : invalidPairs;

        if (!!pairsToUse.length) {
            let response = await API.getExchangeRates(pairsToUse, paymentMethod);
            const ttl = getDelayedMinutes(MINUTES_TTL_DURATION).toISOString();
            const lastUpdated = (new Date()).toISOString();
            if (response.data.userBanReason) {
                return {};
            }
            response.data.exchangeRates.forEach(exchangeRate => {
                const {quote, base} = exchangeRate;
                exchangeRatesToCache[`${quote}_${base}`] = {
                    ...exchangeRate,
                    ttl,
                    lastUpdated
                };
            });
            updateExchangeRate(exchangeRatesToCache);
            exchangeRatesCache = {
                ...exchangeRatesToCache,
                ...exchangeRatesCache
            };
        }

        let exchangeRates = {};
        pairs.forEach(({quote, base}) => (exchangeRates[`${quote}_${base}`] = exchangeRatesCache[`${quote}_${base}`]));
        return exchangeRates;
    }catch(err){
        Logger.log(err)
        return;
    }
};

export const getRequiredAmountInQuote = (baseAmount, exchangeRate) => {
    try {
        baseAmount = new Decimal(baseAmount);
        exchangeRate = new Decimal(exchangeRate);
    } catch (e) {
        return Decimal(NaN).toFixed(8, Decimal.ROUND_UP);
    }
    const riskFactor = (baseAmount.lt(10) || baseAmount.gt(2000))
        ? new Decimal(1.0)
        : new Decimal(1.0);
    return baseAmount.dividedBy(exchangeRate).times(riskFactor).toFixed(8, Decimal.ROUND_UP);
};

export const getWalletBalanceInBase = (quoteAmount, exchangeRate) => {
    try {
        quoteAmount = new Decimal(quoteAmount);
        exchangeRate = new Decimal(exchangeRate);
    } catch (e) {
        return Decimal(NaN).toFixed(2, Decimal.ROUND_UP);
    }
    return quoteAmount.times(exchangeRate).toFixed(2, Decimal.ROUND_DOWN);
};

// rewards amount in Satoshi
export const getRewardsBalanceInBase = (rewardsAmount, exchangeRate) => {
    const rewardsInBitcoin = sb.toBitcoin(parseInt(rewardsAmount));
    return getWalletBalanceInBase(rewardsInBitcoin, exchangeRate);
};

