import React from "react";
import Analytics from "../../../services/analytics/Mixpanel";
import './BitcoinPaymentTabs.css';

function BitcoinPaymentTabs({ props }) {
    return (
        <div test-id='bitcoin-payment-tabs' className={`bitcoin-payment-tabs-${props.device}`}>

            <button className='bitcoin-payment-left-tab'
                disabled={props.displayLightning}
                style={{
                    color: props.displayLightning ? '#0667d0' : '#9b9b9b',
                    border: props.displayOnChain ? '1px solid lightgray' : '2px solid #a2abce',
                    backgroundColor: props.displayLightning ? '#e3edf7' : 'white',
                }}
                onClick={() => {
                    props.setIsLightning(true);
                    props.setDisplayLightning(true);
                    props.setDisplayOnChain(false);
                    Analytics.track("button_click_lightning_tab", { application: "webapp" });
                }}
            >
                Lightning
            </button>

	        <button className='bitcoin-payment-right-tab'
	                disabled={props.displayOnChain}
	                style={{
		                color: props.displayOnChain ? '#0667d0' : '#9b9b9b',
		                border: props.displayLightning ? '1px solid lightgray' : '2px solid #a2abce',
		                backgroundColor: props.displayOnChain ? '#e3edf7' : 'white',
	                }}
	                onClick={() => {
		                props.setDisplayOnChain(true);
		                if (props.setIsOnChain) {
			                props.setIsOnChain(true);
		                }
		                props.setDisplayLightning(false);
		                Analytics.track("button_click_on_chain_tab", { application: "webapp" });
	                }}
	        >
		        On-Chain
	        </button>

        </div>
    )
}

export default BitcoinPaymentTabs;
