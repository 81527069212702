import React, { useState, useEffect } from 'react'
import UsdtPaymentOptions from './UsdtPaymentOptions/UsdtPaymentOptions'
import UsdtPaymentTabs from './UsdtPaymentTabs/UsdtPaymentTabs'
import './UsdtPayment.css'

export function UsdtPayment ({ props }) {
  const [isEthereum, setIsEthereum] = useState((props.flags.tronUsdt && props.flags.ethereumUsdt) ? false : props.flags.ethereumUsdt ? true : false)
  const [displayEthereum, setDisplayEthereum] = useState((props.flags.tronUsdt && props.flags.ethereumUsdt) ? false : props.flags.ethereumUsdt ? true : false)

  const [isTron, setIsTron] = useState((props.flags.tronUsdt && props.flags.ethereumUsdt) ? true : props.flags.tronUsdt ? true : false)
  const [displayTron, setDisplayTron] = useState((props.flags.tronUsdt && props.flags.ethereumUsdt) ? true : props.flags.tronUsdt ? true : false)

  const [usdtPaymentLoading, setUsdtPaymentLoading] = useState(false)

  useEffect(() => {
    if (props.isUnderpaidOnChainInvoice) {
      const isEthereum = props.onChainTransactionData.blockchain === 'ETHEREUM'
      const isTron = props.onChainTransactionData.blockchain === 'TRON'
      setIsEthereum(isEthereum)
      setDisplayEthereum(isEthereum)
      setIsTron(isTron)
      setDisplayTron(isTron)
    }
  }, [])

  const renderHeader = (type) => {
    // Header for desktop
    const hasAllPaymentOptions = props.flags.tronUsdt && props.flags.ethereumUsdt;
    const isOnlyEthereum = !props.flags.tronUsdt && props.flags.ethereumUsdt;
    const isOnlyTron = props.flags.tronUsdt && !props.flags.ethereumUsdt;
    const isNotUnderpaidOnChainInvoice = !props.isUnderpaidOnChainInvoice;
    const didLoadUsdtPayment = !usdtPaymentLoading && !props.isLoading

    if (type === 'tabs') {
      return (
        hasAllPaymentOptions &&
        isNotUnderpaidOnChainInvoice &&
        didLoadUsdtPayment &&
        <UsdtPaymentTabs
          props={{
            device: 'desktop',
            setIsEthereum,
            setIsTron,
            setDisplayEthereum,
            setDisplayTron,
            displayEthereum,
            displayTron
          }}
        />
      )
  } else if (type === 'title') {
    return (
      didLoadUsdtPayment && 
      (isOnlyEthereum || isOnlyTron) &&
      <div className='payment-title-desktop'>{isEthereum ? 'Ethereum' : 'Tron'}</div>
    )
}
  }

  return (
        <div test-id='usdt-payment'>
            {
              renderHeader('tabs')
            }
            {
                renderHeader('title')
            }
            { isEthereum && <UsdtPaymentOptions
              props={{
                tabProps: { setIsEthereum, setDisplayEthereum, setDisplayTron, displayTron, displayEthereum, setIsTron },
                isActive: true,
                cardAmount: props.cardAmount,
                applyRewardSats: props.applyRewardSats,
                applyUsdCredit: props.applyUsdCredit,
                setIsLoading: props.setIsLoading,
                setHideCard: props.setHideCard,
                mobile: props.mobile,
                setToggleConfirmation: props.setToggleConfirmation,
                setShowUsdtPayment: props.setShowUsdtPayment,
                setUsdtPaymentModal: props.setUsdtPaymentModal,
                setApplyRewardSats: props.setApplyRewardSats,
                setApplyUsdCredit: props.setApplyUsdCredit,
                usdtPaymentLoading,
                setUsdtPaymentLoading,
                setError: props.setError,
                setUserError: props.setUserError,
                setUserErrorText: props.setUserErrorText,
                setOnChainComplete: props.setOnChainComplete,
                onChainTransactionData: props.onChainTransactionData,
                setIsOnChainPaymentCanceled: props.setIsOnChainPaymentCanceled,
                setCreditOrSatsComplete: props.setCreditOrSatsComplete,
                setPopUpMessageData: props.setPopUpMessageData,
                setPopUpMessage: props.setPopUpMessage,
                cardProduct: props.cardProduct,
                flags: props.flags,
                paymentOption: 'ETHEREUM',
                isUnderpaidOnChainInvoice: props.isUnderpaidOnChainInvoice
              }}
            /> }
            {isTron && <UsdtPaymentOptions
                props={{
                  tabProps: { setIsEthereum, setDisplayEthereum, setDisplayTron, displayTron, displayEthereum, setIsTron },
                  isActive: true,
                  cardAmount: props.cardAmount,
                  applyRewardSats: props.applyRewardSats,
                  applyUsdCredit: props.applyUsdCredit,
                  setIsLoading: props.setIsLoading,
                  setHideCard: props.setHideCard,
                  mobile: props.mobile,
                  setToggleConfirmation: props.setToggleConfirmation,
                  setShowUsdtPayment: props.setShowUsdtPayment,
                  setUsdtPaymentModal: props.setUsdtPaymentModal,
                  setApplyRewardSats: props.setApplyRewardSats,
                  setApplyUsdCredit: props.setApplyUsdCredit,
                  usdtPaymentLoading,
                  setUsdtPaymentLoading,
                  setError: props.setError,
                  setUserError: props.setUserError,
                  setUserErrorText: props.setUserErrorText,
                  setOnChainComplete: props.setOnChainComplete,
                  onChainTransactionData: props.onChainTransactionData,
                  setIsOnChainPaymentCanceled: props.setIsOnChainPaymentCanceled,
                  setCreditOrSatsComplete: props.setCreditOrSatsComplete,
                  setPopUpMessageData: props.setPopUpMessageData,
                  setPopUpMessage: props.setPopUpMessage,
                  cardProduct: props.cardProduct,
                  flags: props.flags,
                  paymentOption: 'TRON',
                  isUnderpaidOnChainInvoice: props.isUnderpaidOnChainInvoice
                }}
            />}
        </div>
  )
}

export default UsdtPayment
