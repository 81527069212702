/**
 * Abstract class @EventTrackingBase
 */

class EventTrackingBase {

    constructor() {
        if(this.constructor === EventTrackingBase) {
            throw new Error("EventTrackingBase is an abstract class and can't be instantiated")
        }
    }

    doesUserAgreeToBeTracked() {
        throw new Error("NotImplementedException")
    }

    identify() {
        throw new Error("NotImplementedException")
    }

    track() {
        throw new Error("NotImplementedException")
    }
}

export default EventTrackingBase
