import React from 'react';
import "./index.css";
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter as Router} from "react-router-dom";
import { initSentry } from './libs/errorLib';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './redux/store';
import { Auth } from 'aws-amplify';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import {LAUNCH_DARKLY_CLIENT_ID} from './config';
initSentry();

(async () => {
    let LDProviderComponent = null;
    try {
        const ses = await Auth.currentSession();
        const email = ses.idToken.payload.email;
        if(ses){
            const uid = ses.idToken.payload.sub;
            LDProviderComponent = await asyncWithLDProvider({
                clientSideID: LAUNCH_DARKLY_CLIENT_ID,
                user: {
                    "key": uid,
                    email,
                },
            });
        }
    } catch (err) {

    }
  
    ReactDOM.render(LDProviderComponent ?
      (<LDProviderComponent>
        <Router>
            <Provider store={store}>
                <App />
            </Provider>
        </Router>
      </LDProviderComponent>) : (
            <Router>
            <Provider store={store}>
                <App />
            </Provider>
        </Router>
      ),
      document.getElementById('root'),
    );
  })();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
