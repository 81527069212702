//Encodes utf8 to string
export const encode = (string)=>{
    const escapable = /[\\\"\x00-\x1f\x7f-\uffff]/g,
    meta = {    // table of character substitutions
        '\b': '\\b',
        '\t': '\\t',
        '\n': '\\n',
        '\f': '\\f',
        '\r': '\\r',
        '"' : '\\"',
        '\\': '\\\\'
    };

    escapable.lastIndex = 0;
    return escapable.test(string) ?
        string.replace(escapable, function (a) {
            var c = meta[a];
            return typeof c === 'string' ? c :
                '\\u' + ('0000' + a.charCodeAt(0).toString(16)).slice(-4);
        }):
        string;
};