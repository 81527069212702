import { combineReducers, createStore, applyMiddleware } from "redux";
import session from "./ducks/session/session";
import api from "./ducks/api/api";
import coinbase from "./ducks/coinbase/coinbase";
import {createStateSyncMiddleware, initMessageListener} from "redux-state-sync";

const reduxStateSyncConfig = { channel: "redux_state_sync" };

export const reducer = combineReducers({
    session,
    api,
    coinbase,
})

const store = createStore(
    reducer,
    applyMiddleware(createStateSyncMiddleware(reduxStateSyncConfig))
);

initMessageListener(store);

export default store;