import React, { useEffect, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import moment from "moment";
import useWindowSize from "../../../../hooks/useWindowSize";
import * as API from '../../../../libs/apiLib';
import {formatSatoshis} from "../../../../utils/formatSatoshis";

const GiftCardCreatedTransactionRow = ({ card }) => {
  const [transactions, setTransactions] = useState(null);
  useEffect(() => {
    async function getTransactions () {
      const transactions = await API.getCardTransactionHistory(card.id)
      // TODO: display other transactions if we receive more than just creation
      const creationTransaction = transactions?.data?.transactions.filter((transaction) => transaction.type === 'CREATION')
      setTransactions(creationTransaction[0])
    }
    if (card) {
      getTransactions()
    }
  }, [])

  const size = useWindowSize();

  if (!size || !size.width || !card || !card.gift_card) return null;
  const biggerFontSize = size.width > 1100 ? 18 : 16;
  const smallerFontSize = size.width > 1100 ? 14 : 12;

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Typography variant="h1">Transactions</Typography>
      <Box display="flex" mt="18px" flexDirection="column" width="100%">
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          width={size.width > 1100 ? "100%" : "90%"}
        >
          <Box flexDirection="column" display="flex">
            <Typography
              style={{
                color: "#A5A5A5",
                fontWeight: 400,
                fontSize: smallerFontSize,
                fontFamily: "“Open Sans”, sans-serif !important",
              }}
            >
              Card Created
            </Typography>
            <Typography
              style={{
                color: "#A5A5A5",
                fontWeight: 400,
                fontSize: smallerFontSize,
                fontFamily: "“Open Sans”, sans-serif !important",
              }}
            >
            {!!transactions?.data?.moon_credit_used && <Box className='transaction-info'>Used {new Intl.NumberFormat("en-US", {style: "currency", currency: 'USD' }).format(transactions.data.moon_credit_used)} credit</Box>}
            </Typography>
          </Box>
          <Typography
            style={{
              color: "#A5A5A5",
              fontWeight: 400,
              fontSize: smallerFontSize,
              fontFamily: "“Open Sans”, sans-serif !important",
            }}
          >
            {moment(card.created_at).format("MMM DD, YYYY, h:mm a")}
          </Typography>
          <Typography
            style={{
              color: "#60C147",
              fontWeight: 400,
              fontSize: smallerFontSize,
              fontFamily: "“Open Sans”, sans-serif !important",
            }}
          >
            ${card.balance}
          </Typography>
        </Box>
        <Box mt="16px" display="flex" justifyContent="center" width={window.size > 1100 ? "100%" : "90%"}>
          <Typography
            style={{
              width: "340px",
              fontFamily: "“Open Sans”, sans-serif !important",
              fontSize: 10,
              color: "#A5A5A5",
              textAlign: "center",
            }}
          >
            Note: Purchases made with this card and balance updates will not be
            displayed in Moon
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default GiftCardCreatedTransactionRow;
