import { Modal, Box } from "@material-ui/core";
import PropTypes from "prop-types";
import useToggle from "../../../hooks/useToggle";
import { getCard, markGiftCardAsUsed, getCards } from "../../../libs/apiLib";
import { useEffect, useState } from "react";
import { onError } from "../../../libs/errorLib";
import LoadingBody from "../../shared/Loading/LoadingBody";
import DesktopGiftCardInfo from "./DesktopGiftCardInfo";
import useWindowSize from "../../../hooks/useWindowSize";
import MobileGiftCardInfo from "./MobileGiftCardInfo";
import store from '../../../redux/store'
import { ACTION_SET_CURRENT_MODAL, ACTION_SET_CARDS } from '../../../redux/constants';
import FaIcon from "../../misc/fontawesome/FaIcon";
import moment from "moment";
import * as AuthUser from "../../../libs/authLib";
import { NUM_CARDS_TO_LOAD } from '../../../components/pages/WebAppHomePage';
import { useAppContext } from "../../../libs/contextLib";

function GiftCardInfo({ children }) {
  const { triggerGiftCardStatusChange, card } = useAppContext();
  const [isOpen, setIsOpen] = useToggle(false);
  const [fullCard, setFullCard] = useState(card);
  const [markedCardAsUsedorUnused, setMarkedCardAsUsedorUnused] = useState(false);

  const size = useWindowSize()

  const setModalView = (currentModal) => store.dispatch({ type: ACTION_SET_CURRENT_MODAL, currentModal })

  useEffect(() => {
    async function fetchCard() {
      try {
        const { data: fullCardFromApi } = await getCard(fullCard.id);
        setFullCard({ ...fullCard, gift_card: { ...fullCard.gift_card, ...fullCardFromApi }})
      } catch (err) {
        onError(err);
      }
    }

    if (isOpen) {
      fetchCard()
    }
  }, [isOpen, markedCardAsUsedorUnused])

  const renderDeletedCardConfirmation = () =>{
    return (
        <Box
          bgcolor="white"
          width="inherit"
          padding="30px 50px"
          height="fit-content"
          display="block"
          textAlign="center"
          borderRadius="0.25rem"
        >
            <FaIcon icon={["fa", "check-circle"]} style={{fontSize: '80px', marginBottom:'15px'}} className="checkmark"/>
            <h2 style={{marginBottom: '25px', width: '300px'}}>{`This card has been moved to your ${!fullCard.gift_card.userMarkedAsUsed ? 'active' : 'inactive'} card list`}</h2>
            <button
                className="btn btn-primary btnHover"
                onClick={()=>{
                  closeModal()
                }}>Got it</button>
        </Box>)
  }

  const markCardAsUsedorUnused = async () => {
    try {
      if (fullCard.gift_card.userMarkedAsUsed) {
        await markGiftCardAsUsed(fullCard.id, false)
        setFullCard({ ...fullCard, gift_card: { ...fullCard.gift_card, userMarkedAsUsed: false }})
      } else {
        await markGiftCardAsUsed(fullCard.id)
        setFullCard({ ...fullCard, gift_card: { ...fullCard.gift_card, userMarkedAsUsed: true }})
      }

      setMarkedCardAsUsedorUnused(true);
    } catch (err) {
      onError(err);
    }
  };

  const closeModal = async () => {
    if (markedCardAsUsedorUnused) {
      triggerGiftCardStatusChange(fullCard, fullCard.gift_card.userMarkedAsUsed);
    }
    setIsOpen()
    setModalView(null)
    setMarkedCardAsUsedorUnused(false)
  }

  if (!fullCard && isOpen) return <LoadingBody />;
  if (!fullCard || !size.width) return null;

  return (
    <>
      <div onClick={()=>{
        setIsOpen()
        setModalView('giftCardInfo')
      }}>{{ ...children }}</div>
      <Modal
        className="giftCardModal"
        style={{
          outline: "none",
          border: "none",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: size.width > 1100 ? "rgba(0, 0, 0, 0.5)" : markedCardAsUsedorUnused ? 'white' : "transparent",
        }}
        open={isOpen}
        onClose={() => closeModal()}
        disableScrollLock
      >
        {markedCardAsUsedorUnused ? renderDeletedCardConfirmation() : (size.width > 1100 ? <DesktopGiftCardInfo closeModal={closeModal} card={fullCard} markCardAsUsedorUnused={markCardAsUsedorUnused}/> : <MobileGiftCardInfo close={closeModal} card={fullCard} markCardAsUsedorUnused={markCardAsUsedorUnused}/>)}
      </Modal>
    </>
  );
}

GiftCardInfo.propTypes = {
  children: PropTypes.node,
};

export default GiftCardInfo;
