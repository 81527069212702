export default function transactionSort(transactions) {
    const comparator = (transA, transB) => {
        const transADate = new Date(transA.data.datetime);
        const transBDate = new Date(transB.data.datetime);

        if(transADate.getTime() !== transBDate.getTime()) {
            return transBDate - transADate;
        } else if (transA.data.id && transB.data.id) {
            return transB.data.id - transA.data.id;
        } else {
            return 1;
        }
    }

    return transactions.sort((a, b) => comparator(a, b));
}
