import React, { useState, useEffect } from 'react'
import UsdcPaymentTabs from './UsdcPaymentTabs/UsdcPaymentTabs'
import UsdcPaymentOptions from './UsdcPaymentOptions/UsdcPaymentOptions'
import './UsdcPayment.css'

// isEthereum & isTron : handles which payment method we want to initially display
// displayEthereum & displayTron: handles which payment method we want to display with tab logic
export function UsdcPayment ({ props }) {
  const [isEthereum, setIsEthereum] = useState((props.flags.tronUsdc && props.flags.ethereumUsdc) ? false : props.flags.ethereumUsdc ? true : false)
  const [isTron, setIsTron] = useState((props.flags.tronUsdc && props.flags.ethereumUsdc) ? true : props.flags.tronUsdc ? true : false)
  const [displayEthereum, setDisplayEthereum] = useState((props.flags.tronUsdc && props.flags.ethereumUsdc) ? false : props.flags.ethereumUsdc ? true : false)
  const [displayTron, setDisplayTron] = useState((props.flags.tronUsdc && props.flags.ethereumUsdc) ? true : props.flags.tronUsdc ? true : false)
  const [usdcPaymentLoading, setUsdcPaymentLoading] = useState(false)

  useEffect(() => {
    if (props.isUnderpaidOnChainInvoice) {
      const isEthereum = props.onChainTransactionData.blockchain === 'ETHEREUM';
      const isTron = props.onChainTransactionData.blockchain === 'TRON';
      setIsEthereum(isEthereum)
      setDisplayEthereum(isEthereum)
      setIsTron(isTron)
      setDisplayTron(isTron)
    }
  }, [])

  const renderHeader = (type) => {
    //Header for desktop
    const hasAllPaymentOptions = props.flags.tronUsdc && props.flags.ethereumUsdc;
    const isOnlyEthereum = !props.flags.tronUsdc && props.flags.ethereumUsdc;
    const isOnlyTron = props.flags.tronUsdc && !props.flags.ethereumUsdc;
    const isNotUnderpaidOnChainInvoice = !props.isUnderpaidOnChainInvoice;
    const didLoadUsdcPayment = !usdcPaymentLoading && !props.isLoading;

    if (type === 'tabs') {
        return (
          hasAllPaymentOptions &&
          isNotUnderpaidOnChainInvoice &&
          didLoadUsdcPayment &&
          <UsdcPaymentTabs
            props={{
              device: 'desktop',
              setIsEthereum,
              setIsTron,
              setDisplayEthereum,
              setDisplayTron,
              displayEthereum,
              displayTron
            }}
          />
        )
    } else if (type === 'title') {
        return (
          didLoadUsdcPayment && 
          (isOnlyEthereum || isOnlyTron) &&
          <div className='payment-title-desktop'>{isEthereum ? 'Ethereum' : 'Tron'}</div>
        )
    }
  }

  return (
        <div test-id='usdc-payment'>
            {
              renderHeader('tabs')
            }
            {
              renderHeader('title')
            }
            { isEthereum && <UsdcPaymentOptions
                props={{
                  tabProps: { setIsEthereum, setDisplayEthereum, setDisplayTron, displayTron, displayEthereum, setIsTron },
                  isActive: true,
                  cardAmount: props.cardAmount,
                  applyRewardSats: props.applyRewardSats,
                  applyUsdCredit: props.applyUsdCredit,
                  setIsLoading: props.setIsLoading,
                  setHideCard: props.setHideCard,
                  mobile: props.mobile,
                  setToggleConfirmation: props.setToggleConfirmation,
                  setShowUsdcPayment: props.setShowUsdcPayment,
                  setUsdcPaymentModal: props.setUsdcPaymentModal,
                  setApplyRewardSats: props.setApplyRewardSats,
                  setApplyUsdCredit: props.setApplyUsdCredit,
                  usdcPaymentLoading,
                  setUsdcPaymentLoading,
                  setError: props.setError,
                  setUserError: props.setUserError,
                  setUserErrorText: props.setUserErrorText,
                  setOnChainComplete: props.setOnChainComplete,
                  onChainTransactionData: props.onChainTransactionData,
                  setIsOnChainPaymentCanceled: props.setIsOnChainPaymentCanceled,
                  setCreditOrSatsComplete: props.setCreditOrSatsComplete,
                  setPopUpMessageData: props.setPopUpMessageData,
                  setPopUpMessage: props.setPopUpMessage,
                  cardProduct: props.cardProduct,
                  flags: props.flags,
                  paymentOption: 'ETHEREUM',
                  isUnderpaidOnChainInvoice: props.isUnderpaidOnChainInvoice
                }}
                /> }
                { isTron && <UsdcPaymentOptions
                  props={{
                    tabProps: { setIsEthereum, setDisplayEthereum, setDisplayTron, displayTron, displayEthereum, setIsTron },
                    isActive: true,
                    cardAmount: props.cardAmount,
                    applyRewardSats: props.applyRewardSats,
                    applyUsdCredit: props.applyUsdCredit,
                    setIsLoading: props.setIsLoading,
                    setHideCard: props.setHideCard,
                    mobile: props.mobile,
                    setToggleConfirmation: props.setToggleConfirmation,
                    setShowUsdcPayment: props.setShowUsdcPayment,
                    setUsdcPaymentModal: props.setUsdcPaymentModal,
                    setApplyRewardSats: props.setApplyRewardSats,
                    setApplyUsdCredit: props.setApplyUsdCredit,
                    usdcPaymentLoading,
                    setUsdcPaymentLoading,
                    setError: props.setError,
                    setUserError: props.setUserError,
                    setUserErrorText: props.setUserErrorText,
                    setOnChainComplete: props.setOnChainComplete,
                    onChainTransactionData: props.onChainTransactionData,
                    setIsOnChainPaymentCanceled: props.setIsOnChainPaymentCanceled,
                    setCreditOrSatsComplete: props.setCreditOrSatsComplete,
                    setPopUpMessageData: props.setPopUpMessageData,
                    setPopUpMessage: props.setPopUpMessage,
                    cardProduct: props.cardProduct,
                    flags: props.flags,
                    paymentOption: 'TRON',
                    isUnderpaidOnChainInvoice: props.isUnderpaidOnChainInvoice
                  }}
                  /> }
        </div>
  )
}

export default UsdcPayment
