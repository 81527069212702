import { copyToClipboard } from '../utils/dom'

export const copy = (isMobile, setCopied, text, setJustCopiedProperty) => {
  isMobile && onCardCopy(setJustCopiedProperty)
  setCopied(true)
  copyToClipboard(text)
}

const onCardCopy = async (setJustCopiedProperty) => {
  setJustCopiedProperty(true)
  await new Promise(resolve => setTimeout(resolve, 3000))
  setJustCopiedProperty(false)
}
